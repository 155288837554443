@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
.lb_popOut-main.lb-active {

  >.lb_popOutWrapper {
    overflow-y: auto;

    .lb_popOut_header {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 1;
      background: #ffffff;
      width: 100%;
    }

    .lb_popOut_body {

      .lb_message {
        width: 100%;
      }

      .lb_productDetailsArticle {
        margin-bottom: 24px;
        .lb_productDetailsImage,
        .lb_productDetailsTitle {
          margin-bottom: 0;
        }
        .lb_productDetailsImage {
          @include breakpoints.respond-to("desktop") {
            display: block;
          }
        }
      }
      
      .lb_heading {
        line-height: 24px;
        padding-top: 33px;
        margin-bottom: lineHeights.$lineHeight * 1.5;
        position: relative;
      }

      .lb_heading:before {
        z-index:unset;
        height: 9px;
        background-color: #f5f5f5;
        content: "";
        position: absolute !important;
        top: 0;
        bottom: 0;
        left: -9990px;
        right: 0;
        border-left: 9999px solid #f5f5f5;
        box-shadow: 9999px 0 0 #f5f5f5;
      }
    }

    .lb_popOut_footer {
      position: sticky;
      bottom: 0;
      background: #ffffff;
      display: inline-block;
      margin-top: auto;
      background-color: #ffffff;
      box-shadow: 0 0 8px 0px rgba(0, 0, 0, .5);
      @include breakpoints.respond-to("desktop") {
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 16px;
      }
      .lb_popOut_footer_price {
        display: inline-block;
      }
      .lb_popOut_footer_items,
      .lb_popOut_footer_price,
      .lb_popOut_footer_backlink  {
        @include breakpoints.respond-to("desktop") {
          margin: 0 0 0 16px;
          display: inline-block;
        }
      }
      .lb_popOut_footer_button {
        @include breakpoints.respond-to("desktop") {
          width: auto;
          margin: 0 0 0 16px;
        }
      }
    }
  }
}