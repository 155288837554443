@use '/src/scss/base/_paths.scss';

.lb_swatchTile {
  background-position: center;
  background-size: 60px 60px;
  padding: 50%;
  position: relative;
  -webkit-mask-image: url("#{paths.$images}icons/swatch-mask.svg");
  mask-image: url("#{paths.$images}icons/swatch-mask.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-image:url("#{paths.$images}icons/swatch-mask-border.svg");
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
  }
}

