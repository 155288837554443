﻿@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';

.lb_formElement_postcodeList_addressData {
  max-height: 280px;
  border: 1px solid #e5e5e5!important;
  margin-bottom: 10px;
  overflow-y: scroll;
}

.lb_formElement_postcodeList_addressData_address {
  display: block;
  width: 100%;
  line-height: lineHeights.$lineHeight*2;
  padding: 0 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: .8rem;
  @include breakpoints.respond-to("tablet") {
    font-size: 1rem;
  }
}

.lb_formElement_postcodeList_addressData_address:hover {
  background: signifiers.$primaryCallToActionBackgroundColour;
  color: white;
  cursor: pointer;
}