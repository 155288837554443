@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_headings.scss';

.lb_contentNavigationTile {
  text-align: center;

  .lb_contentNavigationTile_image {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: lineHeights.$lineHeight*.75;
  }

  .lb_contentNavigationTile_title {
    @include headings.heading;
    line-height: 1.2;
    margin-bottom: lineHeights.$lineHeight*.5;
  }

  .lb_contentNavigationTile_text {
    @include typography.textLarge;
    line-height: 1.2;
  }
}