@use '/src/scss/layout/_breakpoints.scss';

.lb_layoutSection-mobileCentred {
  text-align: center;
}

.lb_layoutSection-tabletCentred {
  @include breakpoints.respond-to("tablet") {
    text-align: center;
  }
}

.lb_layoutSection-desktopCentred {
  @include breakpoints.respond-to("desktop") {
    text-align: center;
  }
}

.lb_layoutSection-mobileLeftAlign {
  text-align: left;
}

.lb_layoutSection-tabletLeftAlign {
  @include breakpoints.respond-to("tablet") {
    text-align: left;
  }
}

.lb_layoutSection-desktopLeftAlign {
  @include breakpoints.respond-to("desktop") {
    text-align: left;
  }
}

.lb_layoutSection-mobileRightAlign {
  text-align: right;
}

.lb_layoutSection-tabletRightAlign {
  @include breakpoints.respond-to("tablet") {
    text-align: right;
  }
}

.lb_layoutSection-desktopRightAlign {
  @include breakpoints.respond-to("desktop") {
    text-align: right;
  }
}