@use '/src/scss/styles/colors/abstracts/_tension.scss';
@use '/src/scss/styles/colors/_colors.scss';

.lb_tensionMark {
    @include tension.tensionMark;
    background: #ffffff;
    color: colors.$primaryColour;
    border: solid 1px colors.$primaryColour;
    margin-top: 3px;
    margin-right: 6px;
}

.lb_tensionMark-soft {
    @include tension.tensionMark;
    background: tension.$softColour;
    color: #ffffff!important;
    border: solid 1px tension.$softColour;
}

.lb_tensionMark-softMedium {
    @include tension.tensionMark;
    background: tension.$softMediumColour;
    color: #ffffff!important;
    border: solid 1px tension.$softMediumColour;
}

.lb_tensionMark-medium {
    @include tension.tensionMark;
    background: tension.$mediumColour; 
    color: #ffffff!important;
    border: solid 1px tension.$mediumColour;
}

.lb_tensionMark-mediumFirm {
    @include tension.tensionMark;
    background: tension.$mediumFirmColour; 
    color: #ffffff!important;
    border: solid 1px tension.$mediumFirmColour;
}

.lb_tensionMark-firm {
    @include tension.tensionMark;
    background: tension.$firmColour;
    color: #ffffff!important;
    border: solid 1px tension.$firmColour;
}

.lb_tensionMark-extraFirm {
    @include tension.tensionMark;
    background: tension.$extraFirmColour;
    color: #ffffff!important;
    border: solid 1px tension.$extraFirmColour;
}