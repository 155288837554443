$positiveColour: #368036;
$positiveColourTint: hsl(101, 49%, 95%);
$positiveColourUnavailable: #7d8679;

$negativeColour: #cc0000;
$negativeColourTint: #f7d4d4;
$negativeColourUnavailable: #8c7373;

$messagePositiveBackgroundColor: $positiveColourTint;
$messagePositiveBorderColor: $positiveColour;
$messagePositiveTextColor: $positiveColour;

$messageNagativeBackgroundColor: $negativeColourTint;
$messageNagativeBorderColor: $negativeColour;
$messageNagativeTextColor: $negativeColour;