@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/forms/abstracts/_button.scss';
@use '/src/scss/styles/colors/_colors.scss';

.lb_article_intro {
  text-align: center;
}

.lb_article {
  position: relative;
  margin-bottom: lineHeights.$lineHeight*2;
  @include layout.responsiveGrid(12, 0, 1, "margin-left");
  @include layout.responsiveGrid(12, 0, 1, "margin-right");
  @include breakpoints.respond-to("tablet") {
    align-items: center;
  }

  .lb_heading {
    //margin-bottom: lineHeights.$lineHeight * .5;
    //@include layout.responsiveGrid(12, 0, 1, "margin-left");
    //@include layout.responsiveGrid(12, 0, 1, "margin-right");
    @include breakpoints.respond-to("tablet") {
      text-align: center;
    }
  }

  .lb_article_img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: lineHeights.$lineHeight;
  }

  .lb_article_tile {
    margin-bottom: lineHeights.$lineHeight!important;
    display: block;
  }

  .lb_text {
    @include typography.text;
    margin-bottom: lineHeights.$lineHeight;
  }

  .lb_text-large {
    @include typography.textLarge;
  }

  .lb_textIntro {
    @include typography.textIntro;
    margin-bottom: lineHeights.$lineHeight;
    @include breakpoints.respond-to("tablet") {
      text-align: center;
    }
  }

  .lb_button {
    @include button.button;
    @include button.primaryButton;
    margin-bottom: lineHeights.$lineHeight;
    height: fit-content;
  }

  .lb_vacanciesList {
    @include layout.responsiveGrid(12, 12, -2, "width");
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
  }

  .lb_vacanciesEnquiry {
    @include layout.responsiveGrid(12, 12, -2, "width");
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
  }

}

.lb_article-topAlign {
  align-items: initial!important;
}

.lb_article-solidBackground {
  padding-bottom: lineHeights.$lineHeight * 2;
  padding-top: lineHeights.$lineHeight * 2;
}

.lb_article-solidBackground:before {
  @include layout.solidBackground;
}

.lb_article-solidBackground .lb_icon-button {
  &:before {
    color: colors.$surfaceColourTintDark;
  }
}

.lb_article-zigzag.lb_article-border {
  margin-bottom: lineHeights.$lineHeight*2;
  @include layout.responsiveGrid(12, 0, 1, "margin-left");
  @include layout.responsiveGrid(12, 0, 1, "margin-right");
  border: solid 1px colors.$surfaceColourDark;
  padding-top: lineHeights.$lineHeight;
  @include layout.responsiveGrid(12, 0, 1, "padding-left");
  @include layout.responsiveGrid(12, 0, 1, "padding-right");
}