@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/colors/_colors.scss';

.lb_reviews {
  max-height: 375px;
  overflow-y: auto;
  border: solid 1px colors.$surfaceColourLight;
  border-width: 1px;
  padding: 0 16px 0 16px;
  position: relative;
  text-align: center;

  &:after {
    content: "";
    position: sticky;
    bottom: 0;
    height: 28px;
    width: 100%;
    display: block;
    background-image: linear-gradient(transparent, #ffffff);
  }

  &:before {
    content: "";
    position: sticky;
    top: 0;
    height: 28px;
    width: 100%;
    display: block;
    background-image: linear-gradient(#ffffff, transparent);
  }
}

.lb_review {
  border-bottom: 1px solid colors.$surfaceColourVeryLight;
  padding-bottom: lineHeights.$lineHeight*1.5;
  margin: 0 auto lineHeights.$lineHeight*1.75 auto;
  width: 75%;

  &:last-child {
    margin-bottom: 0;
    border: 0;
    padding: 0;
  }

  .lb_heading {
    margin: 0!important;
  }

  .lb_starRating {
    margin-bottom: 6px;
    justify-content: center;
  }

  .lb_text {
    clear: left;
    margin-bottom: 3px;
  }

  .lb_hidden {
    display: none;
  }
}
