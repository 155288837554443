@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/styles/abstracts/_headingText.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colours/_colours.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/icons/abstracts/_arrows.scss';

.lb_mainHeading {
  @include headings.mainHeading;
}

.lb_categoryHeading {
  @include headings.categoryHeading;
  margin-top: layout.$lineHeight*2;
}

.lb_heading {
  @include headings.heading;
}

.lb_heading-centred {
  text-align: center;
  margin-bottom: layout.$lineHeight;
}

.lb_heading-page {
  @include headings.pageHeading;
  margin-top: 36px;
}

.lb_heading-category {
  @include headings.categoryHeading;
}

.lb_heading-section {
  @include headings.sectionHeading;
}

.lb_heading-smallSection {
  @include headings.smallSectionHeading;
}

.lb_heading-subSection {
  @include headings.subSectionHeading;
}

.lb_heading-finance {
  @include headings.smallSectionHeading;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;
}

.lb_heading-popOut {
  @include headings.sectionHeading;
  font-size: 1rem;
}

.lb_heading-text {
  @include headings.textHeading;
}

.lb_heading-mobileCentre {
  @include headings.centreHeading;
}

.lb_heading-tabletCentre {
  @include breakpoints.respond-to("tablet") {
    @include headings.centreHeading;
  }
}

.lb_heading-desktopCentre {
  @include breakpoints.respond-to("desktop") {
    @include headings.centreHeading;
  }
}

.lb_textHeading {
  @include headings.textHeading;
  clear: left;
}

.lb_heading-separator {
  border: solid 1px colours.$surfaceColourLight;
  border-width: 1px 0 0 0;
  padding-top: 24px;
  font-size: 1.2rem;
  font-weight: fontWeights.$fontWeightBold !important;
  width: 100%;
  clear: left;
  padding-left: 0 !important;

  @include breakpoints.respond-to("desktop") {
    border-width: 1px 0 0 0;
    margin-bottom: 24px;
  }
}

.lb_popOut .lb_heading-separator {
  border-width: 1px 0 0 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.lb_heading-delivery {
  display: block;
  border: dotted 1px colours.$surfaceColourLight;
  border-width: 0 0 1px 0;
  padding: 0 0 6px 0;
  font-size: .9rem;
  text-align: left;
  font-weight: fontWeights.$fontWeightBold !important;

  .lb_heading-deliverySubText {
    font-size: .8rem;
    color: colours.$textColour;
    font-weight: fontWeights.$fontWeightRegular;
    margin: -3px 0 0;
  }
}

.lb_headingWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
 
  .lb_headingWrapper_link {
    @include typography.linkSecondary;
    padding-top: 7px;
    width: 150px;
    text-align: right;
    white-space: nowrap;
   
    &:after {
      margin-left: 4px;
      @include arrows.arrow;
      @include arrows.arrowForward;
    }
  }
}

.lb_hr {
  @include headingText.heading;
}

.lb_hr-page {
  @include headingText.pageHeading;
}

.lb_hr-section {
  @include headingText.sectionHeading;
}

.lb_hr-subSection {
  @include headingText.subSectionHeading;
}

.lb_hr-navigation {
  @include headingText.navigationHeading;
}

.lb_hr-text {
  @include headingText.textHeading;
}

.lb_hr-mobileColumn {
  @include headings.centreHeading;
}

.lb_hr-tabletColumn {
  @include breakpoints.respond-to("tablet") {
    @include headings.centreHeading;
  }
}

.lb_hr-desktopColumn {
  @include breakpoints.respond-to("desktop") {
    @include headings.centreHeading;
  }
}

.lb_hr-separator {
  border: solid 1px colours.$surfaceColourLight;
  border-width: 1px 0 0 0;
  padding-top: 24px;
  width: 100%;
  clear: left;
  padding-left: 0 !important;

  @include breakpoints.respond-to("desktop") {
    border-width: 1px 0 0 0;
    margin-bottom: 24px;
  }
}

.lb_hr-delivery {
  display: block;
  border: dotted 1px colours.$surfaceColourLight;
  border-width: 0 0 1px 0;
  padding: 0 0 6px 0;
  font-size: .9rem;
  text-align: left;
  font-weight: fontWeights.$fontWeightBold !important;
  margin-bottom: 22px !important;
}