@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/colors/_colors.scss';
  
@mixin carouselControls {
  cursor: pointer;
}

@mixin carouselControlsSinglePagingSelectableCarousel {
  @include carouselControls;
  border: solid 1px colors.$surfaceColour;
  background: #ffffff99;
  padding: 3px;
  @include breakpoints.respond-to("tablet") {
    padding: 6px;
  }
  z-index: 1;
}

@mixin carouselControlsPagingSelectableCarousel {
  @include carouselControls;
  position: relative;
  display: inline-block;
}