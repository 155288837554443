@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_headingText.scss';  

.lb_table-listTable {
  grid-template-columns: 66.6% 33.3%!important;
  background-color: colors.$surfaceColourTintVeryDark!important;
  padding: 0 10px 12px 10px;
  width: 100%;
  margin: 0 auto;

  .lb_table_item.lb_table_item-header {
    grid-column-start: span 2;
    border: 0!important;
    @include headingText.subSectionHeading;
    margin-top: 12px;
    min-height: unset;
  }

  .lb_table_item {
    background-color: colors.$surfaceColourTintVeryDark!important;
    border: 0!important;
    border-bottom: solid 1px colors.$surfaceColourLight!important;
    line-height: 1;
    padding: 0!important;
  }

  .lb_table_item:nth-child(2n+2) {
    font-weight: 700;
  }

  .lb_table_item:nth-child(2n+3) {
    text-align: right;
  }

  .lb_table_item:last-child {
    border: 0!important;
  }

  .lb_table_item:nth-last-child(2) {
    border: 0!important;
  }
}
