﻿@use '/src/scss/styles/colors/_positiveNegative.scss';
@use '/src/scss/base/_paths.scss';

.lb_formElement.lb-warning {

  .lb_formElement_input {
    border: solid 1px positiveNegative.$negativeColour!important; 
  }

  input.lb_formElement_input {
    background-image: url( #{paths.$images}/icons/cross.svg);
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-size: 12px;
  }

  .lb_formElement_label {
    color: positiveNegative.$negativeColour!important;
  }

  .lb_formElement_message {
    color: positiveNegative.$negativeColour!important;
  }
}