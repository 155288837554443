﻿@use '/src/scss/styles/abstracts/_lineHeights.scss';

textarea,
input[type="text"],
input[type="submit"] {
  -webkit-appearance: none;
  outline: none;
}

$formBorder: #B3B3B3;
$formBorderHover: #B3B3B3;
$formBorderFocused: #212121;
$formBorderUnavailable: #E4E4E4;
$placeholder: #AAAAAA;
$inputBorderRadius: 5px;
$formElementControlPadding: 8px;
$formElementControlHeight: lineHeights.$lineHeight*3;