@use '/src/scss/icons/abstracts/_arrows.scss';


.lb_icon-arrowBackward:before {
  @include arrows.arrow;
  @include arrows.arrowBackward;
}

.lb_icon-arrowDown:before {
  @include arrows.arrow;
  @include arrows.arrowDown;
}

.lb_icon-arrowForward:after {
  @include arrows.arrow;
  @include arrows.arrowForward;
}

.lb_icon-arrowUp:before {
  @include arrows.arrow;
  @include arrows.arrowUp;
}