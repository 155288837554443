@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/abstracts/_loading.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/icons/abstracts/_svgIcons.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/styles/abstracts/_typography.scss';

.lb_productTile {
	display: grid;
	margin-bottom: 24px;
	padding-bottom: 24px;
	background-color: #fff;
	grid-template-columns: 1fr;
	gap: 15px 30px;
	box-sizing: border-box;

	@include breakpoints.respond-to("tablet") {
		grid-template-columns: minmax(33.33%, 240px) minmax(auto, 400px) minmax(200px, 240px);
		grid-template-rows: repeat(5, auto);
		grid-template-areas:
			"thumbnail title pricing"
			"thumbnail title pricing"
			"thumbnail minispec pricing"
			"thumbnail minispec services"
			"thumbnail minispec viewbutton";
		border: none;
	}

	.lb_productDetailsImage {
		width: auto;
		@include breakpoints.respond-to("tablet") {
			grid-area: thumbnail;
			margin: 0;
		}
	}

	.lb_productDetailsTitleAndReview {
		.lb_productDetailsTitle {
			padding-bottom: 6px;
		}

		@include breakpoints.respond-to("tablet") {
			grid-area: title;
		}
	}

	.lb_productDetailsPricing {
		@include breakpoints.respond-to("tablet") {
			grid-area: pricing;
		}
	}

	.lb_productDetailsSpecification {
		
		.lb_productDetailsMinispec {
			font-size: 0.8rem;
			margin-bottom: 12px;
		}
	
		.lb_tensionMark {
			margin-bottom: 8px !important;
		}

		@include breakpoints.respond-to("tablet") {
			grid-area: minispec;
			align-self: flex-start;
		}
	}

	.lb_productDetailsServices {
		@include breakpoints.respond-to("tablet") {
			grid-area: services;
		}

		&.lb_icon-recycle,
		&.lb_icon-services {
			display: none;
		}

		@include breakpoints.respond-to("tablet") {

			&.lb_icon-recycle,
			&.lb_icon-services {
				display: flex;
			}
		}
	}

	.lb_productDetailsBrandLogo {
		max-height: 40px;
		width: auto;

		@include breakpoints.respond-to("tablet") {
			grid-area: brandlogo;
			align-self: flex-end;
		}
	}

	.lb_productDetailsViewButton {
		margin: 0;

		@include breakpoints.respond-to("tablet") {
			grid-area: viewbutton;
			align-self: flex-end;
		}
	}

	.lb_productDetailsFinance {
		margin-top: lineHeights.$lineHeight * 0.75;
	}

	.lb_productDetailsAdditionalServices {
		display: flex;
		flex-direction: column;

		@include breakpoints.respond-to("tablet") {
			grid-area: productDetailsAdditionalServices;
		}
	}
}

.lb_productTile-carousel {
	display: flex;
	flex-direction: column;
	margin: 0;
	align-content: flex-start;
	flex-grow: 1;

	@include breakpoints.respond-to("desktop") {
		min-height: 355px;
	}

	.lb_productDetailsPrice {
		margin-top: auto;
	}

	.lb_productDetailsPricing {
		display: flex;
		flex-direction: column;
		flex-grow: 100;
		justify-content: flex-end;
		margin-top: auto;
	}
}

.lb_productTile.lb-loadingTile {
	@include loading.loadingTile;

	&:before {
		@include loading.shimmerTile;
		width: 100%;
		min-height: 395px;
	}
}

.lb_popOut-main .lb_productTile.lb-loadingTile {
	@include loading.loadingTile;

	&:after {
		@include loading.shimmerTile;
		width: 100%;
		height: 114px;
	}
}

.lb_productLister_tiles .lb_productTile {

	@include breakpoints.respond-to("desktop") {
		padding: 0 0 36px;
		margin-bottom: 36px;
		border-width: 0 0 1px 0 !important;
	}

	&:before {
		width: 100%;
		height: 800px;

		@include breakpoints.respond-to("tablet") {
			height: 310px;
		}
	}
}