@use '/src/scss/layout/_breakpoints.scss';

.lb_layoutSection-mobileHide,
.lb_layoutSection-mobileHidden {
  display: none!important;
  @include breakpoints.respond-to("tablet") {
    display: block!important;
  }
}

.lb_layoutSection-tabletHide,
.lb_layoutSection-tabletHidden {
  @include breakpoints.respond-to("tablet") {
    display: none!important;
  }
  @include breakpoints.respond-to("desktop") {
    display: block!important;
  }
}

.lb_layoutSection-desktopHide,
.lb_layoutSection-desktopHidden {
  @include breakpoints.respond-to("desktop") {
    display: none!important;
  }
}