@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';

@mixin bottomMarginXXSmall {
    margin-bottom: lineHeights.$lineHeight*.25 !important;
}

@mixin bottomMarginXSmall {
    margin-bottom: lineHeights.$lineHeight*.5 !important;
}

@mixin bottomMarginSmall {
    margin-bottom: lineHeights.$lineHeight*.75 !important;
}

@mixin bottomMargin {
    margin-bottom: lineHeights.$lineHeight !important;
}

@mixin bottomMarginLarge {
    margin-bottom: lineHeights.$lineHeight*1.5 !important;
}

@mixin bottomMarginXLarge {
    margin-bottom: lineHeights.$lineHeight*2 !important;
}

@mixin bottomMarginXXLarge {
    margin-bottom: lineHeights.$lineHeight*3 !important;
}

@mixin bottomButting {
  margin-bottom: 0 !important;
}

@mixin mobileMargin {  
  @include layout.responsiveGrid(12, 0, 1, "margin-left");
  @include layout.responsiveGrid(12, 0, 1, "margin-right");
  @include layout.responsiveGrid(12, 12, -2, "width");
}

@mixin tabletMargin {
  @include breakpoints.respond-to("tablet") {
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
    @include layout.responsiveGrid(12, 12, -2, "width");
  }
}

@mixin desktopMargin {
  @include breakpoints.respond-to("desktop") {
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
    @include layout.responsiveGrid(12, 12, -2, "width");
  }
}