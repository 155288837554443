@use '/src/scss/base/_paths.scss';

.lb_spinnyWheel {
    position: relative;
    width: 100%;
    height: 100%;
    &:before {
        content: "";
        background-image: url(#{paths.$images}icons/spinny-wheel.svg);
        background-color: #ffffff99;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 79px;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
}