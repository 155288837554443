@use '/src/scss/styles/abstracts/_lineHeights.scss';

.lb_popOut-colours.lb-active {

  .lb_popOut_header {
    flex-basis: 42px;
    position: initial;
    background-color: #ffffff;
  }

  .lb_popOutWrapper {
    display: flex;
    flex-direction: column;
  }

  .lb_popOut_body {
    overflow: hidden;
    flex: 1;
    padding-top: 18px;

    .lb_lW,
    .lb_lW > .lb_layoutSection {
      height: 100%;
    }
    
    
    .lb_gallery_chooser {
      overflow-y: auto;
    } 

    .lb_gallery-colourPicker {
      display: flex;
      flex-direction: column;
      height: calc(100% - 81px);
      padding-bottom: 0;
    }

    .lb_layoutSection-boilerplate {
      margin-top: lineHeights.$lineHeight;
    }

    .lb_heading-section {
      margin-bottom: lineHeights.$lineHeight!important;
    }

    .lb_gallery_chooser_tiles {
      margin-bottom: lineHeights.$lineHeight*1.5;
    }

  }
  .lb_popOut_footer {
    z-index: 0;
    flex-basis: 80px;
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, .5);
  }
}