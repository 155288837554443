@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/layout/_breakpoints.scss';

.lb_questionsAndAnswers {
  margin-bottom: lineHeights.$lineHeight;

  .lb_questionsAndAnswers_item {
    border: solid 1px colors.$surfaceColourVeryLight;
    border-width: 0 0 1px 0;
    position: relative;
    align-items: center;
    list-style-type: none;
    margin-bottom: lineHeights.$lineHeight;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    .lb_questionsAndAnswers_item_heading {
      position: relative;
      @include typography.text;
      font-weight: 600;
      margin-bottom: lineHeights.$lineHeight;
      padding-left: 20px;
      color: colors.$primaryColour;
      cursor: pointer;

      &:before {
        @include typography.text;
        font-weight: 600;
        content: "Q:";
        position: absolute;
        left: 0;
        color: colors.$primaryColour;
      }

      @include breakpoints.respond-to("tablet") {
        padding-left: 80px;
        @include typography.text;
        &:before {
          content: "Question";
        }
      }
    }

    .lb_questionsAndAnswers_item_content {
      position: relative;
      padding-left: 20px;

      &:before {
        @include typography.text;
        font-weight: 600;
        content: "A:";
        position: absolute;
        left: 0;
        color: colors.$primaryColour;
      }

      @include breakpoints.respond-to("tablet") {
        padding-left: 80px;
        @include typography.text;
        &:before {
          content: "Answer";
        }
      }
    }

    .lb_text {
      line-height: lineHeights.$lineHeight;
      margin-bottom: lineHeights.$lineHeight;
      position: relative;
      @include layout.responsiveGrid(12, 0, 2, "margin-left");
      @include layout.responsiveGrid(12, 0, 2, "margin-right");
    }
  }
}