@use '/src/scss/forms/abstracts/_baseForm.scss';
@use '/src/scss/styles/abstracts/_typography.scss';

.lb_formElement-textarea {
  
  .lb_formElement_input {
    width: 100%;
    @include typography.formInputText;
    height: baseForm.$formElementControlHeight*3;
    padding: baseForm.$formElementControlPadding;
    border: solid 1px baseForm.$formBorder;
    border-radius: baseForm.$inputBorderRadius;
  }

}