@use '/src/scss/icons/abstracts/_fontIcons.scss';

.lb_icon-bedsOnDisplay60:before {
  @include fontIcons.bedsOnDisplay60;
}

.lb_icon-basket:before {
  @include fontIcons.basket;
}

.lb_icon-bin:before {
  @include fontIcons.bin;
}

.lb_icon-brands:before {
  @include fontIcons.brands;
}

.lb_icon-burger:before {
  @include fontIcons.burger;
}

.lb_icon-clearance:before {
  @include fontIcons.clearance;
}

.lb_icon-complaints:before {
  @include fontIcons.complaints;
}

.lb_icon-cross:before { 
  @include fontIcons.cross;
}

.lb_icon-delivery:before {
  @include fontIcons.delivery;
}

.lb_icon-deliveryReturns:before {
  @include fontIcons.deliveryReturns;
}

.lb_icon-email:before {
  @include fontIcons.email;
}

.lb_icon-expand:before {
  @include fontIcons.expand;
}

.lb_icon-expertAdvice:before {
  @include fontIcons.expertAdvice;
}

.lb_icon-facebook:before {
  @include fontIcons.facebook;
}

.lb_icon-faultyGoods:before {
  @include fontIcons.faultyGoods;
}

.lb_icon-filter:before {
  @include fontIcons.filter;
}

.lb_icon-finance:before {
  @include fontIcons.finance;
}

.lb_icon-finance0Percent:before {
  @include fontIcons.finance0Percent;
}

.lb_icon-financeCalculator:before {
  @include fontIcons.financeCalculator;
}

.lb_icon-freeDelivery:before {
  @include fontIcons.freeDelivery;
}

.lb_icon-help:before {
  @include fontIcons.help;
}

.lb_icon-info:before {
  @include fontIcons.info;
}

.lb_icon-instagram:before {
  @include fontIcons.instagram;
}

.lb_icon-offersPromotions:before {
  @include fontIcons.offersPromotions;
}

.lb_icon-keyFeatures:before {
  @include fontIcons.keyFeatures;
}

.lb_icon-tapeMeasure:before {
  @include fontIcons.tapeMeasure;
}

.lb_icon-pillow:before {
  @include fontIcons.pillow;
}

.lb_icon-liveChat:before {
  @include fontIcons.liveChat;
}

.lb_icon-location:before {
  @include fontIcons.location;
}

.lb_icon-overview:before {
  @include fontIcons.overview;
}

.lb_icon-padlock:before {
  @include fontIcons.padlock;
}

.lb_icon-parking:before {
  @include fontIcons.parking;
}

.lb_icon-phone:before {
  @include fontIcons.phone;
}

.lb_icon-priceMatch:before {
  @include fontIcons.priceMatch;
}

.lb_icon-promotion:before {
  @include fontIcons.promotion;
}

.lb_icon-specialPromotion:before {
  @include fontIcons.specialPromotion;
}

.lb_icon-recycle:before {
  @include fontIcons.recycle;
}

.lb_icon-roomOfChoice:before {
  @include fontIcons.roomOfChoice;
}

.lb_icon-rosette:before {
  @include fontIcons.rosette;
}

.lb_icon-search:before {
  @include fontIcons.search;
}

.lb_icon-services:before {
  @include fontIcons.services;
}

.lb_icon-skilledDelivery:before {
  @include fontIcons.skilledDelivery;
}

.lb_icon-sleepMask:before {
  @include fontIcons.sleepMask;
}

.lb_icon-sleepTrial:before {
  @include fontIcons.sleepTrial;
}

.lb_icon-specification:before {
  @include fontIcons.specification;
}

.lb_icon-star:before {
  @include fontIcons.star;
}

.lb_icon-swatches:before {
  @include fontIcons.swatches;
}

.lb_icon-tick:before {
  @include fontIcons.tick;
}

.lb_icon-trade:before {
  @include fontIcons.trade;
}

.lb_icon-trophy:before {
  @include fontIcons.trophy;
}

.lb_icon-twitter:before {
  @include fontIcons.twitter;
}

.lb_icon-yearsExperience:before {
  @include fontIcons.yearsExperience;
}

.lb_icon-youtube:before {
  @include fontIcons.youtube;
}

.lb_icon-warrantyGuarantee:before {
  @include fontIcons.warrantyGuarantee;
}

.lb_icon-writeToUs:before {
  @include fontIcons.writeToUs;
}

.lb_icon-whatsApp:before {
  @include fontIcons.whatsApp;
}