﻿@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/forms/abstracts/_baseForm.scss';
.lb_formElement_label {

  display: block;
  font-size: 0.8rem;
  font-weight: fontWeights.$fontWeightBold;
  margin-bottom: baseForm.$formElementControlPadding;

  &.lb_formElement_label-alignCenter {
    text-align: center;
  }

  .lb_formElement_input:not(button):hover~.lb_formElement_label {
    color: baseForm.$formBorderHover!important; 
  }

  .lb_formElement_input:not(button):hover {
    border: solid 1px baseForm.$formBorderHover!important; 
  }

  .lb_formElement_input:not(button):focus {
    border: solid 1px baseForm.$formBorderFocused!important; 
  }
}