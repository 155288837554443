@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';

.lb_megaMenu_footer {
  margin-top: lineHeights.$lineHeight*2;

  .lb_megaMenu_footer_tile {
    @include layout.responsiveGrid(12, 0, 2, "padding-left");
    @include layout.responsiveGrid(12, 0, 2, "padding-right");
    @include layout.responsiveGrid(12, 12, 0, "width");
    margin: 0 0 lineHeights.$lineHeight 0;
    display: block;
    
    img {
      margin-left: 0!important;
      margin-right: 0!important;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  @include breakpoints.respond-to("desktop") {
    position: absolute;
    bottom: 0;
    width: 100%;

    .lb_megaMenu_footer_tile {
      @include layout.responsiveGrid(12, 0, 1, "padding-left");
      @include layout.responsiveGrid(12, 0, 1, "padding-right");
      margin: 0 0 lineHeights.$lineHeight 0;
      width: 33.33%;
      height: 128px;
      float: left;
    }
  }
}