@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/layout/_clamping.scss';
@use '/src/scss/layout/_breakpoints.scss';

@mixin productDetailsTitle {
    @include headings.heading;
    font-size: 1rem;
    font-weight: fontWeights.$fontWeightSemiBold;
    display: block;
}

.lb_productHeading {
    @include headings.heading;
    font-size: 1.3rem;
    line-height: 1.5rem;

    @include breakpoints.respond-to("desktop") {
        font-size: 1.4rem;
        line-height: 1.6rem;
    }
}

.lb_productDetailsTitle {
    @include headings.heading;

    &.lb_productDetailsTitle-clamp {
        @include clamping.clamp3;
    }

    &.lb_productDetailsTitle-clamp2 {
        @include clamping.clamp2;
    }
}

.lb_productDetailsTitle_name {
    @include headings.textHeading;
    font-weight: fontWeights.$fontWeightRegular;
    margin-bottom: 0;
    display: block;

    &.lb_productDetailsTitle-clamp {
        @include clamping.clamp3;
    }
}

.lb_productDetailsTitle_brand {
    @include clamping.clamp2;
    @include headings.heading;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: fontWeights.$fontWeightSemiBold;
    display: block;
}
