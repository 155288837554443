@use '/src/scss/layout/_breakpoints.scss';

.lb_brandImage {
  max-width: 150px;
  max-height: 75px;
  width: auto;
  height: auto;
}

.lb_layoutSection-mobileCentred {
  .lb_brandImage {
    margin: 0 auto;
    @include breakpoints.respond-to("desktop") {
      margin: 0;
    }
  }
}