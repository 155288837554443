@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
  
  .lb_addressDetails_details {
    @include typography.text;
    font-weight: fontWeights.$fontWeightBold;
    margin-bottom: lineHeights.$lineHeight;
    color: colors.$primaryColour;
  }