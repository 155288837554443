@use '/src/scss/base/_paths.scss';

@mixin logoWrapper {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  justify-content: initial;
}

@mixin logo {
  display: block;
  height: auto;
  width: auto;
}

