@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/components/tiles/abstracts/_selectableTile.scss';
@use '/src/scss/styles/abstracts/_typography.scss';

.lb_sizeTile {
  @include selectableTile.selectableTile;
  padding: 8px!important;
  text-align: left;
  border-width: 2px!important;

  &:hover {
    padding: 8px!important;
  }

  .lb_productDetailsTitle {
    @include typography.text;
    margin-bottom: 0!important;
  }

  .lb_productDetailsPrice {
    white-space: nowrap;
  }

  .lb_sizeTile_header_measurments {
    @include typography.text;
    color: #000000;
  }

  .lb_productDetailsSaving {
    white-space: nowrap;
    display: block;
    line-height: 0;
    @include typography.miniText;
  }
}


.lb_carousel-sizes .lb-selected {
  scroll-margin-left: 16px;
  @include breakpoints.respond-to("tablet") {
    scroll-margin-left: 20px;
  }
}