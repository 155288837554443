﻿@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/colors/_signifiers.scss';

.lb_customerRatingValues {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  .lb_customerRatingValues_meterItem {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    font-size: 13px;
    margin-bottom: 7px;
    cursor: pointer;
    column-gap: 10px;
  }

  .lb_customerRatingValues_meterItem_label {
    @include typography.text;
    flex-basis: 100%;
  }

  .lb_customerRatingValues_meterItem_bar {
    flex: 1;
    height: 20px;
  }

  .lb_customerRatingValues_meterItem_value {
    @include typography.text;
  }

  .lb_customerRatingValues_bar::-webkit-meter-optimum-value {
    background: signifiers.$trustpilot;
  }
}

