@use '/src/scss/components/carousel/_carouselControls.scss';
@use '/src/scss/components/popOut/basePopout.scss';
@use '/src/scss/icons/abstracts/_arrows.scss';
@use '/src/scss/layout/_breakpoints.scss';

.lb_singlePagingSelectableCarousel {
  margin-bottom: 16px;
  position: relative;

  .lb-hidden {
    display: none !important;
  }

  .lb_selectableCarousel_next {
    @include carouselControls.carouselControlsSinglePagingSelectableCarousel;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    position: absolute;
    top: calc(50% - 12px);
    right: 8px;
    box-shadow: 1px 1px 3px -1px #000000cc;

    @include breakpoints.respond-to("tablet") {
      right: 16px;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      top: calc(50% - 24px);
    }

    &:after {
      position: absolute;
      @include arrows.arrow;
      @include arrows.arrowForward;
      @include arrows.arrowSmall;
      top: -5px;
      left: 3px;

      @include breakpoints.respond-to("tablet") {
        @include arrows.arrowMedium;
        top: 7px;
        left: 8px;
      }
    }
  }

  .lb_selectableCarousel_prev {
    @include carouselControls.carouselControlsSinglePagingSelectableCarousel;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    position: absolute;
    top: calc(50% - 12px);
    left: 8px;
    box-shadow: 1px 1px 3px -1px #000000cc;

    @include breakpoints.respond-to("tablet") {
      left: 16px;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      top: calc(50% - 24px);
    }

    &:after {
      position: absolute;
      @include arrows.arrow;
      @include arrows.arrowBackward;
      @include arrows.arrowSmall;
      top: -5px;
      left: 6px;

      @include breakpoints.respond-to("tablet") {
        @include arrows.arrowMedium;
        top: 7px;
        left: 12px;
      }
    }
  }

  .lb_selectableCarousel_zoom {
    @include carouselControls.carouselControlsSinglePagingSelectableCarousel;
    position: absolute;
    top: 8px;
    right: 8px;
    box-shadow: 1px 1px 3px -1px #000000cc;

    &:before {
      line-height: 1;
      font-size: 19px;
      display: block;
    }

    @include breakpoints.respond-to("tablet") {
      top: 16px;
      right: 16px;
      font-size: 20px;
      line-height: 18px;

      &:before {
        line-height: 1;
        font-size: 24px;
        display: block;
      }
    }
  }

  .lb_selectableCarousel_zoom-hidden {
    display: none;
  }

  .lb_selectableCarousel_cells {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #ffffff;
    scroll-behavior: smooth;

    .lb_selectableCarousel_cells_cell {
      flex: 1 0 100%;
      position: relative;
      width: 100%;
      height: 100%;
    }

    img {
      padding: 0;
    }
  }

  .lb_endorsementIcon-button {
    position: absolute;
    height: 24%;
    top: 8px;
    left: 8px;
    z-index: 1;

    @include breakpoints.respond-to("tablet") {
      height: 15%;
      top: 16px;
      left: 16px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .lb_endorsementIcon-ribbon {
    position: absolute;
    height: 10%;
    bottom: 8px;
    left: 0px;
    z-index: 1;

    @include breakpoints.respond-to("tablet") {
      height: 6%;
      bottom: 16px;
      left: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .lb_swatchTile {
    right: 8px;
    bottom: 8px;
    position: absolute;
    z-index: 20;
    padding: 8%;

    @include breakpoints.respond-to("tablet") {
      padding: 5%;
      right: 16px;
      bottom: 16px;
    }
  }
}

[data-swatch-image-is-hidden="true"] {
  display: none;
}