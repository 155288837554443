
@use '/src/scss/components/popOut/basePopout.scss';
@use '/src/scss/components/popOut/abstracts/_popOut.scss';

.lb_popOut_header {
  @include popOut.popOutHeader;

  .lb_popOut_header_backlink {
    @include popOut.backLink;
    text-align: right;
    float: right;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 4px;
    padding-bottom: 16px;

    &:before {
      margin-right: 6px;
    }
  }

  .lb_heading-popOut {
    float: left;
  }
}