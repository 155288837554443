@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/icons/abstracts/_arrows.scss';


@mixin listItem {
  line-height: 58px;
  padding: 0;
  @include layout.responsiveGrid(12, 0, 2, "padding-left");
  @include layout.responsiveGrid(12, 0, 2, "padding-right");
  border-bottom: 1px solid colors.$surfaceColourLight;  
  text-decoration: none;
  cursor: pointer;
  @include typography.menuItem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-weight: fontWeights.$fontWeightSemiBold;
  @include breakpoints.respond-to("desktop"){
    line-height: 48px;
    @include typography.menuItem;
  }

  &:before {
    font-weight: fontWeights.$fontWeightRegular;
  }
  
  &:after {
    @include arrows.arrow;
    @include arrows.arrowForward;
    margin-left: auto;
  }
}

@mixin listItemImage {
  float: left;
  margin: 4px 0;
  @include layout.responsiveGrid(12, 0, 2, "margin-right");
  font-weight: fontWeights.$fontWeightRegular;
}

@mixin listItemIndent {
  .lb_megaMenu_list_item_link {
    @include layout.responsiveGrid(12, 0, 6, "padding-left");
  }
}