@use '/src/scss/icons/abstracts/_fontIcons.scss';
@use '/src/scss/icons/abstracts/_sizes.scss';
@use '/src/scss/styles/abstracts/_listItems.scss';
@use '/src/scss/icons/abstracts/_buttons.scss';
@use '/src/scss/components/tiles/abstracts/_selectableTile.scss';
@use '/src/scss/components/tiles/abstracts/_tiles.scss';
@use '/src/scss/layout/_breakpoints.scss';

.lb_mM-beds {
    ul {
        @include tiles.tiles;

        li {
            @include tiles.tilesCell;
            @include tiles.tilex2;

            >div {
                @include selectableTile.selectableTile;

                >div {
                    @include selectableTile.selectableTileWrapper;

                    img {
                        @include selectableTile.selectableTileImg;
                    }

                    >div {
                        @include selectableTile.selectableTileText;
                    }
                }
            }
        }
    }

    @include breakpoints.respond-to("desktop") {
        ul {
            >li {
                margin-bottom: 0;
                @include tiles.tilex4;

                >div {
                    border: 0;
                    &:hover {
                        border: 0!important;
                    }
                    >div {
                        flex-direction: row;
                        justify-content: center;
                        img {
                            width: 85px;
                            height: 85px;
                            margin: 0;
                        }
                        >div {
                            display: flex;
                            height: 100%;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}