﻿@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/forms/abstracts/_button.scss';
@use '/src/scss/styles/abstracts/_typography.scss';

.lb_formElementPostcode {
  position: relative;
  padding-top: 0;

  
  .lb_formElement {
    padding-top: 6px;
    @include breakpoints.respond-to("tablet") {
      width: 50%;
      float: left;
    }
    &:nth-child(1) {
      @include breakpoints.respond-to("tablet") {
        padding-right: 16px;
      }
    }
    &:nth-child(2) {
      @include breakpoints.respond-to("tablet") {
        padding-right: 16px;
        position: initial;
        margin-bottom: 0;
      }
    }
  }
  
  .lb_formElement_message {
    @include typography.linkSecondary;
  }

  .lb_formElement_message:hover {
    @include typography.linkSecondaryHover;
  }
  .lb_button {
    margin-bottom: 0;
    width: 100%;
    @include button.secondaryButtonOutline;
  }
}
