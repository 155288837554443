﻿@use '/src/scss/styles/colors/_positiveNegative.scss';
@use '/src/scss/base/_paths.scss';

.lb_formElement.lb-success {
  
  .lb_formElement_input {
    border: solid 2px positiveNegative.$positiveColour!important;
  }

  input.lb_formElement_input {
    background-image: url( #{paths.$images}/icons/tick.svg);
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-size: 16px;
  }

  .lb_formElement_message {
    color: positiveNegative.$positiveColour!important;
  }
}