@use '/src/scss/styles/abstracts/_lineHeights.scss';

.lb_text.lb_icon-small.lb_icon-button,
.lb_heading.lb_icon-small.lb_icon-button {
  position: relative;
  padding-left: 30px;
  margin-bottom: lineHeights.$lineHeight;
  &:before {
    position: absolute;
    left: 0;
    top: -2px;
  }
}
  
.lb_heading.lb_icon-medium.lb_icon-button {
  position: relative;
  padding-left: 42px;
  margin-bottom: lineHeights.$lineHeight*1.5;
  &:before {
    position: absolute;
    left: 0;
    top: -5px;
  }
}
  
.lb_heading.lb_heading-separator.lb_icon-button:before {
  top: 20px;
  display: none;
}

.lb_heading.lb_icon-large.lb_icon-button {
  position: relative;
  padding-left: 62px;
  margin-bottom: lineHeights.$lineHeight*2;
  &:before {
    position: absolute;
    left: 0;
    top: -11px;
  }
}