@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/components/popOut/basePopout.scss';
@use '/src/scss/components/popOut/abstracts/popOut.scss';

.lb_popOut_footer {
    padding-top: lineHeights.$lineHeight;
    text-align: center;
    width: 100%;
    background-color: #ffffff;

  .lb_popOut_footer_backlink {
    width: 100%;
    display: none;
    @include popOut.backLink;
    text-align: left;
    @include breakpoints.respond-to("desktop") {
      display: block;
    }
  }
  
  .lb_popOut_footer_items {
    display: inline-block;
    white-space: nowrap;
    margin-right: 4px;
  }

  .lb_popOut_footer_price {
    font-size: 1.1rem;
    position: relative;
    top: 0px;
  }

  .lb_popOut_footer_button {
    width: 100%;
    display: block;
    white-space: nowrap;
    margin-bottom: 0;
  }

  .lb_popOut_footer_backlink,
  .lb_popOut_footer_items,
  .lb_popOut_footer_price {
    margin-bottom: lineHeights.$lineHeight;
  }
  .lb_popOut_footer_backlink:before {
    margin-right: 6px;
  }
}
