@use '/src/scss/styles/abstracts/_lineHeights.scss';

.lb_reviewTile {
  position: relative;
  padding: 24px;
  text-align: center;
  display: inline-block;
  margin-bottom: 24px;
  width: 100%;

  .lb_heading {
    margin-bottom: lineHeights.$lineHeight*.5!important;
  }

  .lb_starRating {
    text-align: center;
    margin: 0;
    flex-direction: column;
    gap: lineHeights.$lineHeight*.5;

    .lb_starRating_stars {
      float: initial;
      text-align: left;
      margin: 0 auto 0;
    }
  }

  .lb_starRating_reviews {
    display: block;
    float: initial;
    white-space: inherit;
    line-height: 16px;
  }

}