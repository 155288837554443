@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';

@mixin layoutSection10 {
  width: 10%!important;
  overflow: hidden;
  float: left;

  &.lb_layoutSection-padded {  
    @include layout.responsiveGrid(12, 0, 1, "padding-left");
    @include layout.responsiveGrid(12, 0, 1, "padding-right");
  }

  &.lb_layoutSection-margin {  
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
  }
}

@mixin layoutSection12 {
  width: 12%!important;
}

@mixin layoutSection25 {
  width: 25%;
  overflow: hidden;
  float: left;

  &.lb_layoutSection-padded {  
    @include layout.responsiveGrid(12, 0, 1, "padding-left");
    @include layout.responsiveGrid(12, 0, 1, "padding-right");
  }

  &.lb_layoutSection-margin {  
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
    @include layout.responsiveGrid(12, 3, -2, "width");
  }
}

@mixin layoutSection33 {
  width: 33%;
  overflow: hidden;
  float: left;

  &.lb_layoutSection-padded {  
    @include layout.responsiveGrid(12, 0, 1, "padding-left");
    @include layout.responsiveGrid(12, 0, 1, "padding-right");
  }

  &.lb_layoutSection-margin {  
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
    @include layout.responsiveGrid(12, 4, -2, "width");
  }

  &.lb_layoutSection-desktopMargin {
    @include breakpoints.respond-to("tablet") {
      @include layout.responsiveGrid(12, 0, 1, "margin-left");
      @include layout.responsiveGrid(12, 0, 1, "margin-right");
      @include layout.responsiveGrid(12, 4, -2, "width");
    }
  }
}

@mixin layoutSection50 {
  width: 50%!important;
  overflow: hidden;
  float: left;
  
  &.lb_layoutSection-padded {  
    @include layout.responsiveGrid(12, 0, 1, "padding-left");
    @include layout.responsiveGrid(12, 0, 1, "padding-right");
  }

  &.lb_layoutSection-margin {  
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
  }
}

@mixin layoutSection66 {
  width: 66.66%;
  overflow: hidden;
  float: left;

  &.lb_layoutSection-padded {  
    @include layout.responsiveGrid(12, 0, 1, "padding-left");
    @include layout.responsiveGrid(12, 0, 1, "padding-right");
  }

  &.lb_layoutSection-margin {  
    &.lb_layoutSection-margin {  
      @include layout.responsiveGrid(12, 0, 1, "margin-left");
      @include layout.responsiveGrid(12, 0, 1, "margin-right");
      @include layout.responsiveGrid(12, 8, -2, "width");
    }
  }
}

@mixin layoutSection75 {
  width: 75%;
  overflow: hidden;
  float: left;

  &.lb_layoutSection-padded {  
    @include layout.responsiveGrid(12, 0, 1, "padding-left");
    @include layout.responsiveGrid(12, 0, 1, "padding-right");
  }

  &.lb_layoutSection-margin {  
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
    @include layout.responsiveGrid(12, 9, -2, "width");
  }
}

@mixin layoutSection90 {
  width: 90%!important;
  overflow: hidden;
  float: left;

  &.lb_layoutSection-padded {  
    @include layout.responsiveGrid(12, 0, 1, "padding-left");
    @include layout.responsiveGrid(12, 0, 1, "padding-right");
  }

  &.lb_layoutSection-margin {  
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
  }
}

@mixin layoutSection100 {
  @include layout.responsiveGrid(12, 12, 0, "width");
  &.lb_layoutSection-margin {
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
    @include layout.responsiveGrid(12, 12, -2, "width");
  }
}