@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/_positiveNegative.scss';
@use '/src/scss/styles/abstracts/_loading.scss';
@use '/src/scss/base/_paths.scss';

.lb_carouselTile {
  width: 100%;
  height: 100%;
  padding-top: 75%;
  cursor: pointer;

}

.lb_pagingSelectableCarousel {
  .lb_carouselTile {
      border: solid 2px colors.$surfaceColourLight;
      padding: 2px;
  }
  .lb-selected {
    .lb_carouselTile {
      border: solid 2px positiveNegative.$positiveColour!important;
    }
  }
}

.lb_pagingSelectableCarousel-flickityClickDots {
  .lb_carouselTile {
    border-radius: 10px;
    background-color: colors.$surfaceColourLight;
    @include breakpoints.respond-to("desktop") {
      border-radius: 0px;
      background-color: transparent;
    }
  }
  .lb-selected {
    .lb_carouselTile {
      background-color: positiveNegative.$positiveColour;
      @include breakpoints.respond-to("desktop") {
        background-color: transparent;
      }
    }
  }
}

.lb_carouselTile {
  width: 100%;
  height: 100%;
}

.lb_carouselTile_name {
  text-align: center;
  width: 100%;
  margin-bottom: 8px;
}

.lb_carouselTile-image > img {
  width: 100%;
  height: 100%;
  display: block;
  padding: 2px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.lb_carouselTile-zoomed {
  padding: 0;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
  cursor: all-scroll;
}

.lb_carouselTile-zoomed::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.lb_carouselTile-youtube {
  img {
    width: 100%;
    height: 100%;
    display: block;
    padding: 0;
    margin: 0;
  }
  
  &:after {
    content: "";
    background-image: url(#{paths.$images}icons/play-small.svg);
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}

.lb_singlePagingSelectableCarousel .lb_carouselTile-youtube:after {
  background-image: url(#{paths.$images}icons/play-large.svg);
}

.lb_pagingSelectableCarousel .lb_carouselTile-youtube:after {
  background-image: url(#{paths.$images}icons/play-small.svg);
}

.lb_carouselTile.lb-loadingTile {
  @include loading.loadingTile;

  &:before {
    @include loading.shimmerTile;
    width: 100%;
    height: 100%;
  }
}