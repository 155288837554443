﻿@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_breakpoints.scss';

.lb_productDetailsServices {
  @include breakpoints.respond-to("tablet") {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 3px;
  }

  .lb_productDetailsService {
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 0.8rem;
    font-weight: fontWeights.$fontWeightSemiBold;
    color: colors.$primaryColour;

    &:before {
      font-weight: fontWeights.$fontWeightRegular;
      color: colors.$textColourVeryLight;
      margin-right: 6px;
      font-size: 19px!important;
      position: relative;
      top: 3px;
    }

    @include breakpoints.respond-to("tablet") {

      .lb_icon-recycle::before {
        position: relative;
        top: -2px;
        font-size: 30px;
        margin: 0 10px 0 -5px;
      }

      .lb_icon-services::before {
        position: relative;
        top: -1px;
      }

      .lb_icon-delivery::before {
        font-size: 22px;
        margin-top: 2px;
      }
    }
  }
}