@use '/src/scss/layout/_layout.scss';
@use '/src/scss/layout/_breakpoints.scss';

.lb_layoutSection-bleedRight {
  margin-right: -4.65%;
  @include breakpoints.respond-to("tablet") {
    margin-right: -2.8%;
  }
  @include breakpoints.respond-to("desktop") {
    margin-right: 0;
  }
}

.lb_layoutSection-bleedLeft {
  margin-left: -4.65%;
  @include breakpoints.respond-to("tablet") {
    margin-left: -2.8%;
  }
  @include breakpoints.respond-to("desktop") {
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
  }
}