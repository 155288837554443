@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/icons/abstracts/_svgIcons.scss';
@use '/src/scss/styles/abstracts/_typography.scss';        
@use '/src/scss/base/_helpers.scss';

.lb_productDetailsColours {
  font-size: 0.7rem;
  display: flex;
  gap: 6px;
  @include helpers.gapfix(6px);
  line-height: 1;
  align-items: center;
  @include typography.text;
  
  &:before {
    content: "";
    @include svgIcons.colourWheel;
  }
}