.lb_filterButtonCarousel {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 12px;
  padding-bottom: 0;
 
  @media (hover: none) and (pointer: coarse) {
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }
  
}