@use '/src/scss/icons/abstracts/_arrows.scss';

[data-read-more-toggle] {
  cursor: pointer;
  clear: left;
  position: relative;
  margin-right: 4px;
  line-height: 3!important;

  &:after {
    transition: all 250ms linear;
    @include arrows.arrow;
    @include arrows.arrowUp;
    margin-left: 6px;
  }

  &.lb-hidden:after {
    transition: all 250ms linear;
    @include arrows.arrow;
    @include arrows.arrowDown;
    margin-left: 6px;
  }
}

[data-read-more-content] {
  overflow: hidden;
  transition: max-height .5s ease-in-out;
  position: relative;
}

[data-read-more-content].lb-hidden:not(.lb-readMoreFade) {
  max-height: 0;
  -webkit-mask-image: linear-gradient(to bottom, black calc(100% - 36px), transparent 100%);
  mask-image: linear-gradient(to bottom, black calc(100% - 36px), transparent 100%);
}