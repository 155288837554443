@use '/src/scss/layout/_breakpoints.scss';

@mixin imageOverlay {
  position: absolute;
  z-index: 1;
}

@mixin imageOverlayLeft {
  //@include responsiveGrid(12, 0, 1, "left");
  left: 16px;
}

@mixin imageOverlayRight {
  //@include responsiveGrid(12, 0, 1, "right");
  right: 16px;
}

@mixin imageOverlayTop {
  top: 16px;
}

@mixin imageOverlayBottom {
  bottom: 16px;
}

@mixin imageOverlayCenter {
  left: 50%;
}

@mixin imageOverlayMiddle{
  top: 50%;
}

.lb_imageOverlay {
  position: relative;
  display: inline-block;
  width: 100%;

  img {
    display: block;
  }

  .lb_imageOverlay-ribbon {
    height: 9%;
    width: 33%;

    img {
      display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
    }
  }

  .lb_imageOverlay-ribbon0 {
    height: 9%;
    width: 33%;
    top: calc(-4% - 5px)!important;
    left: -8px!important;

    img {
      display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
    }
  }

  .lb_imageOverlay-ribbon0:before {
    content: "";
    display: block;
    position: relative;
    top: calc(100% + 8px);
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent #555555 transparent transparent;
    border-width: 0px 8px 8px 0px;
  }

  .lb_imageOverlay-ribbon0:after {
    content: "";
    display: block;
    position: relative;
    bottom: 2px;
    left: 4px;
    width: 80%;
    height: 2px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 3px 3px 1px;
  }

 .lb_imageOverlay-ribbon1 {
    height: auto;
    width: 100px;
    top: -16px!important;
    left: -8px!important;

    img {
      display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
    }
  }

  .lb_imageOverlay-ribbon1:before {
    content: "";
    display: block;
    position: relative;
    top: 28px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent #999999 transparent transparent;
    border-width: 0px 8px 8px 0px;
    z-index: 1;
  }

  .lb_imageOverlay-ribbon1:after {
    content: "";
    display: block;
    position: relative;
    bottom: 2px;
    left: 4px;
    width: 80px;
    height: 2px;
    box-shadow: rgba(0, 0, 0, 0.80) 0px 2px 4px 0px;
  }

  .lb_imageOverlay-button {
    height: 24%;
    
    @include breakpoints.respond-to("tablet") {
      height: 24%;
    }
    img {
        width: auto;
        height: 100%;
    }
  }

  .lb_imageOverlay-leftTop {
    @include imageOverlay;
    @include imageOverlayLeft;
    @include imageOverlayTop;
    &.lb_imageOverlay-butting {
      left: 0;
    }
  }

  .lb_imageOverlay-centerTop {
    @include imageOverlay;
    @include imageOverlayCenter;
    @include imageOverlayTop;
  }

  .lb_imageOverlay-rightTop {
    @include imageOverlay;
    @include imageOverlayRight;
    @include imageOverlayTop;
    &.lb_imageOverlay-butting {
      right: 0;
    }
  }

  .lb_imageOverlay-leftMiddle {
    @include imageOverlay;
    @include imageOverlayLeft;
    @include imageOverlayMiddle;
  }

  .lb_imageOverlay-rightMiddle {
    @include imageOverlay;
    @include imageOverlayRight;
    @include imageOverlayMiddle;
  }

  .lb_imageOverlay-leftBottom {
    @include imageOverlay;
    @include imageOverlayLeft;
    @include imageOverlayBottom;
    &.lb_imageOverlay-butting {
      left: 0;
    }
  }

  .lb_imageOverlay-centerBottom {
    @include imageOverlay;
    @include imageOverlayCenter;
    @include imageOverlayBottom;
  }

  .lb_imageOverlay-rightBottom {
    @include imageOverlay;
    @include imageOverlayRight;
    @include imageOverlayBottom;
    &.lb_imageOverlay-butting {
      right: 0;
    }
  }

  .lb_imageOverlay-rightTop {
    @include imageOverlay;
    @include imageOverlayRight;
    @include imageOverlayBottom;
    &.lb_imageOverlay-butting {
      right: 0;
    }
  }
}

.lb_endorsementIcon{
  display: none;
}