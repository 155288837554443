@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/_positiveNegative.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/icons/abstracts/_arrows.scss';
@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/forms/abstracts/_checkbox.scss';
@use '/src/scss/icons/abstracts/_fontIcons.scss';
@use '/src/scss/icons/abstracts/_svgIcons.scss';

.lb_facetNavigation {
    background: #ffffff;
    padding: 0 lineHeights.$lineHeight;

    @include breakpoints.respond-to("desktop") {
        padding: lineHeights.$lineHeight 16px lineHeights.$lineHeight 0;
    }

    .lb_button,
    .lb_filterBar_sort {
        margin-bottom: 0;
    }

    .lb_heading {
        color: colors.$textColour;
    }

    .lb_facetNavigation_title {
        @include headings.textHeading;
        position: relative;
        border-top: 1px solid colors.$surfaceColour;
        padding-top: lineHeights.$lineHeight;
        padding-bottom: lineHeights.$lineHeight*.5;
        margin-top: lineHeights.$lineHeight*1.5;

        &:after {
            @include arrows.arrow;
            @include arrows.arrowUp;
            transition: all 250ms linear;
            color: colors.$textColour;
            right: 2px;
            top: 22px;
            left: initial;
            position: absolute;
        }
    }

    .lb_facetNavigation_header {
        display: none;

        @include breakpoints.respond-to("desktop") {
            display: block !important;
            margin-bottom: 24px;
        }

        display: block;

        + .lb_facetNavigation_title {
            border: 0;
        }

        @include breakpoints.respond-to("desktop") {
            display: block;
        }
    }

    .lb_facetNavigation_section {
        padding-top: lineHeights.$lineHeight*.5;
        //@include responsiveGrid (12, 0, 2, "padding-right");
    }


    .lb-hidden.lb_facetNavigation_title:after {
        @include arrows.arrow;
        @include arrows.arrowDown;
        transition: all 250ms linear;
        right: 2px;
        left: initial;
        position: absolute;
    }

    .lb_facetNavigation_section_item {
        position: relative;
        cursor: pointer;

        div:last-child {
            margin-bottom: 16px !important;
        }
    }

    .lb_facetNavigation_section_item_title {
        @include typography.text;
        font-size: .9rem;
        margin-left: 28px;
        padding-bottom: 2px;
    }

    .lb_facetNavigation_section_item_subTitle {
        @include typography.text;
        line-height: 1.2;
        color: signifiers.$secondaryCallToActionColour;
        margin-left: 28px;
    }

    .lb_facetNavigation_section_item_checkbox {
        @include checkbox.checkbox;
        border: 2px solid #cdd8df;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        margin-right: 6px;
        position: absolute;
        top: 2px;
        left: 0;
    }
    /*
  .lb_facetNavigation_section_item_checkbox.lb-selected {
    @include checkboxSelected;
    border: 2px solid #cdd8df;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    margin-right: 6px;
    position: absolute;
    top: 2px;
    left: 0;
    &:after {
      @include tick;
      font-size: .6rem;
      //display: flex;
      justify-content: center;
      line-height: 1.8;
    }
  }
*/
    .lb_facetNavigation_section_moreOptions {
        @include typography.text;
        font-weight: fontWeights.$fontWeightSemiBold;
        cursor: pointer;
        margin-bottom: lineHeights.$lineHeight;
    }

    .lb_facetNavigation_section_moreOptions:hover {
        color: colors.$secondaryColour;
    }
}

.lb_facetNavigation_section_item_radio {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  margin: 0;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #cdd8df;
}

[data-product-listing-page-option-selected="true"] .lb_facetNavigation_section_item_radio {
  border-color: positiveNegative.$positiveColour;
  border-width: 6px;
}

[data-product-listing-page-option-selected="true"] .lb_facetNavigation_section_item_checkbox:after {
  position: absolute;
  font-size: 10px;
  top: -2px;
  left: -2px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  @include fontIcons.tick;
  line-height: 2;
  padding-left: 4px;
  display: block;
  color: #ffffff;
  background-color: positiveNegative.$positiveColour;
  border-radius: 3px!important;
  border-color: #368036!important;
}

[data-product-listing-page-option="xmas-delivery"] .lb_facetNavigation_section_item_title {
  color: #93132A;
  font-weight: fontWeights.$fontWeightBold;
  display: flex;

  &:after {
    content:"";
    width: 24px;
    height: 24px;
    background-size: 22px;
    background-repeat: no-repeat;
    @include svgIcons.christmasTree;
    display: block;
    position: relative;
    top: -2px;
  }
}