@use '/src/scss/styles/colors/_colors.scss';

$primaryCallToActionBackgroundColour: colors.$secondaryColour;

$primaryCallToActionColour: colors.$secondaryColour;
$primaryCallToActionColourHover: colors.$secondaryColourDark;

$secondaryCallToActionBackgroundColour: colors.$primaryColour;
$secondaryCallToActionColour: colors.$primaryColour;
$secondaryCallToActionColourHover: colors.$primaryColourDark;

$unavailableColour: #999999;

$primaryCallToActionColourUnavailable: $unavailableColour;

$messageBackgroundColor: #cccccc;
$messageBorderColor: #999999;
$messageTextColor: #999999;

$offerColour: #DB003E;
$sleepTrial: #CA2876;
$whatsApp: #25D366;
$trustpilot: #007f4e;

$videoBackground: #EEF4E5;