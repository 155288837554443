@use '/src/scss/layout/_layout.scss';
@use '/src/scss/base/_helpers.scss';
@use '/src/scss/layout/_breakpoints.scss';

@mixin tiles {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  @include layout.responsiveGrid(12, 0, 1.99, "gap");
}

@mixin tilesCell {
  width: 100%;
  @include layout.responsiveGrid(12, 0, 2, "margin-bottom");
  justify-content: stretch;
  display: flex;
}

@mixin tilex2 {
  @include layout.responsiveGrid(12, 6, -1, "width");
   @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    &.lb_tiles_cell:nth-child(2n+1) {
      @include layout.responsiveGrid(12, 0, 1.99, "margin-right");
    }
  }
}

@mixin tilex3 {
  @include layout.responsiveGrid(12, 4, -1.334, "width");
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    &.lb_tiles_cell {
      @include layout.responsiveGrid(12, 0, 1.99, "margin-right");
    }
    &.lb_tiles_cell:nth-child(3n+3) {
      margin-right:0;
    }
  }
}

@mixin tilex4 {
  @include layout.responsiveGrid(12, 3, -1.5, "width");
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    &.lb_tiles_cell {
      @include layout.responsiveGrid(12, 0, 1.99, "margin-right");
    }
    &.lb_tiles_cell:nth-child(4n+4) {
      margin-right:0;
    }
  }
}

@mixin tilesLeftAligh {
  justify-content: flex-start;
}