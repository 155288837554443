@use '/src/scss/styles/colors/_positiveNegative.scss';
@use '/src/scss/styles/colors/_colors.scss';

@mixin checkboxBase {
  cursor: pointer;
  display: block;
  height: 32px;
  width: 32px;
  border-radius: 5px;
  border: solid 1px colors.$surfaceColour;
} 

@mixin checkbox {
  @include checkboxBase;
  background-color: #ffffff;
}

@mixin checkboxSelected {
  @include checkboxBase;
  color: #ffffff;
  background-color: positiveNegative.$positiveColour;
  font-size: 16px;
  text-align: center;
  line-height: 28px;
}