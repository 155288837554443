
@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';

.lb_speachBubble {
  @include headings.textHeading;
  font-size: .9rem;
  text-align: center;
  background: #ED6D93;
  color: #ffffff;
  border-radius: 10px;
  @include layout.responsiveGrid(12, 8, -2, "width");
  @include layout.responsiveGrid(12, 2, 1, "margin-left");
  @include layout.responsiveGrid(8, 0, 2, "padding-left");
  @include layout.responsiveGrid(8, 0, 2, "padding-right");
  position: relative;
  margin-bottom: lineHeights.$lineHeight * 3;
}

.lb_speachBubble:after {
  position: absolute;
  z-index: -1;
  content: "";
  bottom: -16px;
  @include layout.responsiveGrid(12, 1, 0, "right");
  background: #ED6D93;
  border-radius: 10px;
  width: 48px;
  height: 48px;
  transform: skewY(-45deg);
  margin-top: auto;
}