@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/icons/abstracts/_svgIcons.scss';

.lb_productDetailsChristmas {
    color: #93132A;
    font-weight: fontWeights.$fontWeightBold;
    display: flex;
    font-size: .9rem;
    justify-content: center;

    &:after {
        content: "";
        width: 24px;
        height: 24px;
        background-size: 22px;
        background-repeat: no-repeat;
        @include svgIcons.christmasTree;
        display: block;
        position: relative;
        top: -2px;
        left: -2px;
    }

    &-reverse {
        flex-direction: row-reverse;
    }
}