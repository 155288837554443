@use '/src/scss/styles/abstracts/_fontWeights.scss';

@mixin iconButton {
  position: relative;
  font-weight: fontWeights.$fontWeightRegular;
  text-align: left;
  display: inline-block;
  color: #ffffff;
  background-color: #002E5E;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin iconButtonVSmall {
  border-radius: 8px;
  font-size: 10px;
}

@mixin iconButtonSmall {
  border-radius: 12px;
  font-size: 14px;
}

@mixin iconButtonMedium {
  border-radius: 19px;
  font-size: 22px;
}

@mixin iconButtonLarge {
  border-radius: 26px;
  font-size: 30px;
}

@mixin iconButtonXLarge {
  border-radius: 32px;
  font-size: 41px;
}

@mixin iconButtonXXLarge {
  border-radius: 40px;
  font-size: 47px;
}