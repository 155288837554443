@use '/src/scss/components/productDetails/abstracts/miniSpecification.scss';
@use '/src/scss/components/productDetails/abstracts/pricing.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/_signifiers.scss';

.lb_productDetailsPrice {
  @include pricing.productDetailsPriceMark;

  &.lb_productDetailsPrice-large {
    .lb_productDetailsPrice_mark {
      @include breakpoints.respond-to("tablet") {
        font-size: 2.25rem;
      }
    }
  }

  &.lb_productDetailsPrice-offer {
    color: signifiers.$offerColour;
  }

  .lb_productDetailsPrice_mark {
    font-size: 1.4rem;
    display: inline-block;
    margin-right: 6px;
  }

  .lb_productDetailsPrice_superText {
    color: colors.$textColour;
    font-size: .7rem;
    line-height: 1;
    margin-bottom: 0;
  }

  .lb_productDetailsPrice_saving {
    @include pricing.productDetailsSaving;
  }
}

.lb_productDetailsSaving {
  display: flex;
  flex-direction: row;
  position: relative;
  padding-top: 4px;
}

.lb_productDetailsSaving_rrp {
  @include pricing.productDetailsRrpWas;
}

.lb_productDetailsSaving_was {
  @include pricing.productDetailsRrpWas;
}

.lb_layoutSection-mobileCentred .lb_productDetailsSaving,
.lb_layoutSection-mobileCentred .lb_customerRating {
  justify-content: center;
}

.lb_layoutSection-desktopRightAlign .lb_customerRating {
  @include breakpoints.respond-to("desktop") {
    justify-content: right;
  }
}