@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_colors.scss';

.lb_mainMenuRibbon {
    display: none;

    @include breakpoints.respond-to("desktop") {
        display: block;
        background-color: colors.$primaryColour;
        height: 45px;
        position: relative;
    }

    .lb_mainMenu {
        display: none;

        @include breakpoints.respond-to("desktop") {
            color: #fff;
            display: flex;
            align-items: center;
            height: 45px;
            font-size: 0.9rem;
        }
    }
}