@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/styles/abstracts/_typography.scss';

.lb_filterBar {
  display: grid;
  grid-auto-columns: 1fr 1fr;
  @include layout.responsiveGrid(12, 0, 2, "column-gap");
  grid-template-rows: auto auto;
  grid-template-areas: 
  'filter sort'
  'results results';

    @include breakpoints.respond-to("desktop") {
      grid-template-rows: auto;
      grid-template-columns: auto 1fr;
      background: colors.$surfaceColourTintDark;
      //@include layout.responsiveGrid(10, 0, 1, "padding-left");
      //@include layout.responsiveGrid(10, 0, 1, "padding-right");
      padding: lineHeights.$lineHeight 0;
      height: 79px;
      grid-template-areas:
      'results sort';
      &:after {
        @include layout.solidBackground;
        height: 100%;
    }
  }

  .lb_filterBar_heading {
    @include headings.heading;
    @include headings.sectionHeading;
    margin-bottom: 16px;
    grid-area: heading;
    height: 48px;
    margin-left:auto;
    margin-right:auto;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    text-align: center;
    @include breakpoints.respond-to("desktop") {
      height: auto;
      display: block;
      margin-left:0;
      margin-right:0;
      margin-bottom: 4px;
      overflow: unset;
      text-align: left;
    }
  }

  .lb_filterBar_heading-clearance {
    color: signifiers.$offerColour;
  }

  .lb_filterBar_results {
    @include typography.text;
    grid-area: results;
    text-align: center;
    margin-top: 6px;
    @include breakpoints.respond-to("desktop") {
      margin-top: 14px;
      text-align: left;
    }
  }

  .lb_filterBar_sort {
    width: 100%;
    grid-area: sort;
    justify-self: end;
    margin-bottom: 0;
    @include breakpoints.respond-to("desktop") {
      width: 200px;
    }
  }

  .lb_filterBar_filter {
    width: 100%;
    grid-area: filter;
    margin-bottom: 0;
    height: 48px;

    .lb_icon-button:before {
      display: inline-block;
      line-height: 1;
      font-size: 24px;
    }

    @include breakpoints.respond-to("desktop") {
      display: none;
    }

    .lb_icon:before {
      top: 5px;
      margin-right: 8px;
    }
  }

}