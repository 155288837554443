@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/forms/abstracts/_button.scss';
@use '/src/scss/layout/_breakpoints.scss';

.lb_cookieConsent {
  position: fixed;
  bottom: 40px;
  @include layout.responsiveGrid (12, 0, 2, "right");
  z-index: 50;
  @include layout.responsiveGrid (12, 0, -4, "width");
  border-radius: 5px;
  @include layout.responsiveGrid (12, 0, 2, "margin-left");
  @include layout.responsiveGrid (12, 0, 2, "margin-left");
  padding: 12px 0;
  @include layout.responsiveGrid (12, 0, 1, "padding-left");
  @include layout.responsiveGrid (12, 0, 1, "padding-right");
  background: #ffffff;
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, .5);
  @include breakpoints.respond-to("tablet") {
    width: 100%;
    max-width: 450px;
  }

  .lb_cookieConsent_text {
    @include typography.text;
  }

  .lb_cookieConsent_btn {
    @include button.button;
    @include button.primaryButton;
    line-height: 32px;
    width: 100%!important;
    margin-top: 8px!important;
    margin-bottom: 4px!important;
    display: block!important;
  }
}