@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/_signifiers.scss';

@mixin mMHeading {
    font-weight: fontWeights.$fontWeightBold;
    color: colors.$primaryColour;
    font-size: 1rem;
}

@mixin mMSubHeading {
    font-weight: fontWeights.$fontWeightBold;
    color: colors.$primaryColour;
    font-size: .9rem;
    margin-bottom: 16px;
}

@mixin mMText {
    font-size: .9rem;
    color: colors.$textColour;
    line-height: 1;
    &:hover {
        color: colors.$textColour; 
    }
}

@mixin mMSmallText {
    font-size: .7rem;
    display: inline;
}

@mixin mMLink {
    font-size: .9rem;
    font-weight: fontWeights.$fontWeightSemiBold;
    color: signifiers.$primaryCallToActionColour;
    padding-left: 50px;
}