.lb_bannerAd {
  display: block;

  .lb_innerPageSection {
    padding: 0;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    cursor: pointer;
  }
}

.lb_bannerAd-clearance {
  background-color: #DA003D;
}

.lb_bannerAd-expressDelivery {
  background-color: #1C325B;
}

.lb_bannerAd-payMonthlyOptions {
  background-color: #24B9CF;
}

.lb_bannerAd-buyYourBedWithKlarna {
  background-color: #DA4795;
}

.lb_bannerAd-storeLocations {
  background-color: #9CBCD1;
}

.lb_bannerAd-needHelpChoosing {
  background-color: #F9A214;
}

.lb_bannerAd-removalAndRecycling {
  background-color: #A58CA2;
}

.lb_bannerAd-specialBuys {
  background-color: #DC003E;
}

.lb_bannerAd-bestOfBritish {
  background-color:#EC898C;
}

.lb_bannerAd-christmas {
  background-color:#93132A;
}
