@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';

.lb_popOut {

  .lb_sizeTile {
    margin-bottom: lineHeights.$lineHeight*.75;
    width: 100%;

    .lb_selectableTile_text {
      padding: 0 16px!important;
      margin: 0!important;
      width: 100%!important;
    }
  }

  .lb_selectableTile {

    .lb_productDetailsPrice {
      font-size: .8rem;
      line-height: 1.2;
    }

    .lb_selectableTileWrapper {
      
      .lb_selectableTile_text {
        @include layout.responsiveGrid(12, 0, 1, "margin-left");
        @include layout.responsiveGrid(12, 0, 1, "margin-right");
        @include layout.responsiveGrid(12, 12, -2, "width");
        @include breakpoints.respond-to("tablet") {
          @include layout.responsiveGrid(6, 0, 1, "margin-left");
          @include layout.responsiveGrid(6, 0, 1, "margin-right");
          @include layout.responsiveGrid(6, 6, -2, "width");
        }
        @include breakpoints.respond-to("desktop") {
          @include layout.responsiveGrid(3, 0, 1, "margin-left");
          @include layout.responsiveGrid(3, 0, 1, "margin-right");
          @include layout.responsiveGrid(3, 3, -2, "width");
        }
      }
    }
  }

  .lb_popOut-filters {
    
    .lb_productDetailsSaving {
      margin-bottom: 4px!important;
      margin-top: 1px;
    }

    .lb_productDetailsMinispec {
      margin-bottom: lineHeights.$lineHeight;
    }
  }
}

[data-pop-out-family="basketPopOut"] {
  background-color: transparent !important;
  @include breakpoints.respond-to("desktop") {
    background-color:#333333cc !important;
  }
}
