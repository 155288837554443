@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_headings.scss';

.lb_awardTile {
  width: 100%;
    padding-top: lineHeights.$lineHeight;
    padding-bottom: lineHeights.$lineHeight;
    @include layout.responsiveGrid(10, 0, 1, "padding-left");
    @include layout.responsiveGrid(10, 0, 1, "padding-right");
    border: solid 1px colors.$surfaceColourDark;
    background-color: #ffffff;
    @include breakpoints.respond-to("tablet") {
      height: 100%;
      width: 298px;
    }
  }

  .lb_awardTile_img {
    width: 176px;
    height: auto;
    margin-top: lineHeights.$lineHeight;
    margin-bottom: lineHeights.$lineHeight;
    @include layout.responsiveGrid(4, 1, 0, "margin-left");
    @include layout.responsiveGrid(4, 1, 0, "margin-right");
    @include layout.responsiveGrid(4, 2, 0, "width");
  }

  .lb_awardTile_header {
    @include headings.heading;
    color: #303030;
    text-align: center;
    padding-top: lineHeights.$lineHeight;
    padding-bottom: lineHeights.$lineHeight;
    @include layout.responsiveGrid(10, 0, 1, "padding-left");
    @include layout.responsiveGrid(10, 0, 1, "padding-right");
    @include breakpoints.respond-to("tablet") {
      @include layout.responsiveGrid(5, 0, 1, "padding-left");
      @include layout.responsiveGrid(5, 0, 1, "padding-right");
    }
      @include breakpoints.respond-to("desktop") {
      @include layout.responsiveGrid(4, 0, 1, "padding-left");
      @include layout.responsiveGrid(4, 0, 1, "padding-right");
    }

    .lb_awardTile_header_date {
      display: block;
      color: #002E5E;
    }
  }