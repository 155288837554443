$shimmerDark: #e0e0e0;
$shimmerLight: #f0f0f0;

@mixin shimmer {
	background: linear-gradient(135deg, $shimmerDark, $shimmerDark 40%, $shimmerLight 44%, $shimmerDark 48%, $shimmerLight 52%, $shimmerDark 56%);
	background-size: 800% 800%;
	animation: gradient 2s linear infinite;
}

@mixin shimmerTile {
    content: "";
    background-color: transparent;
    @include shimmer;
}

@keyframes gradient {
	0% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 50% 0%;
	}
}

@mixin loadingTile {
	display: flex!important;
    flex-direction: column;
	border: 0!important;
	padding: 0!important;
	background-color: transparent!important;
	background: transparent!important;
	cursor: unset!important;
	* {
		display: none!important;
	}
}