@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/colors/_signifiers.scss';

@mixin button {
  font-size: .9rem;
  color: #ffffff;
  display: block;
  width: 100%;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 5px;
  line-height: lineHeights.$lineHeight*3;
  text-align: center;
  border: 0;
  background-color: signifiers.$secondaryCallToActionBackgroundColour;
  font-weight: fontWeights.$fontWeightBold;
  @include breakpoints.respond-to("tablet") {
    display: inline-block;
    width: auto;
    margin: 0 3px lineHeights.$lineHeight 0;
  }
}

@mixin primaryButton {
  background-color: signifiers.$primaryCallToActionBackgroundColour;
}

@mixin secondaryButton {
  background-color: signifiers.$secondaryCallToActionBackgroundColour;
}

@mixin surfaceButton {
  background-color: colors.$surfaceColourLight;
  color: colors.$textColour;
}

@mixin unavailableButton {
  text-shadow: 1px 1px 1px #FAFAFA;
  color: #BABABA;
  background-color: #DADADA;
  cursor: text;
}

@mixin secondaryButtonOutline {
  border: solid 1px signifiers.$secondaryCallToActionBackgroundColour;
  background-color: white;
  color: signifiers.$secondaryCallToActionBackgroundColour;
}

@mixin tensionButton {
  @include typography.miniText;
  height: lineHeights.$lineHeight*2;
  min-width: 100px;
  width: 100px;
  padding: 0;
  font-weight: fontWeights.$fontWeightBold;
}

@mixin buttonOutline {
  border: solid 1px signifiers.$secondaryCallToActionBackgroundColour;
  background-color: transparent;
  color: signifiers.$secondaryCallToActionBackgroundColour;
}
