@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_listItems.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/icons/abstracts/_arrows.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_signifiers.scss';

.lb_megaMenu-option {
  margin-top: -20px;

  .lb_megaMenu_heading {
    position: relative;
    @include listItems.listItem;
    color: colors.$textColour;
    margin-bottom: 0;
    @include layout.responsiveGrid(12, 0, 2, "padding-left");
    border-bottom: 1px solid colors.$surfaceColourLight;
    font-weight: fontWeights.$fontWeightBold;
    font-size: 0.95rem;

    &:after {
      @include arrows.arrow;
      @include arrows.arrowUp;
      transition: all 250ms linear;
      color: colors.$textColour;
      right: 0;
      left: initial;
      position: absolute;
      @include layout.responsiveGrid(12, 0, 2, "right");
    }
  }

  .lb-hidden.lb_megaMenu_heading:after {
    @include arrows.arrowDown;
  }

  .lb_megaMenu_list {
    height: auto;

    .lb_megaMenu_list_item_link {
      @include listItems.listItem;
      line-height: 1.6 !important;
      color: colors.$textColour !important;
      display: block;
      padding: 11px 0;
      
      &:before {
        width: 40px;
        font-size: 23px;
        @include layout.responsiveGrid(12, 0, 1, "margin-right");
      }
      
      &:after {
        display: none;
      }
    }
  }
  
  .lb_megaMenu_list-indent {
    @include listItems.listItemIndent;
  }

  .lb_megaMenu_list_item-all {
    .lb_megaMenu_list_item_link {
      color: signifiers.$primaryCallToActionColour!important;
      
      &:after,
      &:before {
        display: none;
      }
    }
  }

  @include breakpoints.respond-to("desktop") {
    margin-top: 0;
    display: none;
    @include layout.responsiveGrid(12, 3, 0, "left");
    @include layout.responsiveGrid(12, 9, 0, "width");
    position: absolute;
    top: 0;
    height: 100%;

    .lb_megaMenu_heading {
      border: 0;
      padding: 0;
      margin-top: lineHeights.$lineHeight*.5;
      margin-bottom: lineHeights.$lineHeight *.25;
      
      &:after {
        display: none;
      }
    }

    .lb_megaMenu-optionList {
      @include layout.responsiveGrid(12, 0, 1, "padding-left");
      @include layout.responsiveGrid(12, 0, 1, "padding-right");
      margin: 0;
      width: 33.33%;
      float: left;
    }

    .lb_megaMenu_list_item {
      margin: 0;
      padding: 0;
      border: 0;
      width: 100%;

      .lb_megaMenu_list_item_link {
        display: block!important;
        margin: 0 0 lineHeights.$lineHeight!important;
        padding: 0!important;
        border-bottom: 0!important;
        font-size: .8rem;
        font-weight: fontWeights.$fontWeightRegular!important;
        background-color: transparent!important;
        line-height: 1.3!important;

        &:after {
          display: none !important;
        }
      }
    }
    
    .lb_megaMenu_list_item-all .lb_megaMenu_list_item_link {
      font-weight: fontWeights.$fontWeightBold!important;
    }
  }
}

.lb-active .lb_megaMenu-option {
  display: block;

  .lb_text-small {
    font-weight: fontWeights.$fontWeightRegular;
  }

}
.lb_megaMenu-optionList-shopAll a {
  text-decoration: none!important;
  color: #368036!important;
}