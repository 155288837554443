/* ordered */
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_listItems.scss';

.lb_megaMenu-category {
  margin-top: -20px;
  @include breakpoints.respond-to("desktop") {
    margin-top: 0;
    background-color: colors.$surfaceColourTintDark;
  }

  >.lb_megaMenu_list > .lb_megaMenu_list_item > .lb_megaMenu_list_item_link {
    @include listItems.listItem;
    color: colors.$textColour;
    @include layout.responsiveGrid(12, 0, 3, "padding-left");
    @include layout.responsiveGrid(12, 0, 3, "padding-right");
    border-right: 1px solid #cccccc;
  }

  @include breakpoints.respond-to("desktop") {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;

    >.lb_megaMenu_list {
      height: 588px;
      @include layout.responsiveGrid(12, 3, -1, "width");
      background: #ffffff;
      
      >.lb_megaMenu_list_item {
        padding: 0;
      }
    }
    
    .lb_megaMenu_list_item_link:after {
      display: block;
    }
    
    .lb_megaMenu_list_item_link {
      @include listItems.listItem;
      @include typography.text;
      line-height: lineHeights.$lineHeight*3;
    }

    .lb-active>.lb_megaMenu_list_item_link {
      background-image: linear-gradient(to right, #ffffff 140px, colors.$surfaceColourTintDark 100%);
      border-right: 0!important;
    }
  }
}