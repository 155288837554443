@use '/src/scss/layout/_layout.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/forms/abstracts/_button.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_headings.scss';

@mixin notificationLayout {
  @include layout.responsiveGrid (12, 0, 2, "padding-left");
  @include layout.responsiveGrid (12, 0, 2, "padding-right");
}

.lb_notification {
  position: fixed;
  bottom: -300px;
  opacity: 0;
  z-index: 99999999;
  background: #ffffff;
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, .5);
  transition: opacity 1s ease-in-out, bottom 1s ease-in-out;

  @include breakpoints.respond-to("tablet") {
    width: 450px;
    @include layout.responsiveGrid (12, 0, 2, "right");
  }

  &.lb-active {
    bottom: 0;
    opacity: 1;
    .lb_notification_footer_progressBar{
      .lb_notification_footer_progressBar_progress {
        width: 100%;
      }
    }

    @include breakpoints.respond-to("tablet") {
      bottom: 36px;
    }
  }

  .lb_notification_close {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
  }
  .lb_notification_header {
    display: grid;
    grid-template-columns: 64px auto auto;
    grid-template-areas:
        "avatar agentName"
        "avatar agentRole";
        margin-bottom: 16px;
        @include notificationLayout;
        padding-top: 24px;

    .lb_notification_header_avatar {
      clip-path: circle(26px at center);
      grid-area: avatar;
    }

    .lb_notification_header_agentName {
      grid-area: agentName;
      @include headings.categoryHeading;
      margin-bottom: 0;
    }

    .lb_notification_header_agentRole {
      grid-area: agentRole;
      @include typography.textLarge;
    }
  }

  .lb_notification_text {
    @include typography.textLarge;
    margin-bottom: 16px;
    @include notificationLayout;
  }
  
  @mixin notificationButton {
    @include button.button;
    width: 100%!important;
    display: flex!important;
    margin: 0!important;
    font-size: 1rem;
    justify-content: center;
    gap: 4px;
    &[data-notification-button-show="false"] {
      display: none!important;
    }
  }

  .lb_notification_footer {
    display: flex;
    @include layout.responsiveGrid (12, 0, 1, "gap");
    @include notificationLayout;
    margin-bottom: 16px;
  }

  .lb_notification_footer_button-phone {
    @include notificationButton;
  }

  .lb_notification_footer_button-chat {
    @include notificationButton;
    background-color: colors.$chatColour;
  }

  .lb_notification_footer_button-chat:before {
    font-weight: fontWeights.$fontWeightRegular;
  }

  .lb_notification_footer_progressBar {
    background-color: colors.$chatColourDark;
    position: relative;
    top: 1px;
    height: 8px;

    .lb_notification_footer_progressBar_progress {
      background-color: colors.$chatColour;
      height: 8px;
      width: 0;
      transition-property: width;
      transition-delay: 2s;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}