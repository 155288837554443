﻿@use '/src/scss/forms/abstracts/_baseForm.scss';.lb_formElement-unavailable {


  .lb_formElement_input:not(button) {
    border: solid 2px baseForm.$formBorderUnavailable!important; 
  }
  .lb_formElement_label {
    color: baseForm.$formBorderUnavailable!important;
  }
  .lb_formElement_message {
    color: baseForm.$formBorderUnavailable!important;
  }
  .lb_formElement_input:not(button):hover~.lb_formElement_label {
    color: baseForm.$formBorderUnavailable!important; 
  }
}