@use '/src/scss/base/_paths.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/layout/abstracts/_lbGrid.scss';

.lb_popOut-search {
    z-index: 0 !important;

    .lb_popOut_header_backlink {
        display: none;
        cursor: pointer;
    }

    &.lb-active {
        z-index: 30 !important;

        .lb_popOutWrapper {
            @include breakpoints.respond-to("desktop") {
                overflow-y: scroll !important;
            }

            .lb_layoutSection-margin {
                @include lbGrid.lbGrid(12, 0, 1, "margin-left");
                @include lbGrid.lbGrid(12, 0, 1, "margin-right");
            }
        }

        .lb_popOut_header_backlink {
            @include breakpoints.respond-to("desktop") {
                order: 3;
                width: 293px;
                display: block;
                font-size: .9rem;
                text-align: right;
                font-weight: fontWeights.$fontWeightSemiBold;

                &:before {
                    margin-right: 6px;
                }
            }
        }

        .lb_searchBox_clear {
            left: initial;
            right: 26px;
            margin-top: 4px;

            @include breakpoints.respond-to("desktop") {
                left: calc(50% + 219px);
                right: initial;
                margin-top: -1px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }


    .lb_popOut_body {
        text-align: center;

        .lb_lW {
            @include lbGrid.lbGridInnerPage("padding-left");
            @include lbGrid.lbGridInnerPage("padding-right");
            max-width: 1472px !important;
            margin: 0 auto;
        }
    }
}

.lb_searchResults {
    display: grid !important;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: start;
    text-align: left;

    @include breakpoints.respond-to("tablet") {
        grid-template-columns: 33.3% 1fr;
    }

    @include breakpoints.respond-to("desktop") {
        padding-top: 0;
    }

    .lb_list_item {
        @include typography.menuItem;
        margin: 6px 0 10px 0 !important;
        line-height: 1.4;
    }

    .lb_list_item a {
        color: colors.$textColour;
        text-decoration: underline;
        font-weight: fontWeights.$fontWeightSemiBold;
    }

    .lb_heading {
        display: none;

        @include breakpoints.respond-to("desktop") {
            display: block;
            margin-bottom: 16px;
        }
    }

    .lb_searchResults_resultsList {

        .lb_list_item:nth-child(n+6) {
            display: none;
        }

        @include breakpoints.respond-to("tablet") {
            @include layout.responsiveGrid(12, 0, 2, "padding-right");
        }

        @include breakpoints.respond-to("desktop") {
            .lb_list_item {
                display: list-item !important;
            }
        }
    }

    .lb_searchResults_resultsTiles {
        .lb_tiles_cell:nth-child(n+7) {
            display: none;
        }

        @include breakpoints.respond-to("tablet") {
            .lb_tiles_cell {
                display: flex !important;
            }
        }

        .lb_productTile {
            border: solid 1px #cccccc;
            padding: 15px;

            .lb_productDetailsImage img {
                margin: -15px -15px 0 -15px;
                width: calc(100% + 30px);
            }
        }

        .lb_starRating {
            align-content: flex-start !important;
            flex-direction: column;
            gap: 6px;

            .lb_starRating_reviews {
                margin: 0;
            }
        }
    }
}

.lb_searchResultsList_item {
    @include typography.menuItem;
    line-height: layout.$lineHeight;
    padding: layout.$lineHeight*.5 0;
    position: relative;
    background-color: #ffffff;
    width: 100%;
    display: block;
    text-decoration: none;
    color: colors.$textColour;
}

.lb_searchResultsList_item a {
    text-decoration: none;
    color: colors.$textColour;
}

.lb_searchResultsList_item:hover {
    cursor: pointer;
}