/*IMPROVE: .lb_layoutSection-padding and .lb_layoutSection-mobilePadding are duplicated. .lb_layoutSection-padding need to be removed*/
@use '/src/scss/layout/abstracts/_padding.scss';

.lb_layoutSection-topPadded {
  @include padding.topPadded;
}

.lb_layoutSection-topPaddedLarge {
  @include padding.topPaddedLarge;
}

.lb_layoutSection-topPaddedSmall {
  @include padding.topPaddedSmall;
}

.lb_layoutSection-topPaddedXSmall {
  @include padding.topPaddedXSmall;
}

.lb_layoutSection-bottomPaddedXSmall {
  @include padding.bottomPaddedXSmall;
}

.lb_layoutSection-bottomPaddedSmall {
  @include padding.bottomPaddedSmall;
}

.lb_layoutSection-bottomPadded {
  @include padding.bottomPadded;
}

.lb_layoutSection-bottomPaddedLarge {
  @include padding.bottomPaddedLarge;
}

.lb_layoutSection-bottomPaddedXLarge {
  @include padding.bottomPaddedXLarge;
}

.lb_layoutSection-padded,
.lb_layoutSection-mobilePadded {
  @include padding.mobilePadded;
}

.lb_layoutSection-tabletPadded {
  @include padding.tabletPadded;
}

.lb_layoutSection-desktopPadded {
  @include padding.desktopPadded;
}

.lb_layoutSection-mobileDoublePadded {
  @include padding.mobileDoublePadded;
}

.lb_layoutSection-mobileSuperPadded {
  @include padding.mobileSuperPadded;
}

.lb_layoutSection-desktopSuperPadded {
  @include padding.desktopSuperPadded;
}