﻿@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';

.lb_form {
  width: 100%;
  padding: lineHeights.$lineHeight 0 0 0;
  overflow: hidden;
}

.lb_formElement {
  width: 100%;
  position: relative;
  margin-bottom: lineHeights.$lineHeight;
  text-align: left;

  .lb_formElement_label_optional {
    @include typography.text;
  }
}




