@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/components/tiles/abstracts/_selectableTile.scss';

.lb_tensionTile {
  @include selectableTile.selectableTile;
  display: flex;
  flex-direction: row;
  min-height: 80px;
  align-items: center;
  margin-bottom: lineHeights.$lineHeight * .75;
  padding: 16px!important;
  
  &.lb-selected {
    background: #ffffff;
    padding: 0 15px!important;
  }

  .lb_button {
    margin: 0 16px 0 0;
  }

  .lb_text {
    line-height: 1.25;
    margin: 0 16px 0 0;
  }

  .lb_tensionMark {
    font-size: 0.7rem;
    padding: 8px 12px;
    margin-right: 12px;
    width: 150px;
    top: initial;
    cursor: pointer;
  }
}