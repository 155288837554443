﻿@use '/src/scss/icons/abstracts/_fontIcons.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/abstracts/trustpilot.scss';
@use '/src/scss/layout/_breakpoints.scss';

.lb_customerRatingFilter {
  color: colors.$textColour;
  display: flex;
  gap: 6px;
  align-items: center;
  line-height: 1;
  font-weight: fontWeights.$fontWeightSemiBold;
  @include breakpoints.respond-to("desktop") {
    gap: 12px;
  }

  .lb_customerRatingFilter_item-all {
    cursor: pointer;
    padding: 6px;
    white-space: nowrap;
    display: flex;
    gap: 5px;

    span {
      display: none;

      @include breakpoints.respond-to("tablet") {
        display: block;
      }
    }
  }

  .lb_customerRatingFilter_item-star5,
  .lb_customerRatingFilter_item-star4,
  .lb_customerRatingFilter_item-star3,
  .lb_customerRatingFilter_item-star2,
  .lb_customerRatingFilter_item-star1 {
    display: flex;
    gap: 3px;
    align-items: flex-end;
    padding: 6px;
    cursor: pointer;
  }

  @mixin starRatedFilter {
    @include fontIcons.star;
    font-size: 14pt;
  }

  .lb_customerRatingFilter_item-star5:before {
    @include starRatedFilter;
    color: trustpilot.$rated5;
  }

  .lb_customerRatingFilter_item-star4:before {
    @include starRatedFilter;
    color: trustpilot.$rated4;
  }

  .lb_customerRatingFilter_item-star3:before {
    @include starRatedFilter;
    color: trustpilot.$rated3;
  }

  .lb_customerRatingFilter_item-star2:before {
    @include starRatedFilter;
    color: trustpilot.$rated2;
  }

  .lb_customerRatingFilter_item-star1:before {
    @include starRatedFilter;
    color: trustpilot.$rated1;
  }

  .lb-active {
    border-color: signifiers.$trustpilot;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    background-color: #ffffff;
  }

}