﻿@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/abstracts/trustpilot.scss';

.lb_customerRatingBreakdown {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .lb_customerRatingBreakdown_meterItem {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #124b92;
    margin-bottom: 7px;
    cursor: pointer;
    gap: 10px;
  }

  .lb_customerRatingBreakdown_meterItem_label,
  .lb_customerRatingBreakdown_meterItem_value {
    @include typography.text;
    cursor: pointer;
  }

  .lb_customerRatingBreakdown_meterItem_bar {
    flex: 1;
    height: 20px;
  }

  #bar1::-webkit-meter-optimum-value {
    background: trustpilot.$rated5;
  }

  #bar2::-webkit-meter-optimum-value {
    background: trustpilot.$rated4;
  }

  #bar3::-webkit-meter-optimum-value {
    background: trustpilot.$rated3;
  }

  #bar4::-webkit-meter-optimum-value {
    background: trustpilot.$rated2;
  }

  #bar5::-webkit-meter-optimum-value {
    background: trustpilot.$rated1;
  }

}

