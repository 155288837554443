@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/abstracts/_lbGrid.scss';
@use '/src/scss/components/popOut/abstracts/_popOut.scss';
@use '/src/scss/icons/abstracts/_arrows.scss';

.lb_mM {
    @include popOut.popOut; 
    > div {
        @include popOut.popOutWrapper;
        
        > div:first-child {
            @include popOut.popOutStatics;
            @include popOut.popOutHeader;

            > div {
                @include popOut.backLink;

                float: right;
                padding-top: 4px;
        
                &:before {
                    @include arrows.arrow;
                    @include arrows.arrowBackward;
                    margin-right: 6px;
                }
            }
        }
    }
}

@include breakpoints.respond-to("desktop") {
    .lb_mM {
        
        @include lbGrid.layoutSection-outerPage;

        @include breakpoints.respond-to("desktop") {
            display: block;
            background-color: colors.$primaryColour;
            position: relative;

            div {
                @include lbGrid.layoutSection-innerPage;
                height: 100%;

                ul {
                    display: flex;
                    height: 100%;

                    li {
                        height: 100%;

                        div {
                            padding: 19px;
                            color: #ffffff;
                            display: flex;
                            align-items: center;
                            height: 45px;
                            font-size: 0.9rem;
                            font-weight: 600;

                            &:hover {
                                background: #ffffff;
                                color: colors.$primaryColour;
                                cursor: pointer;
                            }
                        }
                        &:last-child {
                            margin-left: auto;
                        }
                    }
                }
            }
        }

        // .lb_mainMenu {


        //     @include breakpoints.respond-to("desktop") {
        //         color: #fff;
        //         display: flex;
        //         align-items: center;
        //         height: 45px;
        //         font-size: 0.9rem;
        //     }
        // }
    }
}