@use '/src/scss/icons/abstracts/fontIcons.scss';
@use '/src/scss/icons/abstracts/sizes.scss';
@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/base/_helpers.scss';

.lb_socialIcons_heading {
  @include headings.heading;
  margin-bottom: lineHeights.$lineHeight;
}

.lb_socialIcons_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  @include helpers.gapfix(12px);
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.lb_socialIcons_list_item {
  display: block;
  cursor: pointer;
  text-decoration: none;
}

.lb_socialIcons_list_item_link {
  display: inline-block;
}

.lb_socialIcons_list_item_link:before {
  font-size: 28pt;
  color: colors.$primaryColour;
}

.lb_socialIcons_list_item-facebook a:before {
  @include fontIcons.facebook;
}

.lb_socialIcons_list_item-instagram a:before {
   @include fontIcons.instagram;
 }

 .lb_socialIcons_list_item-twitter a:before {
  @include fontIcons.twitter;
}

.lb_socialIcons_list_item-youtube a:before {
  @include fontIcons.youtube;
}

.lb_socialIcons_list_item-whatsApp a:before {
  @include fontIcons.whatsApp;
}

.lb_socialIcons-negative .lb_socialIcons_list_item a:before {
  color: #ffffff;
}