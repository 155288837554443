@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/components/tiles/abstracts/_selectableTile.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/_signifiers.scss';

.lb_selectableTile {
  @include selectableTile.selectableTile;

  &:hover .lb_heading {
    color: signifiers.$primaryCallToActionColour;
  }

  .lb_selectableTileWrapper {
    @include selectableTile.selectableTileWrapper;

    .lb_selectableTile_img {
      @include selectableTile.selectableTileImg
    }

    .lb_selectableTile_svg {
      display: block;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: top 10% center;
      margin: 0 25%;
    }

    .lb_selectableTile_svg:before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    .lb_selectableTile_text {
      @include typography.text;
      text-align: center;
      line-height: 1.25;
      @include layout.responsiveGrid(12, 0, 2, "margin-left");
      @include layout.responsiveGrid(12, 0, 2, "margin-right");
      @include layout.responsiveGrid(12, 12, -4, "width");
    }
  }

  .lb_productDetailsPrice {
    margin-top: 12px;
  }

  &.lb-selected {
   @include selectableTile.selectableTile-selected;
  }

  &.lb_selectableTile-square {
    @include selectableTile.selectableTile-square;
  }

  // &.lb_selectableTile-topAlign {
  //   @include selectableTile.selectableTile-topAlign;
  // }

  // &.lb_selectableTile-middleAlign {
  //   @include selectableTile.selectableTile-middleAlign;
  // }

  // &.lb_selectableTile-bottomAlign {
  //   @include selectableTile.selectableTile-bottomAlign;
  // }

  &.lb_selectableTile-leftAlign {
    @include selectableTile.selectableTile-leftAlign;
  }

  &.lb_selectableTile-centreAlign {
    @include selectableTile.selectableTile-centreAlign;
  }
}