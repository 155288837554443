@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/styles/abstracts/_typography.scss';

@mixin productDetailsPriceMark {
  font-size: 1.5rem;
  line-height: 1;
  font-weight: fontWeights.$fontWeightBold;
  color: colors.$primaryColour;
}

@mixin productDetailsRrpWas {
  font-size: .8rem;
  line-height: 1;
  white-space: nowrap;
  text-decoration: line-through;
  font-weight: fontWeights.$fontWeightRegular;
  margin-right: 8px;
  color: colors.$textColour;
}

@mixin productDetailsSaving {
  @include typography.text;
  font-weight: fontWeights.$fontWeightBold;
  white-space: nowrap;
  color: signifiers.$offerColour;
  position: relative;
  display: inline-block;
}