@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_listItems.scss';

.lb_megaMenu-desktop {
  display: none;

  @include breakpoints.respond-to("desktop") {
    display: block;
  }

  z-index: 20;
}

.lb_megaMenu {
  @include breakpoints.respond-to("desktop") {}
}

.lb_megaMenu-mobile {
  @include breakpoints.respond-to("desktop") {
    display: none;
  }
}

.lb_megaMenu-mobile.lb-active:after {
  content: "";
  position: fixed;
  top: 0;
  right: 16px;
  font-size: 1.8rem;
  color: #ffffff;
  font-weight: fontWeights.$fontWeightBold;
  pointer-events: none;
}

.lb_megaMenu-mobile.lb_megaMenu {
  position: fixed;
  top: 0;
  right: 100%;
  box-shadow: none;
  height: 100%;
  transition: right 250ms;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  @include layout.responsiveGrid(12, 11, 1, "width");
}

.lb_megaMenu-mobile.lb-active {
  @include layout.responsiveGrid(12, 1, 0, "right");
  @include layout.responsiveGrid(12, 11, 0, "width");
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, .5);
}

.lb_megaMenu-mobile .lb_megaMenu {
  transition: left 250ms ease-in-out;
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
  border-left: solid 1px #555555;
}

;

.lb_megaMenu-mobile .lb_megaMenu.lb-active {
  left: 0;
  overflow-y: auto;
  box-shadow: -3px 0 8px 0px rgba(0, 0, 0, 0.5);
}

;

.lb_megaMenu.lb-hidden.lb-active {
  overflow-y: hidden !important;
}

;

// MOBILE FIRST / MIXINS
.lb_megaMenu {
  width: 100%;
  position: relative;
}

.lb_megaMenu_list_item_link_img {
  @include listItems.listItemImage;
}

.lb_megaMenu-link,
.lb_megaMenu-link:hover {
  @include typography.linkPrimary;
}