@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/styles/abstracts/_loading.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/forms/abstracts/_button.scss';
@use '/src/scss/styles/colors/abstracts/_tension.scss';
@use '/src/scss/icons/abstracts/_fontIcons.scss';

.lb_button {
  @include button.button;
  margin-bottom: lineHeights.$lineHeight;

  &.lb_button-primary {
    @include button.primaryButton;
  }

  &.lb_button-secondary {
    @include button.secondaryButton;
  }
  
  &.lb_button-surface {
    @include button.surfaceButton;
  }

  &.lb_button-unavailable {
    @include button.unavailableButton;
  }

  &.lb_button-outline {
    @include button.buttonOutline;
  }

  &.lb_button-wide {
    padding: 0 64px;
  }

  &.lb_button-largeText {
    font-size: 1.1rem;
    gap: 5px;
  }

  &.lb_button-narrow {
    display: inline-block;
  }

  &.lb_button-small {
    width: 120px;
    display: inline-block;
  }

  &.lb_button-minimalWidth {
    width: inherit;
    display: inline-block;
  }

  &.lb_button-stretch {
    width: 100%;
    margin-right: 0;
  }

  &.lb_button-short {
    line-height: 32px;
  }

  &.lb_button-vShort {
    line-height: 24px;
  }
}

// .lb_button-soft {
//   @include button.tensionButton;
//   background: tension.$softColour;
// }

// .lb_button-softMedium {
//   @include button.tensionButton;
//   background: tension.$softMediumColour;
// }

// .lb_button-medium {
//   @include button.tensionButton;
//   background: tension.$mediumColour;
// }

// .lb_button-mediumFirm {
//   @include button.tensionButton;
//   background: tension.$mediumFirmColour;
// }

// .lb_button-firm {
//   @include button.tensionButton;
//   background: tension.$firmColour;
// }

// .lb_button-extraFirm {
//   @include button.tensionButton;
//   background: tension.$extraFirmColour;
// }

.lb_button-link {
  @include typography.text;
  text-decoration: none;
  border: 0;
  background-color: transparent!important;
  color: colors.$primaryColour;
  text-decoration: underline;
  margin: 0!important;
  padding: 0!important;
  &:hover {
    text-decoration: underline;
    color: signifiers.$primaryCallToActionColourHover;
    cursor: pointer;
  }
}

.lb_button.lb_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    font-weight: fontWeights.$fontWeightRegular;
    top: -4px;
    line-height: 1;
  }
}

.lb_button-filter {
  margin: 0;
  border-radius: 10px;
  border: solid 1px colors.$surfaceColourLight;
  background-color: #ffffff;
  color: colors.$surfaceColourDark;
  line-height: 32px;
  width: auto;
  display: inline-block;
  padding: 0 12px;

  &:after {
    @include fontIcons.cross;
    font-size: .8rem;
    padding-left: 8px;
    position: relative;
    top: 1px;
    font-weight: fontWeights.$fontWeightRegular;
  }

  &.lb-loadingTile {
    @include button.unavailableButton;
    @include loading.shimmerTile;
    color: #cfcfcf;
  }
}

.lb_button.lb_spinnyWheel {
  width: auto!important;
  height: auto!important; 
  
  &:before {
    background-size: 40px;
  }
}