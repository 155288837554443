@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/layout/_breakpoints.scss';

@mixin alphabeticalList {
  
  dt {
    @include headings.heading;
    float: left;
    line-height: layout.$lineHeight*2;
    height: layout.$lineHeight*2;
    display: inline;
    padding-top: 40px;
  }

  dt:first-of-type {
    padding-top: 0;
  }
  
  dd {
    @include typography.text;
    line-height: 32px;
    padding-left: 40px;

    a {
      @include typography.text;
      text-decoration: none;
      font-weight: fontWeights.$fontWeightRegular;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  dd:first-of-type {
    border: 0;
    margin-top: 0;
    padding-top: 0;
  }

  dt + dd {
    border-top: solid 1px #cccccc;
    padding-top: 20px;
    margin-top: 20px;
  }
  @include breakpoints.respond-to("desktop") {
    dt {
      margin-top: 0;
      padding-top: 0;
    }

    dt + dd {
     border: 0;
     margin-top: 0;
     padding-top: 0;
    }
   }
}

