@mixin iconVSmall {
  font-size: 14px;
  width: 16px;
  height: 16px;
}

@mixin iconSmall {
  font-size: 24px;
  width: 24px;
  height: 24px;
}

@mixin iconMedium {
  font-size: 36px;
  width: 36px;
  height: 36px;
}

@mixin iconLarge {
  font-size: 48px;
  width: 48px;
  height: 48px;
}

@mixin iconXLarge {
  font-size: 64px;
  width: 64px;
  height: 64px;
}

@mixin iconXXLarge {
  font-size: 80px;
  width: 80px;
  height: 80px;
}