@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/icons/abstracts/_arrows.scss';

.lb_contentTile {
  height: 100%;
  overflow: hidden;
  text-align: center;
  background-color: colors.$surfaceColourTintDark;
  flex-direction: column;
  display: flex;
  padding-bottom: lineHeights.$lineHeight;

  .lb_contentTile_img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 16px;
  }

  .lb_heading {
    @include headings.heading;
    margin-left: 0 !important;

    &:before {
      color: colors.$surfaceColourTintDark;
    }
  }

  .lb_textHeading {
    @include headings.textHeading;
    font-size: 1rem !important;
    @include layout.responsiveGrid(10, 0, 2, "padding-left");
    @include layout.responsiveGrid(10, 0, 2, "padding-right");

    @include breakpoints.respond-to("tablet") {
      @include layout.responsiveGrid(5, 0, 2, "padding-left");
      @include layout.responsiveGrid(5, 0, 2, "padding-right");
    }
  }

  .lb_text {
    @include layout.responsiveGrid(10, 0, 2, "padding-left");
    @include layout.responsiveGrid(10, 0, 2, "padding-right");

    @include breakpoints.respond-to("tablet") {
      @include layout.responsiveGrid(5, 0, 2, "padding-left");
      @include layout.responsiveGrid(5, 0, 2, "padding-right");
    }
  }

  .lb_contentTile_logo {
    @include layout.responsiveGrid(10, 0, 2, "margin-left");
    @include layout.responsiveGrid(10, 0, 2, "margin-right");
    float: right;
    margin-bottom: lineHeights.$lineHeight *.5;

    @include breakpoints.respond-to("tablet") {
      @include layout.responsiveGrid(5, 0, 2, "margin-left");
      @include layout.responsiveGrid(5, 0, 2, "margin-right");
    }
  }
}

.lb_contentTile-border {
  text-align: left;
  display: block;
  border: solid 1px #cccccc;
  background-color: #ffffff;

  .lb_textHeading {
    margin: lineHeights.$lineHeight*2 150px lineHeights.$lineHeight*.5 0;
  }

  .lb_contentTile_logo {
    margin-top: lineHeights.$lineHeight;
  }
}

.lb_charitiesTile {
  margin: 0 calc(50% - 64px);

  @include breakpoints.respond-to("tablet") {
    margin: 0;
  }
}

.lb_contentTile-showroom {
  padding-bottom: 0;
}

[data-static="showroom"] {
  .lb_article-zigzag.lb_article-border .lb_phoneDetails h2,
  .lb_article-zigzag.lb_article-border .lb_addressDetails h2 {
    margin: 12px 0 4px !important;
    font-size: 1rem!important;
  }
  


  .lb_phoneDetails {
    margin-bottom: 24px!important;
  }
}


.lb_contentTile-showroom {

  text-align: left !important;

  h2 {
    margin-top: lineHeights.$lineHeight;
    margin-top: 36px;
    margin-bottom: 24px;
    text-align: left !important;
  }

  h3 {
    text-align: left !important;
    font-weight: 400!important;
    margin-bottom: 4px!important;
    line-height: 1.1!important;
  }

  .lb_phoneDetails {
    margin-bottom: 24px;
  }

  .lb_openingTimes {
    margin-top: 16px;
  }

  >* {
    @include layout.responsiveGrid(12, 0, 4, "padding-left");
    @include layout.responsiveGrid(12, 0, 4, "padding-right");
    text-align: left;
  }

  .lb_contentTile_img {
    margin-bottom: 36px;
  }

  a:first-child {
    padding: 0 !important;
  }

  .lb_button {
    margin-top: lineHeights.$lineHeight;
    margin-bottom: lineHeights.$lineHeight;
    @include layout.responsiveGrid(12, 0, 4, "margin-left");
    @include layout.responsiveGrid(12, 0, 4, "margin-right");
    width: auto !important;

    @include breakpoints.respond-to("tablet") {
      margin-left: 5.5% !important;
      margin-right: 5.5% !important;
    }

    @include breakpoints.respond-to("desktop") {
      width: 150px !important;
      margin-left: 5.5% !important;
    }
  }
}

.lb_contentTile-bedAssembly {
  .lb_icon-button {
    padding-left: 0 !important;
    margin: 0 0 lineHeights.$lineHeight !important;
  }

  .lb_icon-button:before {
    margin: lineHeights.$lineHeight auto 0;
  }

  .lb_text-strong {
    margin-top: auto;
  }

}

.lb_contentTile-contactUs {
  padding: lineHeights.$lineHeight*2 0 lineHeights.$lineHeight;
  border: solid 1px #cccccc;
  background: #ffffff;
  text-align: left;
  width: 100%;

  .lb_heading {
    text-align: left !important;
    margin-bottom: 0 !important;
    padding-left: 80px !important;
  }

  .lb_icon-button:before {
    margin-left: 22px;
    top: -17px;
  }

  .lb_icon-large {
    padding-top: 2px;
  }

  >*:not(h2) {
    //margin-top: lineHeights.$lineHeight;
    @include layout.responsiveGrid(10, 0, 2, "margin-right");
    //margin-bottom: lineHeights.$lineHeight;
    margin-left: 80px !important;
  }

  .lb_button {
    width: fit-content !important;
    margin-top: lineHeights.$lineHeight !important;
  }

  p {
    padding: 0 !important;
  }

  .lb_openingTimes {
    margin-top: lineHeights.$lineHeight !important;
  }

  .lb_text {
    padding: 0 !important;
  }
}

.lb_contentTile-sleepTrial {
  padding-top: lineHeights.$lineHeight*2;
  padding-bottom: lineHeights.$lineHeight;
  @include layout.responsiveGrid(10, 1, 0, "padding-left");
  @include layout.responsiveGrid(10, 1, 0, "padding-right");
  position: relative;
  overflow: visible;

  .lb_icon-button:before {
    display: flex;
    margin: 0 auto lineHeights.$lineHeight auto;
  }

  .lb_heading {
    line-height: 22.2px;
  }
}

.lb_contentTile-sleepTrial:after {
  @include arrows.arrow;
  @include arrows.arrowDown;
  @include arrows.arrowMedium;
  color: colors.$surfaceColour;
  top: 32px;
  left: calc(50% - 8px);

  @include breakpoints.respond-to("tablet") {
    position: absolute;
    @include arrows.arrowForward;
    top: 48%;
    left: 101%;
  }
}

.lb_tiles_cell:last-child .lb_contentTile-sleepTrial:after {
  display: none;
}

.lb_brandCategoryTile {
  border: solid 1px colors.$surfaceColourLight;
  display: block;

  height: 100%;
  text-decoration: none;

}

.lb_brandCategoryTile:hover .lb_heading {
  color: signifiers.$primaryCallToActionColour;
}

.lb_brandCategoryTile_img {
  display: block;
  height: auto;
  margin-top: lineHeights.$lineHeight*.5;
  @include layout.responsiveGrid(6, 0, 4, "margin-left");
  @include layout.responsiveGrid(6, 0, 4, "margin-right");
  @include layout.responsiveGrid(6, 6, -8, "width");

  @include breakpoints.respond-to("tablet") {
    @include layout.responsiveGrid(4, 0, 4, "margin-left");
    @include layout.responsiveGrid(4, 0, 4, "margin-right");
    @include layout.responsiveGrid(4, 4, -8, "width");
  }

  @include breakpoints.respond-to("desktop") {
    @include layout.responsiveGrid(3, 0, 4, "margin-left");
    @include layout.responsiveGrid(3, 0, 4, "margin-right");
    @include layout.responsiveGrid(3, 3, -8, "width");
  }
}

.lb_brandCategoryTile .lb_heading {
  font-size: 1rem;
  height: lineHeights.$lineHeight * 4;
  margin-top: lineHeights.$lineHeight;
  @include layout.responsiveGrid(6, 0, 1, "margin-left");
  @include layout.responsiveGrid(6, 0, 1, "margin-right");

  @include breakpoints.respond-to("tablet") {
    @include layout.responsiveGrid(4, 0, 1, "margin-left");
    @include layout.responsiveGrid(4, 0, 1, "margin-right");
  }

  @include breakpoints.respond-to("desktop") {
    @include layout.responsiveGrid(3, 0, 1, "margin-left");
    @include layout.responsiveGrid(3, 0, 1, "margin-right");
  }
}

.lb_brandCategoryTile-clearance {
  .lb_heading {
    color: signifiers.$offerColour;
  }

  .lb_heading:hover {
    color: #000000;
  }
}