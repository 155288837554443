@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/layout/_layout.scss';

@mixin tableHeader {
  @include typography.text;
  font-size: .9rem;
  background-color: colors.$primaryColour;
  padding: 6px;
  @include layout.responsiveGrid(12, 0, 4, "padding-left");
  @include layout.responsiveGrid(12, 0, 4, "padding-right");
  color: #ffffff;
}

@mixin table {
  display: grid;
  grid-template-columns: 50% 50%;
}

@mixin tablex1 {
  grid-template-columns: 100%;
}

@mixin tablex2 {
  grid-template-columns: 50% 50%;
}

@mixin tablex3 {
  grid-template-columns: 33.3% 33.3% 33.3%;
}

.lb_table {
  @include table;

  &.lb_table-x1 {
    @include tablex1;

    .lb_table_item {
        border-left: solid 1px colors.$surfaceColourLight;
        border-right: solid 1px colors.$surfaceColourLight;
        border-bottom: solid 1px colors.$surfaceColourLight;
    }
  }

  &.lb_table-x2 {
    @include tablex2;

    .lb_table_item {

      &:nth-child(4n+5),
      &:nth-child(4n+6) {
        background-color: colors.$surfaceColourTintVeryDark;
      }

      &:nth-child(2n+1) {
        border-left: solid 1px colors.$surfaceColourLight;
        border-bottom: solid 1px colors.$surfaceColourLight;
      }

      &:nth-child(2n+2) {
        border-left: solid 1px colors.$surfaceColourLight;
        border-right: solid 1px colors.$surfaceColourLight;
        border-bottom: solid 1px colors.$surfaceColourLight;
      }
    }
  }

  &.lb_table-x3 {
    @include tablex3;
    
    .lb_table_item {

      &:nth-child(6n+7),
      &:nth-child(6n+8),
      &:nth-child(6n+9) {
        background-color: colors.$surfaceColourTintVeryDark;
      }

      &:nth-child(3n+1),
      &:nth-child(3n+2) {
        border-left: solid 1px colors.$surfaceColourLight;
        border-bottom: solid 1px colors.$surfaceColourLight;
      }

      &:nth-child(3n+3) {
        border-left: solid 1px colors.$surfaceColourLight;
        border-right: solid 1px colors.$surfaceColourLight;
        border-bottom: solid 1px colors.$surfaceColourLight;
      }
    }
  }

  .lb_table_item {
    @include typography.text;
    padding: 6px;
    min-height: 47px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include layout.responsiveGrid(12, 0, 4, "padding-left");
    @include layout.responsiveGrid(12, 0, 4, "padding-right");

    &.lb_table_item-center {
      justify-content: center;
      text-align: center!important;
    }

    &.lb_table_item-right {
      text-align: right;
    }

    &.lb_table_item-header {
      @include tableHeader;
      font-weight: 700;
      border-right: solid 1px colors.$primaryColour!important;
      border-left: solid 1px colors.$primaryColour!important;
      border-bottom: solid 1px colors.$primaryColour!important;
    }

    &.lb_table_item-blank {
      border-left: 0!important;
      border-right: 0!important;
    }
  }

  &.lb_table:not(.lb_table-stripes) {
    .lb_table_item:not(.lb_table_item-header) {
      background-color: #ffffff;
    }
  }

}
.lb_table-sizeGuide {
  .lb_table_item-header {
    font-size: .7rem!important;
    font-weight: 400!important;
    .lb_text-strong {
      font-size: .9rem!important;
    }
  }
}

