@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_layout.scss';

.lb_openingTimes {
  margin: 0 0 lineHeights.$lineHeight 0;
  overflow: hidden;
}
  
.lb_openingTimes_heading {
  @include typography.textLarge;
  font-weight: fontWeights.$fontWeightRegular;
}

.lb_openingTimes_details {
  max-width: 300px;
}

.lb_openingTimes_details_days {
  @include typography.text;
  color: colors.$primaryColour;
  font-weight: fontWeights.$fontWeightBold;
  @include layout.responsiveGrid(12, 6, 0, "width");
  float: left;
  clear: both;
}

.lb_openingTimes_details_hours {
  @include typography.text;
  color: colors.$primaryColour;
  font-weight: fontWeights.$fontWeightBold;
  @include layout.responsiveGrid(12, 6, 0, "width");
  text-align: left;
  float: right;
}
  