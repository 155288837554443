@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/styles/colors/_positiveNegative.scss';

@mixin selectableTile-selected {
  border: solid 2px positiveNegative.$positiveColour;
  background: positiveNegative.$positiveColourTint;
}

@mixin selectableTile-disabled {
  cursor: default;
}

@mixin selectableTile-square {
  padding-top: 100%;
    
  .lb_selectableTileWrapper {
    position: absolute;
  }
}

// @mixin selectableTile-topAlign {
//   .lb_selectableTile_text {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//   }
// }

// @mixin selectableTile-bottomAlign {
//   .lb_selectableTile_text {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-end;
//   }
// }

// @mixin selectableTile-middleAlign {
//   .lb_selectableTile_text {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//   }
// }

@mixin selectableTile-leftAlign {
  .lb_selectableTile_text {
    text-align: left!important;
  }
}

@mixin selectableTile-centreAlign {
  .lb_selectableTile_text {
    text-align: center!important;
  }
}

@mixin selectableTile {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  border: solid 2px colors.$surfaceColourLight;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  padding: 1px;
  &:hover:not(.lb-disabled) {
    border: solid 2px positiveNegative.$positiveColour;
  }
  &.lb-selected:not(.lb-disabled) {
    @include selectableTile-selected;
  }
}

@mixin selectableTileWrapper {
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: lineHeights.$lineHeight 0;
  height: 100%;
}

@mixin selectableTileImg {
  display: block;
  height: auto;
  margin: 0 25%;
  width: 50%;
}

@mixin selectableTileText {
  @include typography.text;
  text-align: center;
  line-height: 1.25;
  @include layout.responsiveGrid(12, 0, 2, "margin-left");
  @include layout.responsiveGrid(12, 0, 2, "margin-right");
  @include layout.responsiveGrid(12, 12, -4, "width");
}