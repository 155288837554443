@use '/src/scss/global/header/mM/abstracts/_mMStyles.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/components/tiles/abstracts/_tiles.scss';
@use '/src/scss/layout/_breakpoints.scss';

.lb_mM-mattresses,
.lb_mM-headboards {
    h3 {
        @include mMStyles.mMSubHeading;
    }

    >div:nth-child(2)>div>div:nth-child(2) {
        @include tiles.tiles;

        >div {
            @include tiles.tilex4;
        }
    }

    ul {
        margin-bottom: 16px;
        margin-left: 8px;
        @include breakpoints.respond-to("desktop") {
            margin-left: 0;
        }

        li {
            margin-bottom: 8px;

            >a {
                @include mMStyles.mMText;
                line-height: 1.2;

                >div {
                    @include mMStyles.mMSmallText;
                }
            }
        }

        .lb_mM-all a {
            @include mMStyles.mMLink;
            padding-left: 50px;

            @include breakpoints.respond-to("desktop") {
                padding-left: 0;
            }
        }
    }
}