.lb_colourPickerTile {
  position: relative;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-position: center;
  background-size: 60px 60px;
}

.lb_gallery_mainArea .lb_colourPickerTile {
  background-size: 400px 209px;
}

.lb_colourPickerTile:after {
  content: "";
  width: 48px;
  height: 48px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cpath id='Path_579' data-name='Path 579' d='M-39.115,2.182l4.266-4.266,4.2,4.266,4.513-4.59,4.5,4.59,4.318-4.389,4.294,4.389,4.247-4.266,4.239,4.266L2.182-4.555-2.151-8.747l4.333-4.293-4.534-4.427,4.534-4.553L-2.162-26.3l4.344-4.314L-2.1-34.865,2.182-39.1l-6.647-6.715H2.182L2.176,2.176H-45.818V-45.818H-4.466l-4.29,4.3-4.3-4.3-4.461,4.535-4.445-4.535-4.275,4.3-4.334-4.3-4.3,4.25-4.219-4.25-6.734,6.682,4.281,4.271-4.281,4.219,4.563,4.563-4.562,4.453,4.322,4.3-4.322,4.329,4.266,4.25-4.266,4.2Z' transform='translate(45.818 45.818)' fill='%23fff'/%3E%3C/svg%3E");
  color: #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
}