@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';

.lb_grid {
  display: grid;
  @include layout.responsiveGrid(12, 0, 2, "grid-column-gap");
  grid-row-gap: 4.44%;
}

.lb_grid-mobilex2 {
  grid-template-columns: 1fr 1fr;
}

.lb_optionsGrid {
    display: grid;
    grid-template-columns: 1fr 40px;
    gap: 14px;
    row-gap: 14px;
    align-items: start;
    text-align: left;
    margin-bottom: lineHeights.$lineHeight*1.5;
    @include typography.text;
    line-height: 1.15;

    .lb_optionsGrid_option {

        .lb_icon {
            margin-left: 6px;
            position: relative;
            top: 1px;
        }
    }

    .lb_optionsGrid_price {
        text-align: right;
        font-weight: fontWeights.$fontWeightBold;
    }
}