@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_headings.scss';

@include breakpoints.respond-to("desktop") {
  .lb_megaMenu_list_item:hover {
    .lb_megaMenu-brands {
      display: flex;
      z-index: 10;
    }
  }
}

.lb_megaMenu-brands {
  display: none;
  position: absolute!important;
  left: 0;
  background: #ffffff;
  flex-direction: column;

  .lb_megaMenu_list {
    column-count: 4;
    column-gap: 2rem;
    margin: 2rem;
  }

  .lb_megaMenu-link {
    margin: 0 2rem layout.$lineHeight*2;
    font-weight: fontWeights.$fontWeightSemiBold;
  }

  .lb_megaMenu_list_term {
    @include headings.heading;
    float: left;
    line-height: layout.$lineHeight*2;
    height: layout.$lineHeight*2;
    display: inline;
    top: 0;
  }

  .lb_megaMenu_list_description {
    @include typography.text;
    line-height: 32px;
    padding-left: 20px;
    position: relative;
    left: 8px;
    top: 0;
  }

  .lb_megaMenu_list_description a {
    @include typography.text;
    text-decoration: none;
    font-weight: fontWeights.$fontWeightRegular;
  }

  .lb_megaMenu_list_description a:hover {
    text-decoration: underline;
  }
}
