@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';

@mixin productDetailsMinispec {
  padding-left: 16px;
  list-style: disc;
  @include typography.text;
  line-height: 1.2;
  font-size: .7rem;
 }

 @mixin productDetailsMinispecItem {
  margin-bottom: lineHeights.$lineHeight * .25;
 }