@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_typography.scss';

.lb_serviceTile {
  position: relative;
  text-align: left;
  @include typography.menuItem;
  padding: 0;
  @include breakpoints.respond-to("tablet") {
    text-align: center;
    margin: 0 auto;
  }
}

.lb_serviceTile .lb_icon {
  padding-top: 0;
  padding-left: 58px;
  margin: 16px 0;
  @include breakpoints.respond-to("tablet") {
    padding: 0;
    margin-bottom: 0;
  }
}

.lb_serviceTile .lb_icon:before {
  text-align: center;
  margin-top: .55rem;
  top: -8px;
  position: absolute;
  left: 0;
  @include breakpoints.respond-to("tablet") {
    position: relative;
    float: initial;
    top: 0;
    display: flex;
    margin: 0 auto lineHeights.$lineHeight auto;
  }
}

.lb_serviceTile .lb_icon-tick:before {
  top: 5px;
  left: 12px;
  @include breakpoints.respond-to("tablet") {
    top: 0;
    left: 0;
  }
}