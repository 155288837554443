@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/components/tables.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_signifiers.scss';

.lb_dynamicContent {
  @include typography.text;
  
  h2 {
    @include headings.heading;
    margin-bottom: lineHeights.$lineHeightContent;
  }

  p {
    margin-bottom: lineHeights.$lineHeight;
  }

  ul {
    margin-bottom: lineHeights.$lineHeight;
    li {
      @include typography.text;
      list-style-type: disc;
      margin-left: 16px;
      margin-bottom: 16px;
    }
  }

  ol {
    margin-bottom: lineHeights.$lineHeight;
    li {
      @include typography.text;
      margin-left: 16px;
      margin-bottom: 16px;
    }
  }

  a {
    text-decoration: none;
    color: signifiers.$primaryCallToActionColour;
    font-weight: fontWeights.$fontWeightBold;
  }
  
  a:hover {
    text-decoration: underline;
    color: signifiers.$primaryCallToActionColourHover;
  }

  hr {
    margin-bottom: lineHeights.$lineHeight;
    height: 1px;
  }

  table, tr, td {
    border: none;
   }

  table {
    @include typography.text;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: lineHeights.$lineHeight;

    th {
      @include tables.tableHeader;
    }

    td {
      padding: 8px;
      border: 1px solid #cccccc;
    }

    tr:first-child td {
      padding: 8px;
      border-top: 0;
    }
    
  }
}

.lb_dynamicContent-secondaryCallToActionLinks {
  a {
    text-decoration: none;
    color: signifiers.$secondaryCallToActionColour;
    font-weight: fontWeights.$fontWeightBold;
  }
  a:hover {
    text-decoration: underline;
    color: signifiers.$secondaryCallToActionColourHover;
  }
}