@mixin bedsOnDisplay50 {
  content: "a";
  font-family: landOfBedsIcons;
}

@mixin bedsOnDisplay60 {
  content: "^";
  font-family: landOfBedsIcons;
}

@mixin basket {
  content: "c";
  font-family: landOfBedsIcons;
}

@mixin brands {
  content: "d";
  font-family: landOfBedsIcons;
}

@mixin burger {
  content: "e";
  font-family: landOfBedsIcons;
}

@mixin clearance {
  content: "f";
  font-family: landOfBedsIcons;
}

@mixin delivery {
  content: "h";
  font-family: landOfBedsIcons;
}

@mixin deliveryReturns {
  content: "i";
  font-family: landOfBedsIcons;
}

@mixin email {
  content: "k";
  font-family: landOfBedsIcons;
}

@mixin expertAdvice {
  content: "n";
  font-family: landOfBedsIcons;
}

@mixin facebook {
  content: "o";
  font-family: landOfBedsIcons;
}

@mixin finance0Percent {
  content: "p";
  font-family: landOfBedsIcons;
}

@mixin finance {
  content: "p";
  font-family: landOfBedsIcons;
}

@mixin financeCalculator {
  content: "q";
  font-family: landOfBedsIcons;
}

@mixin freeDelivery {
  content: "h";
  font-family: landOfBedsIcons;
}

@mixin form {
  content: "r";
  font-family: landOfBedsIcons;
}

@mixin help {
  content: "t";
  font-family: landOfBedsIcons;
}

@mixin info {
  content: "u";
  font-family: landOfBedsIcons;
}

@mixin instagram {
  content: "v";
  font-family: landOfBedsIcons;
}

@mixin liveChat {
  content: "y";
  font-family: landOfBedsIcons;
}

@mixin location {
  content: "z";
  font-family: landOfBedsIcons;
}

@mixin overview {
  content: "B";
  font-family: landOfBedsIcons;
}

@mixin padlock {
  content: "C";
  font-family: landOfBedsIcons;
}

@mixin parking {
  content: "D";
  font-family: landOfBedsIcons;
}

@mixin phone {
  content: "E";
  font-family: landOfBedsIcons;
}

@mixin pillow {
  content: "9";
  font-family: landOfBedsIcons;
}

@mixin priceMatch {
  content: "A";
  font-family: landOfBedsIcons;
}

@mixin promotion {
  content: "H";
  font-family: landOfBedsIcons;
}

@mixin recycle {
  content: "I";
  font-family: landOfBedsIcons;
}

@mixin roomOfChoice {
  content: "J";
  font-family: landOfBedsIcons;
}

@mixin rosette {
  content: "K";
  font-family: landOfBedsIcons;
}

@mixin search {
  content: "L";
  font-family: landOfBedsIcons;
}

@mixin services {
  content: "M";
  font-family: landOfBedsIcons;
}

@mixin single {
  content: "N";
  font-family: landOfBedsIcons;
}

@mixin skilledDelivery {
  content: "O";
  font-family: landOfBedsIcons;
}

@mixin sleepMask {
  content: "P";
  font-family: landOfBedsIcons;
}

@mixin sleepTrial {
  content: "Q";
  font-family: landOfBedsIcons;
}

@mixin specification {
  content: "R";
  font-family: landOfBedsIcons;
}

@mixin star {
  content: "S";
  font-family: landOfBedsIcons;
}

@mixin swatches {
  content: "V";
  font-family: landOfBedsIcons;
}

@mixin trophy {
  content: "W";
  font-family: landOfBedsIcons;
}

@mixin twitter {
  content: "X";
  font-family: landOfBedsIcons;
}

@mixin yearsExperience {
  content: "Y";
  font-family: landOfBedsIcons;
}

@mixin writeToUs {
  content: "Z";
  font-family: landOfBedsIcons;
}

@mixin youtube {
  content: "1";
  font-family: landOfBedsIcons;
}

@mixin tick {
  content: "2";
  font-family: landOfBedsIcons;
}

@mixin cross {
  content: "3";
  font-family: landOfBedsIcons;
}

@mixin keyFeatures {
  content: "4";
  font-family: landOfBedsIcons;
}

@mixin tapeMeasure {
  content: "5";
  font-family: landOfBedsIcons;
}

@mixin expand {
  content: "6";
  font-family: landOfBedsIcons;
}

@mixin specialPromotion {
  content: "7";
  font-family: landOfBedsIcons;
}

@mixin pillow {
  content: "9";
  font-family: landOfBedsIcons;
}

@mixin bin {
  content: "0";
  font-family: landOfBedsIcons;
}

@mixin filter {
  content: "-";
  font-family: landOfBedsIcons;
}

@mixin warrantyGuarantee {
  content: "$";
  font-family: landOfBedsIcons;
}

@mixin faultyGoods {
  content: "+";
  font-family: landOfBedsIcons;
}

@mixin offersPromotions {
  content: 'B';
  font-family: landOfBedsIcons;
}

@mixin trade {
  content: 'F';
  font-family: landOfBedsIcons;
}

@mixin complaints {
  content: '%';
  font-family: landOfBedsIcons;
}

@mixin whatsApp {
  content: '&';
  font-family: landOfBedsIcons;
}