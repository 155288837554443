@use '/src/scss/styles/colors/_colors.scss';

.lb_breadcrumb {
    font-size: .8rem;
    line-height: 1.2;
    color: colors.$textColour;
    text-transform: uppercase;
    margin-right: 0 !important;
    margin-left: 0 !important;
    list-style-type: none;

    li {
        display: inline-block;

        a {
            text-decoration: none;
            color: colors.$textColour;
            white-space: normal;

            &:hover {
                text-decoration: underline;
            }
        }

        &:not(:last-child):after {
            margin: 0 6px 0 4px;
            position: relative;
            top: -2px;
            display: inline-block;
            content: "";
            height: 6px;
            width: 6px;
            border-style: solid;
            border-width: 1px 1px 0 0;
            border-color: colors.$textColour colors.$textColour transparent transparent;
            transform: rotate(45deg);
        }
    }
}
