@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_layout.scss';

.lb_article_wrapper {
  @include breakpoints.respond-to("tablet") {
    flex: 1 0 50%;
  }
}

.lb_article_wrapper *:last-child {
  @include breakpoints.respond-to("tablet") {
    margin-bottom: 0;
  }
}

.lb_article.lb_article-zigzag {
  //padding: 0;
  margin-left: 0;
  margin-right: 0;
  .lb_article_wrapper {
    padding: 0;
    @include layout.responsiveGrid(12, 0, 1, "padding-left");
    @include layout.responsiveGrid(12, 0, 1, "padding-right");
  }
  @include breakpoints.respond-to("tablet") {
    display: flex;
  }
}

.lb_article-zigzag:not(.lb_article-zigzagReverse):nth-of-type(odd) {
    @include breakpoints.respond-to("tablet") {
    flex-direction: row-reverse;
  }
}
.lb_article-zigzag .lb_heading {
  text-align: left!important;
  margin: 0 0 lineHeights.$lineHeight * .5!important;
}

.lb_article-zigzag .lb_heading.lb_icon-button {
  text-align: left!important;
  margin-bottom: lineHeights.$lineHeight!important;
}