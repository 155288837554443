﻿@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';

.lb_starRating {
  @include typography.text;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  overflow: hidden;
  margin: 0;

  &.lb_starRating-large {
    .lb_starRating_stars {
      height: 36px;
      width: 184px;
      margin: 0;
      &::before,
      &::after {
        width: 400px;
        font-size: 2.2rem;
        height: 36px;
        padding-top: 2px;
      }
    }
    .lb_starRating_reviews {
      padding-top: 22px;
    }
  }

  &.lb_starRating-medium {
    font-size: .9rem;
    height: 28px;

    .lb_starRating_stars {
      height: 36px;
      width: 105px;
      margin: 5px 0 0 0;

      &::before,
      &::after {
        font-size: 1.2rem;
        height: 36px;
        padding-top: 2px;
      }
    }

    .lb_starRating_reviews {
      padding-top: 12px;

      .lb_starRating_reviews_reviewScore {
        font-size: 1.1rem;
      }
    }
  }

  .lb_starRating_stars {
    position: relative;
    margin: 0;
    width: 72px;
    height: 16px;
    width: 80px;

    @for $i from 0 through 10 {
      &.lb_starRating_stars-#{$i*10}Percent:after {
        width: #{$i*10}#{"%"};
      }
    }
    
    &:before {
      position: absolute;
      top: -1;
      left: 0;
      content: "SSSSS";
      line-height: 1;
      display: block;
      overflow: hidden;
      font-family: landOfBedsIcons;
      font-size: .9rem;
      color: rgb(197, 197, 197);
      letter-spacing: 2px;
      padding-top: 0px;
    }
  
    &:after {
      position: absolute;
      top: -1;
      left: 0;
      content: "SSSSS";
      display: block;
      line-height: 1;
      overflow: hidden;
      font-family: landOfBedsIcons;
      font-size: .9rem;
      color: #EF960A;
      letter-spacing: 2px;
      padding-top: 0px;
    }

  }
  
  .lb_starRating_reviews {
    white-space: nowrap;
    line-height: .9;
    margin: 0 0 0 5px;
    padding-top: 2px;

    .lb_starRating_reviews_reviewScore {
      font-weight: fontWeights.$fontWeightSemiBold;
      display: inline;
      margin-right: 3px;
    }
  }
}


