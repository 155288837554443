@use '/src/scss/icons/abstracts/_arrows.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/components/carousel/_carouselControls.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/base/_helpers.scss';

.lb_pagingSelectableCarousel {
  display: flex;
  flex-direction: row;

  .lb_selectableCarousel_cells {
    width: 100%;
    height: 75px;
  }

  .lb_selectableCarousel_cells_cell {
      @include breakpoints.respond-to("desktop") {
        flex: 0 0 100px!important;
        height: 75px!important;
        border-radius: 0!important;
      }
  }

  &[data-selectable-carousel-component-orientation="horizontal"] {
    justify-content: center;
    flex-direction: row;
    
    .lb_selectableCarousel_cells {
      @include breakpoints.respond-to("desktop") {
        justify-content: flex-start;
      }

      .lb_selectableCarousel_cells_cell {
        &.lb_selectableCarousel_cells_cell-filler,
        img {
          @include breakpoints.respond-to("desktop") {
            display: block;
          }
        }

        .lb_carouselTile-youtube:after {
          @include breakpoints.respond-to("desktop") {
            width: 100%;
            height: 100%;
            position: absolute;
            border: 0;
            top: 0;
            left: 0;
          }
        }

        @include breakpoints.respond-to("desktop") {
          flex: 0 0 100px;
          height: 100%;
          border-radius: 0;
        }
      }
    }

    .lb_selectableCarousel_prev,
    .lb_selectableCarousel_next {
      width: 50px;
      flex: 0 0 50px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }

    .lb_selectableCarousel_prev-small,
    .lb_selectableCarousel_next-small {
      width: 20px;
      flex: 0 0 20px;
      @include breakpoints.respond-to("tablet") {
        width: 50px;
        flex: 0 0 50px;
      }
    }
  }

  &[data-selectable-carousel-component-orientation="vertical"] {
    flex-direction: column;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;

    .lb_selectableCarousel_cells {
      flex-direction: column;
      justify-content: flex-start;
      .lb_selectableCarousel_cells_cell {
        flex: 0 0 75px;
        width: 98px;
      }
    }

    .lb_selectableCarousel_prev,
    .lb_selectableCarousel_next {
      width: 100px;
      height: 75px;
      flex: 0 0 75px;
    }

    .lb_selectableCarousel_prev:after {
      position: absolute;
      bottom: 16px;
      left: 40%;
    }

    .lb_selectableCarousel_next:after {
      position: absolute;
      top: 16px;
      left: 40%;
    }
  }

  .lb_selectableCarousel_prev {
    @include carouselControls.carouselControlsPagingSelectableCarousel;
    &:after {
      @include arrows.arrow;
      @include arrows.arrowBackward;
      @include arrows.arrowMedium;
      color: colors.$surfaceColour;
      position: absolute;
      left: calc(50% - 4px);
    }
  }
  
  .lb_selectableCarousel_next {
    @include carouselControls.carouselControlsPagingSelectableCarousel;
    &:after {
      @include arrows.arrow;
      @include arrows.arrowForward;
      @include arrows.arrowMedium;
      color: colors.$surfaceColour;
      position: absolute;
      left: calc(50% - 8px);
    }
  }

  .lb-hidden:after {
    display: none!important;
  }

  .lb_selectableCarousel_cells {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    @include helpers.gapfix(5px);
    scroll-behavior: smooth;
    /* Auto require for iPhone 12 */
    //overflow-x: auto;
    //@include breakpoints.respond-to("tablet") {
      overflow-x: hidden;
    //}

    /*-ms-overflow-style: none;
    scrollbar-width: none;
    &:-webkit-scrollbar {
      display: none;
    }*/

    .lb_selectableCarousel_cells_cell {
      flex: 0 0 100px;
      height: 100%;
      border-radius: 0;
      //border: solid 2px $surfaceColourLight;
      //background-color: $surfaceColourLight;
      position: relative;

      &.lb-selected {
        //border: solid 2px $positiveColour!important;
        //background-color: $positiveColour;
      }

      &.lb_selectableCarousel_cells_cell-filler {
        cursor: auto;
        border: solid 1px transparent!important;
        padding: 1px;
        background-color: transparent;
      }
    }
  }
}

.lb_pagingSelectableCarousel-centerAlign {
  .lb_selectableCarousel_cells {
    flex: auto!important;
    justify-content: center!important;
    overflow: visible!important;
  }
  
  .lb_selectableCarousel_cells_cell-filler {
    display: none!important;
  }
}
