@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/components/tiles/abstracts/_selectableTile.scss';
@use '/src/scss/styles/colors/_signifiers.scss';

.lb_faqTile {
  width: 100%;
  height: 100%;
  @include selectableTile.selectableTile;
  border-width: 1px!important;
  padding: 0!important;

  &:hover {
    border-width: 1px!important;
    padding: 0!important;
  }

  .lb_faqTileInner {
    padding: 40px 10px 30px 10px;
    @include breakpoints.respond-to("tablet") {
      text-align: center;
    }
  }

  .lb_heading {
    margin-bottom: 0!important;
    @include breakpoints.respond-to("tablet") {
      margin-bottom: 12px!important;
    }
  }

  .lb_faqTile:hover .lb_heading {
    color: signifiers.$primaryCallToActionColour;
  }

  p {
    margin-left: 64px;
    @include breakpoints.respond-to("tablet") {
      margin-left: 0;
    }
  }

  .lb_icon-finance,
  .lb_icon-klarna {
    &:before {
      background-color: transparent;
      border-radius: 0;
      display: block!important;
    }
  }
}