@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/icons/abstracts/_fontIcons.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/base/_helpers.scss';

.lb_mainServices {
    padding: 16px 0 25px;

    .lb_mainServices_heading {
        text-align: center;
        padding: 25px 0;
        font-weight: fontWeights.$fontWeightSemiBold;
        color: colors.$primaryColour;
    }

    .lb_mainServices_list {
        display: grid;
        margin: auto;
        grid-template-columns: repeat(3, 80px);
        grid-template-rows: 1fr 1fr;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        row-gap: 20px;

        @include breakpoints.respond-to("tablet") {
            @include layout.responsiveGrid(12, 12, -2, "width");
            grid-template-columns: repeat(6, 80px);
            grid-template-rows: 1fr;
        }

        @include breakpoints.respond-to("desktop") {
            @include layout.responsiveGrid(12, 10, -2, "width");
        }
    }

    .lb_mainServices_list a {
        font-size: 0.8rem;
        font-weight: fontWeights.$fontWeightSemiBold;
        text-align: center;
        color: colors.$primaryColour;
        line-height: 1.1;
    }

    .lb_mainServices_list a::before {
        margin: 0 auto 16px auto;
        width: 80px;
        height: 80px;
        display: flex;
        flex-direction: column;
        color: #ffffff;
        border-radius: 50%;
        background-color: colors.$primaryColour;
        font-size: 3.2rem;
        font-weight: fontWeights.$fontWeightRegular;
        align-content: center;
        justify-content: center;
    }

    .lb_mainServices_list a:hover::before {
        background-color: colors.$secondaryColour;
    }

    .lb_mainServices_list a:hover {
        color: colors.$secondaryColour;
    }

    .lb_mainServices_list-delivery:before {
        @include fontIcons.delivery;
    }

    .lb_mainServices_list-recycling:before {
        @include fontIcons.recycle;
    }

    .lb_mainServices_list-priceMatch:before {
        @include fontIcons.priceMatch;
    }

    .lb_mainServices_list-expertAdvice:before {
        @include fontIcons.expertAdvice;
    }

    .lb_mainServices_list-brands:before {
        @include fontIcons.brands;
    }

    .lb_mainServices_list-finance:before {
        @include fontIcons.finance;
    }
}

.lb_mainFooterReviewRibbon {
    background-color: colors.$surfaceColourVeryLight;
    margin: 25px 0;
    padding: 40px 0;

    .lb_mainFooterReview {
        display: grid;
        font-size: 0.9rem;
        margin: auto;

        @include breakpoints.respond-to("tablet") {
            @include layout.responsiveGrid(12, 11, -2, "width");
            padding: 25px 0;
            grid-template-columns: 300px 1fr;
            flex-direction: row;
            align-items: center;
        }

        @include breakpoints.respond-to("desktop") {
            width: 70%;
        }

    }

    .lb_mainFooterReviewTrustPilot {
        width: 240px;
        padding: 0 0 30px;

        @include breakpoints.respond-to("desktop") {
            width: 250px;
            padding: 5px 0 0;
        }
    }

    .lb_mainFooterReviewLatest {
        @include typography.text;
        font-weight: fontWeights.$fontWeightRegular;
        font-size: 0.8rem;
        padding: 15px 0;
    }
}

.lb_mainFooterPayments {
    padding: 0px 0 layout.$lineHeight * 2;
    text-align: center;

    h2 {
        margin-bottom: layout.$lineHeight * 1.5
    }

    @include breakpoints.respond-to("desktop") {
        padding: 25px 0 50px;
    }

    .lb_mainFooterPayments_heading {
        color: colors.$primaryColour;
        font-weight: fontWeights.$fontWeightSemiBold;
        text-align: center;
    }

    .lb_paymentIcons {
        @include helpers.gapfix(12px);
        @include breakpoints.respond-to("tablet") {
            gap: 30px;
            @include helpers.gapfix(30px);
        }
    }

    .lb_paymentIcons img {
        @include breakpoints.respond-to("tablet") {
            height: 40px;
        }

        @include breakpoints.respond-to("desktop") {
            height: 60px;
        }
    }
}

.lb_mainFooterMenuRibbon {
    display: block;
    background-color: #002E5E;
    padding: 25px 0;

    .lb_mainFooterMenu {
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;

        @include breakpoints.respond-to("tablet") {
            grid-template-columns: 1fr 1fr 1fr;
            justify-items: center;
        }

        @include breakpoints.respond-to("desktop") {
            grid-template-columns: auto;
            justify-content: space-between;
            margin: 0 auto;
            position: relative;
        }
    }

    .lb_mainFooterMenu_heading {
        @include typography.menuItem;
        line-height: layout.$lineHeight * 1.5;
        margin-bottom: 16px;
        color: #ffffff;
    }

    .lb_mainFooterMenu_list {
        list-style-type: none;
        margin-bottom: layout.$lineHeight * 1.5;
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;
        color: #fff;
    }

    .lb_mainFooterMenu_list a {
        display: flex;
        justify-items: center;
        color: #fff;
        line-height: 24px;
        margin-bottom: 8px;
    }

    .lb_mainFooterMenu_list a::before {
        font-size: 1.2rem;
        margin-right: 8px;
        font-weight: fontWeights.$fontWeightRegular;
    }

    .lb_mainFooterMenu_list .lb_icon-pillow::before {
        position: relative;
        top: 2px;
    }


    .lb_mainFooterMenuSocial {
        height: 66px
    }

    .lb_mainFooterMenu-getInTouch,
    .lb_mainFooterMenu-aboutUs,
    .lb_mainFooterMenu-shoppingWithUs,
    .lb_mainFooterMenu-awards {
        border-bottom: 1px solid #ffffff;
        padding: layout.$lineHeight 0;

        @include breakpoints.respond-to("tablet") {
            border: none;
        }
    }

    .lb_mainFooterMenu-getInTouch {

        .lb_mainFooterMenu_list {
            @include typography.menuItem;

            .lb_icon-pillow {
                display: block;

                .lb_text-small {
                    display: block;
                    margin-left: 32px;
                    font-weight: 400;
                }
            }
        }
    }

    .lb_mainFooterMenu-awards {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 25px 0;
        border-bottom: none;

        @include breakpoints.respond-to("tablet") {
            grid-column: 1/4;
            justify-self: stretch;
            justify-content: center;
            border-top: 1px dotted #ffffff;
        }

        @include breakpoints.respond-to("desktop") {
            grid-column: 4/4;
            padding: 16px 0;
            flex-direction: column;
            justify-self: center;
            justify-content: flex-start;
            border: none;
        }

        img {
            display: block;
            max-width: 128px;
            width: 33.33%;
            height: 100%;

            @include breakpoints.respond-to("desktop") {
                width: 100%;
                height: initial;
                margin-bottom: 12px;
            }
        }
    }



    .lb_mainFooterLegal {
        @include typography.text;
        color: #ffffff;
        padding: 16px 0 32px;
        border-top: 1px dotted #fff;

        @include breakpoints.respond-to("tablet") {
            text-align: center;
        }

        .lb_mainFooterLegal_copyright,
        .lb_mainFooterLegal_companyNo {
            padding-top: 16px;

            a {
                color: #ffffff;
            }
        }

        .lb_mainFooterMenu_list {
            margin: layout.$lineHeight auto 0;
            flex-direction: row;

            @include breakpoints.respond-to("tablet") {
                justify-content: center;
            }
        }

        .lb_mainFooterMenu_list a {
            line-height: layout.$lineHeight 32px;
            padding: 0 10px;
            border-left: 1px solid #fff;
        }

        .lb_mainFooterMenu_list a:first-child {
            border-left: none;
            padding-left: 0;
        }

        .lb_mainFooterMenu_list a:last-child {
            padding-right: 0;
        }
    }
}