@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/abstracts/_tension.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_layout.scss';

.lb_textIntro {
  @include typography.textIntro;
}

.lb_text-small {
  @include typography.miniText
}

.lb_text {
  @include typography.text;
}

.lb_text-large {
  @include typography.textLarge;
}

.lb_text-xLarge {
  @include typography.textXLarge
}

.lb_text-xXLarge {
  @include typography.textXXLarge
}

.lb_text-xXXXLarge {
  @include typography.textXXXXLarge
}

.lb_text-offer {
  color: signifiers.$offerColour!important;
  font-weight: fontWeights.$fontWeightBold;
}

.lb_text-strong {
  font-weight: fontWeights.$fontWeightBold;
}

.lb_text-italic {
  @include typography.italic;
}

.lb_text-unavailable {
  color: signifiers.$unavailableColour;
}

.lb_text-trustpilot {
  color: signifiers.$trustpilot;
}

.lb_text-biolerplate {
  background: colors.$surfaceColourTintDark;
  margin:  layout.$lineHeight 0;
  padding: layout.$lineHeight 16px;
}

.lb_line {
  height: 1px;
  clear: both;
}

.lb_text-softColour {color: tension.$softColour}
.lb_text-softMediumColour {color: tension.$softMediumColour}
.lb_text-mediumColour {color: tension.$mediumColour}
.lb_text-mediumFirmColour {color: tension.$mediumFirmColour}
.lb_text-firmColour {color: tension.$firmColour}
.lb_text-extraFirmColour {color: tension.$extraFirmColour}

.lb_text-trustpilotColour {color: tension.$softColour}