@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/components/tiles/abstracts/_selectableTile.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/layout/_clamping.scss';

.lb_selectedProduct {
  @include selectableTile.selectableTile;
  padding: 8px;
  text-align: left;

  .lb_productDetailsTitle {
    @include typography.text;
    font-weight: fontWeights.$fontWeightBold;
    line-height: 1.25;
    height: 40px;
    margin-bottom: 0!important;
    @include clamping.clamp2;
  }

  .lb_productDetailsPrice {
    white-space: nowrap;
    @include typography.text;
    font-weight: fontWeights.$fontWeightRegular;
  }
}