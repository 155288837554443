@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/layout/abstracts/_lbGrid.scss';
@use '/src/scss/global/header/abstracts/_logo.scss';
@use '/src/scss/global/header/abstracts/_searchBox.scss';
@use '/src/scss/global/header/abstracts/_clearButton.scss';

.lb_mainMenuHeader {
    position: relative;
    background: #ffffff;

    .lb_popOut_headerWrapper {
        min-height: 54px;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.5);

        @include breakpoints.respond-to("desktop") {
            min-height: initial;
            position: relative;
            z-index: 10;
            width: initial;
            background: #ffffff;
            box-shadow: none;
        }
    }

    .lb_layoutSection-innerPage {
        .lb_layoutSection-innerPage {
            text-align: center;
            padding-top: 9px;

            @include breakpoints.respond-to("desktop") {
                padding: 0;

                .lb_layoutSection-margin {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                    width: 100%;
                }
            }
        }
    }
}

.lb_mainHeader {
    display: grid;
    grid-template-columns: auto auto 1fr auto auto;
    grid-template-rows: 60px;
    height: 60px;
    column-gap: 6px;

    @include breakpoints.respond-to("desktop") {
        grid-template-columns: 250px 1fr auto auto auto;
        grid-template-rows: 90px;
        height: 90px;
    }

    .lb_mainHeader-item {
        display: grid;
        border: none;
        align-content: center;
        justify-content: center;
        font-size: 1.4rem;
        color: #002E5E;
        box-sizing: border-box;
        cursor: pointer;
        width: 38px;

        @include breakpoints.respond-to("desktop") {
            margin-left: 16px;
            width: 42px;
        }
    }

    .lb_mainHeaderLogo {
        @include logo.logoWrapper;
        padding: 0 16px;

        @include breakpoints.respond-to("desktop") {
            margin: 0;
            order: 0;
            padding: 0;
        }
    }

    .lb_mainHeaderLogo img {
        @include logo.logo;
    }

    .lb_mainHeaderBurgerMenu {
        background-color: #ffffff;

        @include breakpoints.respond-to("desktop") {
            display: none;
        }
    }

    .lb_mainHeaderBurgerMenu::before {
        content: "e";
        font-family: landOfBedsIcons;
    }

    .lb_mainHeaderSearch {

        @include breakpoints.respond-to("desktop") {
            order: 1;
            width: 100%;
            margin: 0 auto;
            cursor: initial;
        }
    }

    .lb_mainHeaderSearch_searchButton {
        width: 42px;
        height: 60px;
        line-height: 60px;
        font-family: landOfBedsIcons;
        text-align: center;

        @include breakpoints.respond-to("desktop") {
            visibility: hidden;
            display: none;
        }
    }

    .lb_searchBox {
        @include searchBox.searchBox;
    }

    .lb_searchBox_clear {
        @include clearButton.clearButton;
        position: absolute;
        left: calc(50% + 241px);
        z-index: 10;
        margin-top: 4px;
    }

    .lb_searchBox_clear-hidden {
        display: none;
    }

    .lb_popOut_header {
        padding-bottom: lineHeights.$lineHeight;
    }

    .lb_popOutWrapper {
        height: auto;
        overflow: hidden;
    }

    @include breakpoints.respond-to("desktop") {
        .lb_popOut {
            right: 0 !important;
            width: 100%;

            &:not(.lb-active) {
                position: relative;
                display: block;
                overflow: inherit;

                .lb_popOut_header {
                    padding: 0;
                    margin: 0;
                    box-shadow: none;
                    min-height: auto;
                }

                .lb_popOut_body {
                    display: none;
                }
            }
        }

    }

    .lb_mainHeaderStoreLocator {
        display: none;

        @include breakpoints.respond-to("desktop") {
            display: grid;
            order: 3;
            justify-items: center;
        }
    }

    .lb_mainHeaderStoreLocator::before {
        content: "z";
        font-family: landOfBedsIcons;
        font-size: 1.4rem;
    }

    .lb_mainHeaderStoreLocator::after {
        content: "";


        @include breakpoints.respond-to("desktop") {
            content: "Stores";
            font-size: 0.8rem;
            font-weight: fontWeights.$fontWeightSemiBold;
            padding-top: 5px;
            position: relative;
            top: 2px;
        }
    }

    .lb_mainHeaderContact {

        div {
            display: none;
        }

        @include breakpoints.respond-to("desktop") {
            display: flex;
            align-items: center;
            order: 2;
            width: auto;
            margin-left: 0;

            div {
                display: block;
            }
        }
    }

    .lb_mainHeaderContact::before {
        content: "E";
        font-family: landOfBedsIcons;
        @include breakpoints.respond-to("desktop") {
            margin-right: 4px;
        }
    }

    @include breakpoints.respond-to("desktop") {

        .lb_mainHeaderContact_title {
            font-size: .8rem;
            white-space: nowrap;
        }

        .lb_mainHeaderContact_number {
            font-size: 1rem;
            font-weight: fontWeights.$fontWeightBold;
            white-space: nowrap;
        }
    }

    .lb_mainHeaderBasket {
        padding-right: 0;
        justify-content: flex-end;
        background-color: #ffffff;
        position: relative;

        @include breakpoints.respond-to("desktop") {
            justify-items: center;
            order: 4;
        }

        .lb_mainHeaderBasketNotification {
            height: 18px;
            min-width: 18px;
            text-align: center;
            background-color: #DB003E;
            border-radius: 9px;
            position: absolute;
            top: Calc(50% - 13px);
            left: Calc(50% - 17px);
            ;
            color: #ffffff;
            font-size: 0.75rem;
            line-height: 18px;
            font-weight: fontWeights.$fontWeightSemiBold;

            @include breakpoints.respond-to("desktop") {
                top: Calc(50% - 24px);
                left: Calc(50% - 24px);
                ;
            }
        }
    }

    .lb_mainHeaderBasket::before {
        content: "c";
        font-family: landOfBedsIcons;
        font-size: 1.7rem;
    }

    .lb_mainHeaderBasket::after {
        content: "";

        @include breakpoints.respond-to("desktop") {
            content: "Basket";
            font-size: 0.8rem;
            font-weight: fontWeights.$fontWeightSemiBold;
            padding-top: 5px;
        }
    }
}

.lb_mainHeaderSearch {

    .lb_mainHeaderLogo {
        display: none;
    }

    .lb_popOut.lb-active.lb_popOut-left {
        @include breakpoints.respond-to("tablet") {
            @include layout.responsiveGrid(12, 0, 6, "right");
            @include layout.responsiveGrid(12, 12, -6, "width");

            &:after {
                @include layout.responsiveGrid(12, 0, 6, "width");
            }
        }
    }

    @include breakpoints.respond-to("desktop") {

        .lb_popOut {
            background-color: transparent;
            transition: none !important;
        }

        .lb_popOut.lb-active.lb_popOut-left {
            width: 100%;
            text-align: center;
            right: 0 !important;

            .lb_popOutWrapper {
                background-color: transparent;
            }

            .lb_popOut_body {
                text-align: left;
                padding: lineHeights.$lineHeight*1.5 0 0;
            }

            .lb_mainHeaderLogo {
                display: block;
            }

            .lb_layoutSection-outerPage {
                height: 90px !important;
                width: 100%;
                background-color: #ffffff;
                position: sticky;
                top: 0;
                left: 0;
                z-index: 10;
                background: #ffffff;
                box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.5);

                .lb_layoutSection-innerPage {
                    @include lbGrid.lbGridInnerPage("padding-left");
                    @include lbGrid.lbGridInnerPage("padding-right");
                    text-align: center;

                    .lb_layoutSection-margin {
                        grid-template-columns: 250px 1fr auto;
                        grid-template-rows: 90px;
                        height: 90px;
                        display: grid;
                        column-gap: 6px;
                        align-items: center;
                        justify-items: center;
                    }
                }
            }

            &:after {
                display: none !important;
            }

            .lb_searchBox {
                position: relative;
                order: 1;
            }
        }
    }
}