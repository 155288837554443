@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colours/_signifiers.scss';
@use '/src/scss/styles/colours/_colours.scss';

@mixin heading {
  font-weight: fontWeights.$fontWeightBold!important;
  color: colours.$primaryColour;
  text-decoration: none;
}

@mixin pageHeading {
  @include heading;
  font-size: 1.7rem;
  line-height: 1.2;
}

@mixin sectionHeading {
  @include heading;
  font-size: 1.2rem;
  line-height: 1.2;
  margin-bottom: 24px;
}

@mixin subSectionHeading {
  @include heading;
  font-size: 1.0rem;
  line-height: 1.2;
  margin-bottom: 16px;
}

@mixin navigationHeading {
  @include heading;
  font-size: .9rem;
  line-height: 1.2;
  margin-bottom: 16px;
}

@mixin textHeading {
  font-size: .8rem;
  line-height: 1.2;
  color: colours.$textColour;
  margin-bottom: 12px;
  font-weight: fontWeights.$fontWeightBold;
}