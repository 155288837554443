@use '/src/scss/styles/abstracts/_lineHeights.scss';

.lb_productDetailsArticle {
  .lb_productDetailsTitle,
  .lb_productDetailsImage {
    margin-bottom: lineHeights.$lineHeight;
    text-align: left;
  }
  .lb_productDetailsFinance {
    margin-bottom: lineHeights.$lineHeight;
  }  
}
 
