@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';

.lb_selectionHeader {
  @include typography.text;
  display: flex;
  margin-bottom: lineHeights.$lineHeight * .75;
  flex-direction: row;
  justify-content: space-between;

  @include breakpoints.respond-to("desktop") {
    border: 0;
    padding-top: 0;
  }

  .lb_selectionHeader_selection {
    @include typography.text;
    text-align: left;

    .lb_selectionHeader_selection_lable {
      font-weight: fontWeights.$fontWeightBold;
    }
  }

  .lb_selectionHeader_link {
    text-align: right;
    position: relative;
    right: 4px; 
    @include breakpoints.respond-to("desktop") {
      width: 100%;
    }
    &:after {
      margin-left: 6px;
    }
  }

}
