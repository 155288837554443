
@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/styles/colors/_positiveNegative.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';

.lb_message {
  @include headings.textHeading;
  margin-bottom: lineHeights.$lineHeight;
  padding-left: 32px;
  position: relative;
  padding-top: 3px;
  &.lb_icon-button:before {
    position: absolute;
    left: 0;
    background-color: positiveNegative.$positiveColour;
  }
  &.lb_message-positive {
    color: positiveNegative.$positiveColour; 
  }
  &.lb_message-negative {
    color: positiveNegative.$negativeColour; 
  }
}