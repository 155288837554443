@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/icons/abstracts/_payementIcons.scss';
@use '/src/scss/base/_helpers.scss';

.lb_productDetailsFinance {
  color: colors.$textColour;
  font-size: .8rem;
  padding: lineHeights.$lineHeight*.5;
  display: flex;
  flex-wrap: wrap;
  gap: lineHeights.$lineHeight*.5;
  @include helpers.gapfix(8px);
  

  &.lb_productDetailsFinance-menu {
    border: solid 1px colors.$surfaceColourLight;
    border-radius: 5px;
    flex-direction: column;
  }

  .lb_productDetailsFinance_item {
    font-size: .7rem;
    display: flex;
    align-content: space-around;
    line-height: 1;
    align-items: center;
    padding: 0;
  }

  .lb_productDetailsFinance_item:before {
    display: inline-block;
    height: 24px;
    background-size: cover;
    background-position: center;
    width: 44px;
    margin-right: lineHeights.$lineHeight*.5;
    flex: 0 0 auto;
  }

  .lb_productDetailsFinance_item-klarna:before {
    content: "";
    @include payementIcons.paymentMethodIcons_klarna;
  }
  
  .lb_productDetailsFinance_item-finance:before {
    content: "";
    @include payementIcons.paymentMethodIcons_finance;
  }
}