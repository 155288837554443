@use '/src/scss/layout/abstracts/_bordersBoilerplates.scss';

.lb_layoutSection-boilerplate {
  @include bordersBoilerplates.mobileBoilerplate;
}

.lb_layoutSection-mobileBoilerplate {
  @include bordersBoilerplates.mobileBoilerplate;
}

.lb_layoutSection-tabletBoilerplate {
  @include bordersBoilerplates.tabletBoilerplate;
}

.lb_layoutSection-desktopBoilerplate {
  @include bordersBoilerplates.desktopBoilerplate;
}

.lb_layoutSection-mobileBorder {
  @include bordersBoilerplates.mobileBorder;
}

.lb_layoutSection-tabletBorder {
  @include bordersBoilerplates.tabletBorder;
}

.lb_layoutSection-desktopBorder {
  @include bordersBoilerplates.desktopBorder;
}