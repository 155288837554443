
@use '/src/scss/styles/colors/_colors.scss';

@mixin clearButton {
  background-color: colors.$surfaceColourLight;
  padding: 4px 6px;
  border-radius: 14px;
  height: 27px;
  cursor: pointer;
  font-size: .9rem;
}
