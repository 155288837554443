@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/layout/_breakpoints.scss';

.lb_countdown {
  display: flex;
  justify-content: start;
  align-items: flex-end;
  line-height: 1;
  white-space: nowrap;
  align-items: baseline;

  .lb_countdown_time {
    font-weight: fontWeights.$fontWeightSemiBold;
    font-size: 120%;

    &:first-child {
      margin-left: 3px;
    }
  }

  .lb_countdown_abbr {
    margin-right: 8px;
  }

  .lb_countdown_abbr:last-of-type {
    margin-right: 0;
  }

  .lb_countdown_type {
    margin-right: 8px;
    display: none;
    margin-left: 2px;
    margin-bottom: 1px;
    &:last-child {
      margin-right: 0;
    }
  }
}

[data-countdown-alternative-text-display="false"],
[data-countdown-timer-display="false"] {
  display: none !important;
}


[data-countdown-timer-hide-on-desktop="true"] {
  @include breakpoints.respond-to('desktop'){
      display: none !important;
  }
}

[data-countdown-timer-hide-on-mobile="true"] {
  display: none !important;

  @include breakpoints.respond-to('desktop'){
      display: flex !important;
  }
}
[data-countdown-timer-hide-on-desktop="true"][data-countdown-timer-hide-on-mobile="true"] {
  display: none !important;
}