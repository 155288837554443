.lb_popOut-filters {
  >.lb_popOutWrapper {
    overflow-y: auto;

    >.lb_popOut_header {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 30;
      display: inline-block;
      width: 100%;
      background: #ffffff;
    }
    >.lb_popOut_body {
      padding-top: 0;
    }
  }
}