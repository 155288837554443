@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/colors/_positiveNegative.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/components/tiles/abstracts/_tiles.scss';
@use '/src/scss/icons/abstracts/_fontIcons.scss';

.lb_gallery_mainArea {
  margin-bottom: lineHeights.$lineHeight;

  .lb_gallery_mainArea_image {
    width: 100%;
    height: auto;
    display: block;
  }
}

.lb_gallery_chooser {

  .lb_gallery_chooser_tiles {
    @include tiles.tiles;
    margin: 0;

    .lb_gallery_chooser_tiles_cell {
      cursor: pointer;
    }

    .lb_gallery_chooser_tiles_cell img {
      display: block;
    }

    .lb-selected {
      border: solid 2px signifiers.$primaryCallToActionColour; 
    }

    .lb_gallery_chooser_tiles_cell_image {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

.lb_gallery-showroom .lb_gallery_chooser_tiles_cell {  
  @include tiles.tilesCell;
  @include tiles.tilex3;
  border: solid 2px #ffffff;
  margin-bottom: 0!important;
}

.lb_gallery-colourPicker {
  padding-bottom: lineHeights.$lineHeight;

  .lb_gallery_mainArea {
    position: relative;
    transition: all 250ms ease-in-out;
    margin-left: 0;
    margin-right: 0;

    .lb_colourPickerTile {
      width: 100%;
      padding: 25%;
      &:after {
        display: none;
      }
    }
  }

  .lb_gallery_chooser {
    background-color: #ffffff;
    overflow-y: auto;
    
    .lb_heading {
      margin-bottom: lineHeights.$lineHeight*.5;
    }

    .lb_gallery_chooser_tiles {
      @include tiles.tilesLeftAligh;
      margin-bottom: lineHeights.$lineHeight;
      gap: 5px!important;

      .lb_gallery_chooser_tiles_cell {
        border: solid 2px #ffffff;
      }

      .lb-selected {
        border: solid 2px #ffffff;
        position: relative;
      }

      .lb_gallery_chooser_tiles_cell:hover {
        border: solid 2px #cccccc;
      }

      .lb-selected:before {
        width: 24px;
        height: 24px;
        background-color: positiveNegative.$positiveColour;
        @include fontIcons.tick;
        font-size: .8rem;
        color: #ffffff;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 5px;
        border-radius: 12px;
        outline: 2px solid #ffffff;
        z-index: 1;
      }
    }
  }
}