﻿@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/forms/abstracts/_baseForm.scss';
@use '/src/scss/styles/abstracts/_typography.scss';

.lb_form_fieldset {
  display: block;    
  margin:0 0 lineHeights.$lineHeight;
  border: 0;
  padding: 0;
  min-width: 0;
}

.lb_form_fieldset_legend {
  border: solid 1px colors.$primaryColour;
  border-width: 1px 0 1px 0;
  padding: lineHeights.$lineHeight; 
  width: 100%;
  margin-bottom: baseForm.$formElementControlHeight;
}

.lb_form_fieldset_legend-unavailable {
  border: solid 1px baseForm.$formBorderUnavailable;
  border-width: 1px 0 1px 0;
  color: baseForm.$formBorderUnavailable;
  margin-bottom: lineHeights.$lineHeight;
  a {
    color: baseForm.$formBorderUnavailable;
  }
}

.lb_form_fieldset_legend_edit {
  @include typography.linkSecondary;
  padding-top: 4px;
  float: right;
}

.lb_form_fieldset_legend_edit:hover {
  @include typography.linkSecondaryHover;
}