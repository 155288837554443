@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';

@mixin boilerplate {
  background-color: colors.$surfaceColourTintVeryDark;
  overflow: hidden;
  padding-top: lineHeights.$lineHeight;
}

@mixin mobileBoilerplate {
  @include boilerplate;
}

@mixin tabletBoilerplate {
  @include breakpoints.respond-to("tablet") {
    @include boilerplate;
  }
}

@mixin desktopBoilerplate {
  @include breakpoints.respond-to("desktop") {
    @include boilerplate;
  }
}

@mixin mobileBorder {
  border: solid 1px colors.$surfaceColourLight;
  padding-top: lineHeights.$lineHeight;
}

@mixin tabletBorder {
  @include breakpoints.respond-to("tablet") {
    border: solid 1px colors.$surfaceColourLight;
    padding-top: lineHeights.$lineHeight;
  }
}

@mixin desktopBorder {
  @include breakpoints.respond-to("desktop") {
    border: solid 1px colors.$surfaceColourLight;
    padding-top: lineHeights.$lineHeight;
  }
}