@mixin arrow {
  content: "";
  border-style: solid;
  border-width: 2px 2px 0 0;
  display: inline-block;
  height: 8px;
  width: 8px;
  position: relative;
}

@mixin arrowSmall {
  border-width: 2px 2px 0 0;
  height: 8px;
  width: 8px;
}

@mixin arrowMedium {
  border-width: 4px 4px 0 0;
  height: 14px;
  width: 14px;
}

@mixin arrowLarge {
  border-width: 4px 4px 0 0;
  height: 18px;
  width: 18px;
}

@mixin arrowForward {
  transform: rotate(45deg);
  left: -3px;
}

@mixin arrowBackward {
  transform: rotate(225deg);
  right: -3px;
}

@mixin arrowUp {
  transform: rotate(-45deg);
}

@mixin arrowDown {
  transform: rotate(135deg);
}