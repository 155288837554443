.lb_popUpCarouselGallery {
    width: 100%;
    height: 100%;

    .lb_pagingSelectableCarousel {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #ffffffcc;
        text-align: center;
        width: 100%;
    }

    .lb_singlePagingSelectableCarousel {
        height: 100%;
    }

    &.lb_popUpCarouselGallery-pagingHorizontal {
        .lb_pagingSelectableCarousel {
            padding: 12px 0;
            width: 100%;
        }
        .lb_singlePagingSelectableCarousel {
            .lb_selectableCarousel_prev {
                left: 16px;
            }
            .lb_selectableCarousel_next{
                right: 16px;
            }
        }
    }

    &.lb_popUpCarouselGallery-pagingVertical {
        .lb_pagingSelectableCarousel {
             padding: 0 12px;
        }
        .lb_singlePagingSelectableCarousel {
            .lb_selectableCarousel_prev {
                left: 140px;
            }
        }
    }
}
