﻿@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/colors/_signifiers.scss';

.swal2-container {
  background-color: colors.$overlayColour!important;
}

.swal2-popup {
  width: 300px!important;
}

.swal2-title {
  font-size: 1.2rem!important;
  font-weight: fontWeights.$fontWeightBold!important;
  color: colors.$primaryColour!important;
  text-decoration: none!important;
  line-height: lineHeights.$lineHeightContent!important;
  margin: 24px 0!important;
}

.swal2-styled.swal2-confirm {
  background-color: signifiers.$primaryCallToActionBackgroundColour!important;
  height: lineHeights.$lineHeight*3!important;
  width: 200px!important;
  margin: 0 auto 24px!important;
  font-size: .8rem!important;
  font-weight: fontWeights.$fontWeightRegular!important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow:none!important;
}