@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';

.lb_layoutSection-solidBackground {
  padding-bottom: lineHeights.$lineHeight;
  padding-top: lineHeights.$lineHeight*2;
  overflow: initial;
}

.lb_layoutSection-solidBackground:before {
  @include layout.solidBackground;
}

.lb_layoutSection-videoBackground:before {
  background-color: signifiers.$videoBackground;
}

.lb_layoutSection-videoBackground:before {
  border-left: 9999px solid signifiers.$videoBackground;
  box-shadow: 9999px 0 0 signifiers.$videoBackground;
}

.lb_layoutSection-videoBackground {
  background-color: signifiers.$videoBackground;
}

.lb_layoutSection-surfaceBackground {
  background-color: colors.$surfaceColourTintDark;
}