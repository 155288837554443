@use '/src/scss/components/carousel/_carouselControls.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/icons/abstracts/_fontIcons.scss';
@use '/src/scss/components/popOut/abstracts/_popOut.scss';

// .lb_popOut,
// .lb_popOut-search.lb-active {
//   z-index: 30;
// }

.lb_popOut {
  z-index: 30;
}

.lb_popOut {
@include popOut.popOut;
  
  .lb_popOut_header,
  .lb_popOut_footer {
    @include popOut.popOutStatics;
  }

  &.lb_popOut-desktopNarrow,
  &.lb_popOut-desktopNarrowNoOffset {

    @include breakpoints.respond-to("desktop") {
      align-items: flex-end;
      
      .lb-active {
        width: 375px;
        left: calc(100% - 375px)!important;
      }

      .lb_popOut_header,
      .lb_popOut_footer {
        @include layout.responsiveGrid(12, 0, 4, "padding-left");
        @include layout.responsiveGrid(12, 0, 4, "padding-right");
      }
      
      .lb_lW {
        @include layout.responsiveGrid(12, 0, 4, "padding-left");
        @include layout.responsiveGrid(12, 0, 4, "padding-right");
        margin: 0;
        width: 100%;
      }

      .lb_layoutSection-margin {
        padding: 0;
        width: 100%;
        margin: 0;
      }

    }
  }

  @include breakpoints.respond-to("desktop") {
    width: 1200px;

    .lb_popOut-desktopNarrow {

      .lb_popOut_body {
        width: 375px;
      }
      
      &.lb-active {
        width: 1200px!important;
        left: calc(100% - 1200px)!important;
        background-color: colors.$overlayColour;
        cursor: pointer;
      }
    }
  }

  .lb_popOutWrapper {
    @include popOut.popOutWrapper; 
  }

  &.lb_popOut-right {
    left: 100%;
    @include popOut.popOutTransitionLeft;
  }

  &.lb_popOut-left {
    right: 100%;
    @include popOut.popOutTransitionRight;
  }

  .lb_popOut {
    position: absolute;
    width: 100%!important;
    border-left: 0;

    &:after {
      display: none;
    }
  }

  &.lb-active {

    box-shadow: 0 0 8px 0px rgba(0, 0, 0, .5);
    
    &.lb_popOut-left {
      @include layout.responsiveGrid(12, 0, 6, "right");
      @include layout.responsiveGrid(12, 12, -6, "width"); 
      &:after {
        @include layout.responsiveGrid(12, 0, 6, "width");
        right: 0;
        text-align: right;
        padding-right: 12px;
        padding-top: 12px;
      }

      @include breakpoints.respond-to("tablet") {
        @include layout.responsiveGrid(12, 6, 3, "right");
        @include layout.responsiveGrid(12, 6, -3, "width"); 
        &:after {
          @include layout.responsiveGrid(12, 6, 3, "width"); 
        }
      }

      @include breakpoints.respond-to("desktop") {
        right: calc(100% - 1200px)!important;
      }
    }
    
    &.lb_popOut-right {
      @include layout.responsiveGrid(12, 0, 3, "left");
      &:after {
        left: 0;
        @include layout.responsiveGrid(12, 0, 3, "width");
        text-align: left;
        padding-left: 4px;
        padding-top: 6px;
      }
      @include breakpoints.respond-to("tablet") {
        @include layout.responsiveGrid(12, 6, 3, "left");
        &:after {
          @include layout.responsiveGrid(12, 6, 3, "width");
          padding-left:12px;
          padding-top: 12px;
        }
      }
      @include breakpoints.respond-to("desktop") {
        left: calc(100% - 1200px);
        &:after {
          width:calc(100% - 1200px);
        }
      }
    }

    &:after {
      @include fontIcons.cross;
      height: 100%;
      position: fixed;
      top: 0;
      text-align: center;
      font-size: 1rem;
      color: #ffffff;
      font-weight: fontWeights.$fontWeightBold;
      cursor: pointer;
    }
  }

  .lb_popOut.lb-active {
    &.lb_popOut-left {
      right: 0;
    }

    &.lb_popOut-right {
      left: 0;
    }
  }

  .lb_popOut_body {
    background: #ffffff;
    padding-top: 24px;
  }

  &:not(.lb_popOut-colours) .lb_popOut_body {
    padding-bottom: lineHeights.$lineHeight;
  }
}

.lb_popOut-main > .lb_popOutWrapper {
  height: auto;

  .lb_popOut_body {
    background: #ffffff;
    height: inherit;
    min-height: calc(100vh - 178px);
    @include breakpoints.respond-to("desktop") {
      min-height: calc(100vh - 139px);
    }
  }
}

.lb_popOut-desktopNarrowNoOffset {
  @include breakpoints.respond-to("desktop") {
    width: 375px!important;
    .lb_popOutWrapper {
      width: 375px!important;
    }
    &.lb-active {
      left: calc(100% - 375px)!important;
      &:after {
        width: calc(100% - 375px)!important;
      }
    }
  }
}

.lb_popOut-center {
  position: fixed;
  margin-top:9999px;
  margin-left:9999px;
  border: solid 1px #666666;
  width: calc(100vw - 16px);
  height: auto;
  z-index: 20!important;
  box-shadow: 0 0 8px 0px rgb(0 0 0 / 75%);
  transition: opacity .25s ease-in-out;
  opacity: 0;
}

.lb_popOut-center.lb-active {
  left: 0px !important;
  top: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  opacity: 1;
  margin: 8px;
  &:after,
  &:before {
      display: none;
  }
}

.lb_popOut-close {
  @include carouselControls.carouselControlsSinglePagingSelectableCarousel;
  position: absolute;
  display: inline-block;
  top: 16px; right: 16px;
  font-size: 12pt;
  &:before {
    font-size: 12pt;
    line-height: 1;
    display: block;
  }
}