@use '/src/scss/icons/abstracts/_buttons.scss';

.lb_icon-button:before {
  @include buttons.iconButton;
}

.lb_icon-button.lb_icon-vSmall:before {
  @include buttons.iconButtonVSmall;
}

.lb_icon-button.lb_icon-small:before {
  @include buttons.iconButtonSmall;
}

.lb_icon-button.lb_icon-medium:before {
  @include buttons.iconButtonMedium;
}

.lb_icon-button.lb_icon-large:before {
  @include buttons.iconButtonLarge;
}

.lb_icon-button.lb_icon-xLarge:before {
  @include buttons.iconButtonXLarge;
}

.lb_icon-button.lb_icon-xXLarge:before {
  @include buttons.iconButtonXXLarge;
}