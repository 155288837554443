@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_colors.scss';

@include breakpoints.respond-to("desktop") {
  .lb_megaMenu_list_item:hover {
    .lb_megaMenu-helpAndAdvice {
      display: flex;
      background-color: #ffffff;
      overflow: hidden;
      z-index: 10;
    }
  }
}

.lb_megaMenu-helpAndAdvice {
  @include breakpoints.respond-to("desktop") {
    display: none;
    position: absolute!important;
    left: 0;
    flex-direction: row;

    .lb_megaMenu_heading {
      @include headings.textHeading;
      border: 0;
      padding: 0;
      margin-top: lineHeights.$lineHeight;
      margin-bottom: lineHeights.$lineHeight *.5;
      
      &:after {
        display: none;
      }
    }

    .lb_megaMenu-optionList {
      @include layout.responsiveGrid(12, 0, 1, "padding-left");
      @include layout.responsiveGrid(12, 0, 1, "padding-right");
      margin: 0;
      width: 25%;
      display: flex;
      flex-direction: column;
    }

    .lb_megaMenu_list_item {
      margin: 0;
      padding: 0;
      border: 0;
      width: 100%;

      .lb_megaMenu_list_item_link {
        display: block!important;
        margin: 0 0 8px 0!important;
        padding: 0!important;
        border-bottom: 0!important;
        font-size: .8rem;
        font-weight: fontWeights.$fontWeightRegular;
        background-color: transparent!important;
        line-height: 1.2!important;
        color: colors.$textColour!important;
        font-weight: 400!important;

        &:after {
          display: none !important;
        }
      }
    }
  }
  .lb_megaMenu_tile {
    margin-top: auto;
    display: block;
    padding: lineHeights.$lineHeight 0;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}