
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/icons/abstracts/_buttons.scss';
@use '/src/scss/icons/abstracts/_sizes.scss';
@use '/src/scss/icons/abstracts/_fontIcons.scss';
@use '/src/scss/styles/abstracts/_listItems.scss';
@use '/src/scss/base/_helpers.scss';

.lb_megaMenu-parentCategory {
  margin-top: -20px;

  >.lb_megaMenu_list .lb_megaMenu_list_item {

    .lb_megaMenu_list_item_link {
      @include listItems.listItem;
      color: colors.$textColour;
    }
  }

  .lb_megaMenu_list_item-brand .lb_megaMenu_list_item_link {
    position: relative;

    &:before {
      @include fontIcons.brands;
      @include sizes.iconLarge;
      @include buttons.iconButtonLarge;
      font-size: 26pt;
      float: left;
      width: 40px;
      height: 40px;
      @include listItems.listItemImage;
      margin-top: -15px;
      margin-bottom: 0;
      margin-left: -4px;
      margin-right: 26px;
      text-align: center;
    }
  }

  .lb_megaMenu_list_item-clearance .lb_megaMenu_list_item_link {
    position: relative;
    background-color: signifiers.$offerColour;
    color: #ffffff !important;

    .lb_clearanceDot {
      line-height: 1; 
      @include fontIcons.clearance;
      @include sizes.iconMedium;
      @include buttons.iconButtonLarge;
      font-weight: fontWeights.$fontWeightRegular;
      @include helpers.smoothing;
      background-color: #ffffff;
      color: signifiers.$offerColour;
      font-size: 26pt;
      float: left;
      width: 40px;
      height: 40px;
      @include listItems.listItemImage;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 3px;
      text-align: center;
    }
  }

  .lb_megaMenu_list_item-help,
  .lb_megaMenu_list_item-services,
  .lb_megaMenu_list_item-helpAdvice,
  .lb_megaMenu_list_item-storeLocator,
  .lb_megaMenu_list_item-contactUs,
  .lb_megaMenu_list_item-delivery,
  .lb_megaMenu-optionList-shopAll {
    @include breakpoints.respond-to("desktop") {
      display: none;
    }

    .lb_megaMenu_list_item_link {
      border-bottom: 0 !important;
      font-weight: fontWeights.$fontWeightRegular;
      height: 36px;
    }
  }

  .lb_megaMenu_list_item-helpAdvice,
  .lb_megaMenu_list_item-storeLocator,
  .lb_megaMenu_list_item-delivery,
  .lb_megaMenu_list_item-contactUs {
    margin: 16px 0 0 0;
  }
  
  .lb_megaMenu_list_item-brand,
  .lb_megaMenu_list_item-clearance,
  .lb_megaMenu_list_item-help,
  .lb_megaMenu_list_item-services,
  .lb_megaMenu_list_item-helpAdvice,
  .lb_megaMenu_list_item-storeLocator,
  .lb_megaMenu_list_item-contactUs,
  .lb_megaMenu_list_item-delivery {
    .lb_megaMenu_list_item_link:after {
      display: none !important;
    }
  }

  @include breakpoints.respond-to("desktop") {
    margin: 0;

    >.lb_megaMenu_list {
      position: relative;

      &>.lb_megaMenu_list_item {
        padding: 0;
        border: 0;
        display: inline-block;

        &:hover {
          background-color: #ffffff;
          z-index: 30;

          .lb_megaMenu_list_item_link {
            color: colors.$primaryColour;
          }

          .lb_megaMenu-category {
            display: block;
            z-index: 10;
            @media screen and (max-height: 768px) and (min-resolution: 144dpi) {
              overflow-y: auto;
              max-height: 440px;
            }
          }
        }

        >.lb_megaMenu_list_item_link {
          line-height: 45px;
          display: inline-block;
          height: 45px;
          color: #ffffff;
          cursor: pointer;
          padding: 0 1.2rem;
          border-bottom: 0;

          &:after {
            display: none;
          }
        }

        &.lb_megaMenu_list_item-clearance {
          float: right;

          .lb_megaMenu_list_item_link {
            padding-right: 75px;
            margin-bottom: 0;

            &:hover {
              color: #ffffff;
              z-index: 10;
            }

            .lb_clearanceDot {
              z-index: 10;
              line-height: 1; 
              left: inherit;
              @include sizes.iconXLarge;
              @include buttons.iconButtonXLarge;
              border: solid 2px #ffffff;
              color: #ffffff;
              background-color: signifiers.$offerColour;
              position: absolute;
              top: -10px;
              right: -16px;
              font-size: 36pt;
              padding-left: 4px;
              padding-top: 2px;
              font-weight: fontWeights.$fontWeightRegular;
              @include helpers.smoothing;
            }
          }
        }
      }
    }
  }
}

.lb_megaMenu_list_item-clearance {
  margin-bottom: 18px;
  @include breakpoints.respond-to("desktop") {
    margin-bottom: 0;
  }
}

.lb_megaMenu-optionList-shopAll .lb_megaMenu_heading:after {
  display: none !important;
}