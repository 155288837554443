
@use '/src/scss/base/_paths.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_breakpoints.scss';

@mixin searchBox {
  border: solid 1px #999999;
  width: 100%;
  height: 36px;
  padding: 0 56px 0 32px;
  font-size: 1rem;
  line-height: 36px;
  color: colors.$textColour;
  background-image: url( #{paths.$images}/icons/search.svg);
  background-position: left 8px center;
  background-repeat: no-repeat;
  background-size: 16px;
  
  @include breakpoints.respond-to("desktop") {
    width: 584px;
  }
}
