$textColour: #373737;
$textColourLight: #777;
$textColourVeryLight: #999;
$textColourUnavailable: #cccccc;

$primaryColour: #002E5E;
$primaryColourTint: #DFE5EF;
$primaryColourvarient: #3C568C;
$primaryColourDark: #000334;

$secondaryColour: #368036;
$secondaryColourTint: #FBFFE8;
$secondaryColourTintLight:#f0f2e6;
$secondaryColourBright: #BFEE81;

$secondaryColourDark: #5D8B23;

$surfaceColourVeryDark: #373737;
$surfaceColourDark: #666;
$surfaceColour: #999;
$surfaceColourLight: #ccc;
$surfaceColourVeryLight: #e4e4e4;
$surfaceColourTintVeryDark: #f0f0f0;
$surfaceColourTintDark: #f5f5f5;
$surfaceColourTint: #fafafa;
$surfaceColourTintLight: #fefefe;
$surfaceColourWhite: #fff;

$chatColourDark:#A57632;
$chatColour:#e99700;

$overlayColour: #333333cc;