@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/colors/_positiveNegative.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/content/abstracts/_alphabeticalList.scss';

.lb_list {
  margin-bottom: lineHeights.$lineHeight*1.5;

  .lb_list_item {
    margin-bottom: lineHeights.$lineHeight;
    @include typography.text;
  }

  .lb_icon:before {
    position: relative;
    top: 11px;
    margin-right: 6px;
  }
  .lb_icon-whatsApp:before {
    font-size: 23pt;
    margin-right: 9px;
    margin-left: 3px;
  }
}

.lb_list-compact .lb_list_item {
  margin-bottom: 0;
}

.lb_list-decimal {
  list-style-type: decimal;
  padding-left: 13px;
}

.lb_list-bullets {
  list-style-type: disc;
  padding-left: 16px;
}

.lb_list-2column {
  @include breakpoints.respond-to("tablet") {
    column-count: 2;
    @include layout.responsiveGrid(12, 1, 0, "column-gap");
  }
}

.lb_list-tick {
  list-style-type: none;
  font-size: 1rem;
  li {
    padding-left: 24px;
    position: relative;
   &:before {
      content: "2";
      font-family: landOfBedsIcons;
      color: positiveNegative.$positiveColour;
      margin-right: 3px;
      line-height: 1;
      font-size: .7rem;
      position: absolute;
      left: 0px;
      top: 6px;
    }

    &.lb_text-offer:before {
      color: signifiers.$offerColour;
    }
    
  }
  &.lb_list-centered {
    li {
      padding: 0;
      &:before {
        position: relative;
        top: 0;
        left: -6px;
      }
    }
  }

  &.lb_list-short {
    li {
      margin-bottom: 0!important;
    }
  }
}

.lb_alphabeticalList {
  @include alphabeticalList.alphabeticalList;
}