@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/base/_paths.scss';
@use '/src/scss/base/_helpers.scss';

.lb_mainUSPRibbon {
    display: block;
    background-color: #efefef;

    .lb_mainUSP {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 32px;

        @include breakpoints.respond-to("desktop") {
            flex-direction: row;
        }

        .lb_icon-trustPilot:before {
            content: url('#{paths.$images}trustpilot/Trustpilot-logo.svg');
            background-size: cover;
            width: 82px;
            height: 34px;
            display: block;
        }

        .lb_icon-trustPilotStars {
            background-image: url('#{paths.$images}icons/social/Trustpilot-stars.svg');
            background-size: contain;
            background-repeat: no-repeat;
            width: 82px;
            height: 34px;
            display: block;
            background-position-y: center;
        }

        .lb_mainUSP_item {
            display: none;
            position: absolute;
            flex: 1 0 100%;
            font-size: 0.9rem;
            font-weight: fontWeights.$fontWeightBold;
            align-items: center;
            justify-content: center;
            gap: 8px;
            @include helpers.gapfix(8px);
            height: 22px;
            color: #373737;

            .lb_icon:before {
                position: relative;
                top: 1px;
            }

            @include breakpoints.respond-to("desktop") {
                display: flex;
                position: relative;
                flex-basis: auto;
                font-size: 0.8rem;
                border-right: 1px solid #bbb
            }

            .lb_icon-klarna:before {
                display: block;
            }
        }
        .lb_mainUSP_item:nth-child(1) {
            @include breakpoints.respond-to("desktop") {
                border-left: 1px solid #bbb;
            }
        }

        .lb_mainUSP_item:nth-child(2) {
            display: flex;
        }
        
    }
}