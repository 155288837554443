@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/icons/abstracts/_arrows.scss';
@use '/src/scss/layout/layout.scss';

@use '/src/scss/styles/abstracts/_typography.scss';

.lb_accordion {
  margin-bottom: lineHeights.$lineHeight;
  display: block!important;

  .lb_accordion_item {
    border: solid 1px colors.$surfaceColourVeryLight;
    border-width: 0 0 1px 0;
    position: relative;
    align-items: center;
    list-style-type: none;
    padding-top: 22px;
    padding-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .lb_dynamicContent {
      *:last-child {
        margin-bottom: 0;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .lb_accordion_item_heading {
    @include typography.text;
    font-weight: 600;
    margin-bottom: 0;
    color: colors.$primaryColour;
    cursor: pointer;
    position: relative;
    padding: 0 50px 6px 0;
    /*@include respond-to("tablet") {
      padding-left: 100px;
    }*/

    &:before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      display: block;
      background-color: colors.$primaryColour;
      top: -3px;
      right: 0px;
    }

    &:after {
      color: #ffffff;
      @include arrows.arrow;
      @include arrows.arrowUp;
      @include arrows.arrowMedium;
      transition: all 250ms linear;
      position: absolute;
      top: 6px;
      right: 5px;
    }
  }
  /*
  [data-show-hide-content] {
    @include respond-to("tablet") {
      position: relative;
      padding-left: 100px;
      &:before {
        @include text;
        font-weight: 600;
        content: "Answer";
        position: absolute;
        top: 0;
        left: 0;
        color: $primaryColour;
      }
    }

  }*/

  .lb-hidden.lb_accordion_item_heading:after {
    @include arrows.arrow;
    @include arrows.arrowDown;
    @include arrows.arrowMedium;
    transition: all 250ms linear;
    position: absolute;
    top: 0px;
    right: 5px;
  }

  .lb_text {
    line-height: lineHeights.$lineHeight;
    margin-bottom: lineHeights.$lineHeight;
    position: relative;
    @include layout.responsiveGrid(12, 0, 2, "margin-left");
    @include layout.responsiveGrid(12, 0, 2, "margin-right");
  }
}