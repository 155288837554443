
@use '/src/scss/forms/abstracts/_checkbox.scss';
@use '/src/scss/icons/abstracts/_fontIcons.scss';

input.lb_checkbox {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

label.lb_checkbox,
.lb_checkbox_control {
  cursor: pointer;
  position: relative;
}

label.lb_checkbox .lb_checkbox_control:before {
  @include checkbox.checkbox;
  content: "";
}

label.lb_checkbox .lb_checkbox_control:after {
  @include checkbox.checkboxSelected;
  @include fontIcons.tick;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

input.lb_checkbox:checked + label.lb_checkbox .lb_checkbox_control:after {
  opacity: 1;
}

.lb_checkbox:not(label) {
  @include checkbox.checkbox;
}

.lb_checkbox.lb-selected:not(label) {
  @include checkbox.checkboxSelected;
  &:after {
    @include fontIcons.tick;
  }
}