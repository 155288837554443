@use '/src/scss/base/_paths.scss';

@mixin paymentMethodIcons_klarna {
  background-image: url('#{paths.$images}icons/payment-methods/sized/klarna.svg');
}

@mixin paymentMethodIcons_finance {
  background-image: url('#{paths.$images}icons/payment-methods/sized/finance.svg');
}

@mixin paymentMethodImageIcons_visa {
  content: url('#{paths.$images}icons/payment-methods/sized/visa.svg');
}

@mixin paymentMethodImageIcons_masterCard {
  content: url('#{paths.$images}icons/payment-methods/sized/mastercard.svg');
}

@mixin paymentMethodImageIcons_maestro {
  content: url('#{paths.$images}icons/payment-methods/sized/maestro.svg');
}

@mixin paymentMethodImageIcons_amex {
  content: url('#{paths.$images}icons/payment-methods/sized/amex.svg');
}

@mixin paymentMethodImageIcons_paypal {
  content: url('#{paths.$images}icons/payment-methods/sized/paypal.svg');
}

@mixin paymentMethodImageIcons_klarna {
  content: url('#{paths.$images}icons/payment-methods/sized/klarna.svg');
}

@mixin paymentMethodImageIcons_finance {
  content: url('#{paths.$images}icons/payment-methods/sized/finance.svg');
}
