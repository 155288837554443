@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/icons/abstracts/_arrows.scss';
@use '/src/scss/layout/_layout.scss';

.lb_servicesMenu {
  border: solid 1px colors.$surfaceColourLight;

  .lb_servicesMenu_item {
    display: flex;
    align-content: space-around;
    align-items: center;
    position: relative;
    border-bottom: 1px solid colors.$surfaceColourLight;
    height: 64px;
    top: 3px;
    @include headings.textHeading;
    margin-bottom: 0px;
    font-size: .9rem;
    @include layout.responsiveGrid(12, 0, 2, "margin-left");
    @include layout.responsiveGrid(12, 0, 2, "margin-right");
    @include breakpoints.respond-to("desktop") {
      @include layout.responsiveGrid(4, 0, 1, "margin-left");
      @include layout.responsiveGrid(4, 0, 1, "margin-right");
    }
    cursor: pointer;
  }

  .lb_servicesMenu_item:last-child {
    border: 0;
  }

  .lb_servicesMenu_item:before {
      @include arrows.arrow;
      @include arrows.arrowForward;
      left: initial;
      right: 0;
      position: absolute;
  }

  .lb_servicesMenu_item .lb_icon {
    text-align: center;
    width: 90px;
  }

  .lb_servicesMenu_item .lb_icon:before {
    position: relative;
    top: 1px;
    margin-right: 10px;
  }



  .lb_servicesMenu_item .lb_icon-recycle, 
  .lb_servicesMenu_item .lb_icon-delivery, 
  .lb_servicesMenu_item .lb_icon-deliveryReturns,
  .lb_servicesMenu_item .lb_icon-services  {
    padding-left: 24px;
    @include breakpoints.respond-to("desktop") {
      padding-left: 24px;
    }
  }

  

}