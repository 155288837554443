@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/layout/_layout.scss';

.lb_customerReviewTile {
  width: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 6px;
  background-color: #ffffff;
  @include layout.responsiveGrid(12, 0, 4, "padding");
  min-height: 300px;
  row-gap: 16px;

  .lb_customerRating {
    align-items: flex-start;
  }

  .lb_cusomerReviewTile_review {
    @include typography.text;
  }

  .lb_cusomerReviewTile_customerDetails {
    @include typography.miniText;
    margin-top: auto;
    display: flex;
    gap: 6px;
  }

  .lb_cusomerReviewTile_customerDetails_name {
    @include typography.miniText;
  }

  .lb_cusomerReviewTile_customerDetails_date {
    @include typography.miniText;
  }
}