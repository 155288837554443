@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';

@mixin formLabel {
  font-size: 1.2rem;
  color: signifiers.$secondaryCallToActionColour;
}

@mixin formLabelFocus {
  font-size: 1rem;
}

@mixin formInputText {
  @include text;
}

@mixin menuItem {
  font-size: .9rem;
  color: colors.$primaryColour;
  font-weight: fontWeights.$fontWeightBold;
}

@mixin miniText {
  font-size: .7rem;
  line-height: 1.34;
}

@mixin text {
  font-size: .8rem;
  color: colors.$textColour;
  line-height: lineHeights.$lineHeightContent;
  text-decoration: none;
}

@mixin textLarge {
  font-size: 1rem;
  color: colors.$textColour;
  line-height: lineHeights.$lineHeightContent;
}

@mixin textXLarge {
  font-size: 1.2rem;
  color: colors.$textColour;
  line-height: lineHeights.$lineHeightContent;
}

@mixin textXXLarge {
  font-size: 1.4rem;
  color: colors.$textColour;
  line-height: lineHeights.$lineHeightContent;
}

@mixin textXXXLarge {
  font-size: 1.6rem;
  color: colors.$textColour;
  line-height: lineHeights.$lineHeightContent;
}

@mixin textXXXXLarge {
  font-size: 1.8rem;
  color: colors.$textColour;
  line-height: lineHeights.$lineHeightContent;
}

@mixin textIntro {
  font-size: .8rem;
  color: colors.$textColour;
  line-height: lineHeights.$lineHeightContent;
  @include breakpoints.respond-to("desktop") {
    font-size: .9rem;
  }
}



@mixin linkPrimary {
  @include text;
  color: signifiers.$primaryCallToActionColour;
  text-decoration: none;
  cursor: pointer;
}

@mixin linkPrimaryHover {
  color: signifiers.$primaryCallToActionColourHover !important;
  text-decoration: none;
}

@mixin linkSecondary {
  @include text;
  color: signifiers.$secondaryCallToActionColour;
  text-decoration: none;
  cursor: pointer;
}

@mixin linkSecondaryHover {
  color: signifiers.$secondaryCallToActionColourHover !important;
  text-decoration: none;
}

@mixin emphasis {
  font-weight: fontWeights.$fontWeightBold;
}
  
@mixin italic {
  font-style: italic;
}