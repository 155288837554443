@use '/src/scss/global/header/mM/abstracts/_mMStyles.scss';

.lb_mM-brands {
    .lb_alphabeticalList {
        column-count: 4;
        column-gap: 2rem;
        margin: 2rem;
    }
    .lb_alphabeticalList + a {
        @include mMStyles.mMLink;
        float: right;
        width: 25%;
    }
}