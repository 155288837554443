@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_signifiers.scss';

.lb_link {
  @include typography.text;
  text-decoration: underline;
  color: signifiers.$primaryCallToActionColour;
  font-weight: fontWeights.$fontWeightBold;
}

.lb_link-wordBreak {
  word-break: break-all;
}

.lb_link-secondary {
  color: signifiers.$secondaryCallToActionColour;
}

.lb_link-secondary:hover {
  color: signifiers.$secondaryCallToActionColour!important;
}

.lb_link-neg {
  color:#ffffff!important;
  text-decoration: none;
}

.lb_link-neg:hover {
  text-decoration: underline;
}

.lb_link-undrlined {
  text-decoration: underline;
}

.lb_link:hover {
  text-decoration: underline;
  color: signifiers.$primaryCallToActionColourHover;
  cursor: pointer;
}