@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/icons/abstracts/_fontIcons.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/colors/_signifiers.scss';

.lb_mobileMenuWrapper {
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
}

.lb_mobileMenu {
  overflow: hidden;
  height: lineHeights.$lineHeight * 4;
  margin-bottom: lineHeights.$lineHeight * 1;
  background-color: colors.$primaryColour;
  @include breakpoints.respond-to("desktop") {
    display: none;
  }

  .lb_mobileMenu_item {
    width: 25%;
    display: block;
    float: left;
    height: lineHeights.$lineHeight * 4;
    @include typography.menuItem;
    color: #ffffff;
    text-align: center;
    padding-top: lineHeights.$lineHeight;
    cursor: pointer;
  }

  .lb_mobileMenu_item:before {
    font-size: 1.2rem;
    font-weight: fontWeights.$fontWeightRegular;
    color: #ffffff;
    display: block;
    content: "";
    width: 24px;
    height: 24px;
    margin: 0 auto;
  }

  .lb_mobileMenu_item-basket:before {
    @include fontIcons.basket;
  }

  .lb_mobileMenu_item-search:before {
    @include fontIcons.search;
  }

  .lb_mobileMenu_item-menu:before {
    @include fontIcons.burger;
  }

  // .lb_mobileMenu_item-compare:before {
  //   @include fontIcons.compare;
  //   font-size: 1.1rem;
  // }

  .lb_mobileMenu_item_notification {
    width: 20px;
    height: 20px;
    background-color: signifiers.$offerColour;
    border-radius: 9px;
    position: absolute;
    top: 14px;
    right: 1px;
    color: #ffffff;
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: fontWeights.$fontWeightSemiBold;
  }
}