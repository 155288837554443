[data-show-hide-toggle] {
  cursor: pointer;
}

[data-show-hide-content] {
  overflow: hidden;
  transition: max-height .5s ease-in-out , opacity .5s ease;
  max-height: 1000px;
}

[data-show-hide-content].lb-hidden {
  opacity: 0;
  max-height: 0;
}