@use '/src/scss/layout/_breakpoints.scss';

.lb_productDetailsImage {
  width: 100%;
  height: auto;
  cursor: default;

  img {
		display: block;
		width: 100%;
		height: auto;
		cursor:	pointer;
	}
}