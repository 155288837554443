@use '/src/scss/base/_helpers.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';

.lb_outerPageSection {
  width: 100%;
  display: block;
}

.lb_innerPageSection {  
  @include layout.responsiveMargin("padding-left");
  @include layout.responsiveMargin("padding-right");
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
  clear: both;
}

.lb_innerPageSection-blead {
  padding-left: 0;
  padding-right: 0;
  @include breakpoints.respond-to("desktop") {
    @include layout.responsiveMargin("padding-left");
    @include layout.responsiveMargin("padding-right");
  }
}

.lb_innerPageSection-wide {  
  max-width: 1440px;
  margin-left: auto!important;
  margin-right: auto!important;
}

.lb_innerPageSection-xWide {  
  max-width: 1920px;
}

.lb_mainSection {
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.lb_noScroll {
  position: fixed;
  overflow-y: hidden;
  padding-right: 0;
  max-height: 100vh;
  width: 100%;
  @include breakpoints.respond-to("desktop") {
    position: relative;
    max-height: initial;
  }
} 

.lb_lW {  
  @include layout.responsiveMargin("margin-left");
  @include layout.responsiveMargin("margin-right");
  clear: both;
}

.lb_mainSection-wide {
  max-width: none;
  width: 100%;
  margin: 0;

  .lb_lW { 
    max-width: 1440px;
    @include layout.responsiveMargin("padding-left");
    @include layout.responsiveMargin("padding-right");
    margin: 0 auto;
  }
}

.lb_layoutSection {
  position: relative;
  margin-bottom: lineHeights.$lineHeight;
  overflow: visible;
}

.lb_layoutSection:after {
  @include helpers.clearFix;
}

.lb_layoutSection-fullWidth {
  margin: lineHeights.$lineHeight*2 0 ;
  width: 100%;
}

.lb_layoutSection {
  .lb_heading,
  .lb_text {
    margin-bottom: lineHeights.$lineHeight;
  }
  .lb_img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.lb_hr {
  margin-bottom: lineHeights.$lineHeight*2;
  clear: both;
}

.lb_layoutSection-desktopFloatRight {
  @include breakpoints.respond-to("desktop") {
    float: right;
  }
}