@use '/src/scss/styles/colors/_colors.scss';

$overlayTransition: 250ms;
.lb_overlay {
  z-index: 10;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: colors.$overlayColour;
  transition: background-color $overlayTransition;
  pointer-events: initial;
  cursor: pointer;
  
  &.lb-hidden {
    background-color: #66666600;
    transition: background-color $overlayTransition;
    pointer-events: none;
  }
}