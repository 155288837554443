@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';

.lb_imageTile img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.lb_imageTile_name {
  @include typography.text;
  font-weight: fontWeights.$fontWeightBold;
  line-height: 1.2;
  text-align: center;
}

.lb_carouselBrandTile {
  display: flex;
  justify-content: center;

  img {
    display: block;
    max-width: 120px;
    max-height: 120px;
    width: 100%;
    height: 100%;
  }
}