@use '/src/scss/icons/abstracts/_payementIcons.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';

.lb_icon-klarna:before {
  @include payementIcons.paymentMethodIcons_klarna;
  content: "";
  background-size: cover;
  background-position: 0;
  width: 51px;
  height: 40px;
  display: inline-block;
}

.lb_icon-finance:before {
  @include payementIcons.paymentMethodIcons_finance;
  content: "";
  background-size: cover;
  background-position: 0;
  width: 51px;
  height: 40px;
  display: inline-block;
}

.lb_icon-small.lb_icon-finance:before,
.lb_icon-small.lb_icon-klarna:before {
  width: 40px;
  height: 23px;
}

.lb_icon-medium.lb_icon-finance:before,
.lb_icon-medium.lb_icon-klarna:before {
  width: 51px;
  height: 28px;
}

.lb_paymentIcons-visa {
  display: block;
  @include payementIcons.paymentMethodImageIcons_visa;
}

.lb_paymentIcons-masterCard {
  display: block;
  @include payementIcons.paymentMethodImageIcons_masterCard;
}

.lb_paymentIcons-maestro {
  display: block;
  @include payementIcons.paymentMethodImageIcons_maestro;
}

.lb_paymentIcons-amex {
  display: block;
  @include payementIcons.paymentMethodImageIcons_amex;
}

.lb_paymentIcons-paypal {
  display: block;
  @include payementIcons.paymentMethodImageIcons_paypal;
}

.lb_paymentIcons-klarna {
  display: block;
  @include payementIcons.paymentMethodImageIcons_klarna;
}

.lb_paymentIcons-finance {
  display: block;
  @include payementIcons.paymentMethodImageIcons_finance;
}

.lb_paymentIcons {
  margin: auto;
  display: inline-flex;
	position: relative;
	gap: lineHeights.$lineHeight * 0.75;

	@include breakpoints.respond-to("tablet") {
		gap: lineHeights.$lineHeight * 1.25;
  }

  img {
    width: auto;
		height: 35px; 
  }
}



