@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/icons/abstracts/_arrows.scss';
@use '/src/scss/forms/abstracts/_button.scss';
@use '/src/scss/styles/colors/_positiveNegative.scss';

.lb_carousel {
  position: relative;

  &:before {
    content: "mobile";
    display: none;
  }

  @include breakpoints.respond-to("tablet") {
    &:before {
      content: "tablet";
      display: none;
    }
  }

  @include breakpoints.respond-to("desktop") {
    margin-bottom: lineHeights.$lineHeight;

    &:before {
      content: "desktop";
      display: none;
    }
  }
}

.lb_carousel_cellsWrapper {
  display: flex;
}

.lb_carousel_cells {
  display: flex;
  -webkit-overflow-scrolling: touch;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  align-items: center;
  width: 100%;
  
  @include breakpoints.respond-to("desktop") {
    overflow-x: hidden !important;
    @include layout.responsiveGrid(12, 0, 2, "margin-left");
    @include layout.responsiveGrid(12, 12, -4, "width");
    scroll-behavior: smooth;
  }
}

.lb_carousel_cell {
  width: 60%;
  height: 100%;
  list-style: none;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  @include layout.responsiveGrid(12, 0, 1, "padding-left");
  @include layout.responsiveGrid(12, 0, 1, "padding-right");

  @include breakpoints.respond-to("tablet") {
    width: 40%;
  }

  &:first-child {
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
  }

  &:last-child {
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
  }

  @include breakpoints.respond-to("desktop") {
    width: 25%;
  }

}

.lb_carousel_next {
  display: none;

  @include breakpoints.respond-to("desktop") {
    position: absolute;
    right: 0;
    top: 0;
    @include layout.responsiveGrid(12, 0, 2, "width");
    height: 100%;
    cursor: pointer;
  }
}

.lb_carousel_next:before {
  top: calc(50% - 15px);
  position: absolute;
  @include arrows.arrow;
  @include arrows.arrowForward;
  @include arrows.arrowLarge;
  right: -6px !important;
  left: initial;
}

.lb_carousel_previous {
  display: none;

  @include breakpoints.respond-to("desktop") {
    position: absolute;
    left: 0;
    top: 0;
    @include layout.responsiveGrid(12, 0, 2, "width");
    height: 100%;
    cursor: pointer;
  }
}

.lb_carousel_previous:after {
  top: calc(50% - 15px);
  position: absolute;
  @include arrows.arrow;
  @include arrows.arrowBackward;
  @include arrows.arrowLarge;
  right: -6px !important;
  left: initial;
}

.lb_carousel_cell_button {
  width: 100% !important;
  padding-top: 0;
  margin: 16px 0 0 0 !important;
}

.lb_carousel_cell_viewDetails {
  @include button.button;
  @include button.secondaryButtonOutline;
  margin: lineHeights.$lineHeight 0 !important;
}

.lb_carousel-sizes {

  .lb_carousel_cell {
    min-width: 52%;
    width: auto;

    @include breakpoints.respond-to("tablet") {
      min-width: 24%;
    }
  }
}

.lb_carousel-sizes.lb-warning {
  border: solid 1px positiveNegative.$negativeColour;
  padding: 12px 0;
  border-radius: 5px;

  .lb_sizeTile {
    background-color:#ffffff;
    border: solid 2px #cccccc;
  }
}

.lb_carousel .lb_carousel_cell {

  div.lb_productDetailsImage {
    padding-top: 75%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}