@use '/src/scss/layout/_breakpoints.scss';

.lb_video {
  margin: 0 auto;
}

.lb_pagingSelectableCarousel-flickityClickDots .lb_video .lb_video-youtube {

  img {
    display: none!important;
    @include breakpoints.respond-to("desktop") {
      display: block!important;
    }
  }

  .play-button {
    background-color: transparent;
    @include breakpoints.respond-to("desktop") {
      background-color: #333333;
    }
    
    &:before {
      border-width: 5px 0 5px 8px!important;
      border-color:transparent transparent transparent#ffffff!important;
    }
  }
} 

.lb_pagingSelectableCarousel-flickityClickDots .lb-selected .lb_video .lb_video-youtube {
  .play-button {
    &:before {
      border-color:transparent transparent transparent #ffffff!important;
    }
  }
} 

.lb_video-youtube {
  background-color: #000;
  margin-bottom: 30px;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  cursor: pointer;
  display: block!important;
}

.lb_video-youtube img {
  width: 100%;
  height: auto;
  top: -16.82%;
  left: 0;
  opacity: 0.7;
}

.lb_video-youtube .play-button {
  width: 90px;
  height: 60px;
  background-color: #333;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  z-index: 1;
  border-radius: 6px;
}

.lb_video-youtube .play-button:before {
  content: "";
  border-style: solid;
  border-width: 15px 0 15px 26.0px;
  border-color: transparent transparent transparent #fff;
}

.lb_pagingSelectableCarousel .lb_video {
  height: 100%;

  .lb_video-youtube {
    
    margin-bottom: 0;

    img {
      top: 0;
    }

    .play-button {
      width: 45px;
      height: 30px;

      &:before {
        border-width: 7px 0 7px 13.0px;
      }
    }
  }
}

.lb_singlePagingSelectableCarousel .lb_video {
  .lb_video-youtube {
    padding-top: 75%;
    margin-bottom: 0;

    img {
      top: 12.5%;
    }
  }
}

.lb_video-youtube img,
.lb_video-youtube .play-button {
  cursor: pointer;
}

.lb_video-youtube img,
.lb_video-youtube iframe,
.lb_video-youtube .play-button,
.lb_video-youtube .play-button:before {
  position: absolute;
}

.lb_video-youtube .play-button,
.lb_video-youtube .play-button:before {
  top: 50%;
  left: 50%;
  transform: translate3d(-49%, -50%, 0);
}

.lb_video-youtube iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.lb_zoomable-gallery .lb_singlePagingSelectableCarousel {
  .lb_video {
    margin: 0 auto;
    height: calc(100% - 100px);
    background: #000000;
    margin: 0 auto;

}

  .lb_carouselTile.lb_carouselTile-zoomed.lb_video-youtube {
      padding-top: 0;
      max-width: 768px;
      margin: 0 auto;
      display: flex!important;
      align-items: center;

      img {
        position: relative;
        top: inherit;
      }
  }
}