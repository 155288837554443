@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/components/tiles/abstracts/_tiles.scss';

.lb_tiles {
  @include tiles.tiles;
}

.lb_tiles-spaced .lb_tiles_cell {
  margin-bottom: 26px;
}

.lb_tiles_cell {
  @include tiles.tilesCell;
}

.lb_tiles-leftAlign {
  @include tiles.tilesLeftAligh;
}

.lb_tiles-mobilex2 {
  .lb_tiles_cell {
    @include tiles.tilex2;
  }
}

.lb_tiles-mobilex3 {
  .lb_tiles_cell {
    @include tiles.tilex3;
  }
}

.lb_tiles-mobilex4 {
  .lb_tiles_cell {
    @include tiles.tilex4;
  }
}

.lb_tiles-mobileWrap {
  .lb_tiles_cell {
    width: initial;
  }
}

.lb_tiles-tabletx2 {
  @include breakpoints.respond-to("tablet") {
  .lb_tiles_cell {
      @include tiles.tilex2;
    }
  }
}

.lb_tiles-tabletx3 {
  @include breakpoints.respond-to("tablet") {
    .lb_tiles_cell {
      @include tiles.tilex3;
    }
  }
}

.lb_tiles-tabletx4 {
  @include breakpoints.respond-to("tablet") {
    .lb_tiles_cell {
      @include tiles.tilex4;
    }
  }
}

.lb_tiles-tabletWrap {
  @include breakpoints.respond-to("tablet") {
    .lb_tiles_cell {
      width: initial;
    }
  }
}

.lb_tiles-desktopx2 {
  .lb_tiles_cell {
    @include breakpoints.respond-to("desktop") {
      @include tiles.tilex2;
    }
  }
}

.lb_tiles-desktopx3 {
  .lb_tiles_cell {
    @include breakpoints.respond-to("desktop") {
      @include tiles.tilex3;
    }
  }
}

.lb_tiles-desktopx4 {
  @include breakpoints.respond-to("desktop") {
    .lb_tiles_cell {
       @include tiles.tilex4;
    }
  }
}

.lb_tiles-desktopWrap {
  @include breakpoints.respond-to("desktop") {
    .lb_tiles_cell {
      width: initial;
    }
  }
}