/*IMPROVE: .lb_layoutSection-margin and .lb_layoutSection-mobileMargin are duplicated. .lb_layoutSection-margin need to be removed*/
@use '/src/scss/layout/abstracts/_margins.scss';

.lb_layoutSection-bottomMarginXXSmall {
    @include margins.bottomMarginXXSmall;
}

.lb_layoutSection-bottomMarginXSmall {
    @include margins.bottomMarginXSmall;
}

.lb_layoutSection-bottomMarginSmall {
    @include margins.bottomMarginSmall;
}

.lb_layoutSection-bottomMargin {
    @include margins.bottomMargin;
}

.lb_layoutSection-bottomMarginLarge {
    @include margins.bottomMarginLarge;
}

.lb_layoutSection-bottomMarginXLarge {
    @include margins.bottomMarginXLarge;
}

.lb_layoutSection-bottomMarginXXLarge {
    @include margins.bottomMarginXXLarge;
}

.lb_layoutSection-butting {
    @include margins.bottomButting;
}

.lb_layoutSection-margin,
.lb_layoutSection-mobileMargin {
    @include margins.mobileMargin;
}

.lb_layoutSection-tabletMargin {
    @include margins.tabletMargin;
}

.lb_layoutSection-desktopMargin {
    @include margins.desktopMargin;
}