@mixin clearFix {
  content: "";
  clear: both;
  display: table;
 }

@mixin smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lb-disableCssTransitions {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.lb-img {
 width: 100%;
 height: auto;
 display: block;
}

.lb-disabled {
 cursor: default;
 pointer-events: none;
 opacity: .5;
}

@mixin gapfix($gap) {
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-right: $gap;
    }
  }
}