@use '/src/scss/layout/_breakpoints.scss';

.lb_selectableCarousel.lb_pagingSelectableCarousel-flickityClickDots {
  justify-content: center;
  
  .lb_selectableCarousel_cells {
    justify-content: center;
    height: auto;

    .lb_selectableCarousel_cells_cell {
      flex: 0 20px;
      height: 20px;
      width: 20px;

      &.lb_selectableCarousel_cells_cell-filler,
      img {
        display: none;
      }

      .lb_carouselTile-youtube:after {
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 8px;
        position: relative;
        top: 1px;
        left: 2px;
        border-color: transparent transparent transparent #ffffff;
      }
    }
  }
  .lb_selectableCarousel_prev,
    .lb_selectableCarousel_next {
      display: none!important;
      @include breakpoints.respond-to ("desktop") {
        display: flex!important;
      }
  }
}
