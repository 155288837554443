@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';




@mixin topPaddedXSmall {
  padding-top: lineHeights.$lineHeight*.25!important;
}

@mixin topPaddedSmall {
  padding-top: lineHeights.$lineHeight*.5!important;
}

@mixin topPadded {
  padding-top: lineHeights.$lineHeight;
}

@mixin topPaddedLarge {
  padding-top: lineHeights.$lineHeight*1.5;
}



@mixin bottomPaddedXSmall {
  padding-bottom: lineHeights.$lineHeight*.25!important;
}

@mixin bottomPaddedSmall {
  padding-bottom: lineHeights.$lineHeight*.5!important;
}

@mixin bottomPadded {
  padding-bottom: lineHeights.$lineHeight*.75!important;
}

@mixin bottomPaddedLarge {
  padding-bottom: lineHeights.$lineHeight*1.5!important;
}

@mixin bottomPaddedXLarge {
  padding-bottom: lineHeights.$lineHeight*2!important;
}

@mixin mobilePadded {  
  @include layout.responsiveGrid(12, 0, 1, "padding-left");
  @include layout.responsiveGrid(12, 0, 1, "padding-right");
}

@mixin tabletPadded {
  @include breakpoints.respond-to("tablet") {
    @include layout.responsiveGrid(12, 0, 1, "padding-left");
    @include layout.responsiveGrid(12, 0, 1, "padding-right");
  }
}

@mixin desktopPadded {
  @include breakpoints.respond-to("desktop") {
    @include layout.responsiveGrid(12, 0, 1, "padding-left");
    @include layout.responsiveGrid(12, 0, 1, "padding-right");
  }
}

@mixin mobileDoublePadded {
  @include layout.responsiveGrid(12, 0, 2, "padding-left");
  @include layout.responsiveGrid(12, 0, 2, "padding-right");
}

@mixin mobileSuperPadded {
  @include layout.responsiveGrid(12, 0, 4, "padding-left");
  @include layout.responsiveGrid(12, 0, 4, "padding-right");
}

@mixin desktopSuperPadded {
  @include breakpoints.respond-to("desktop") {
    @include layout.responsiveGrid(12, 0, 4, "padding-left");
    @include layout.responsiveGrid(12, 0, 4, "padding-right");
  }
}