@use '/src/scss/base/_paths.scss';

@mixin colourWheel {
  $colourWheel: url(#{paths.$images}icons/colour-wheel.svg);
  background-image: $colourWheel;
  height: 24px;
  width: 24px;
  background-size: auto 24px;
  display: block;
}

@mixin christmasTree {
  $christmasTree: url(#{paths.$images}icons/christmas-tree.svg);
  background-image: $christmasTree;
}