﻿@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/base/_helpers.scss';

.lb_customerRating {
  color: colors.$textColour;
  display: flex;
  line-height: 1;

  &.lb_customerRating-small {
    font-size: .8rem;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 6px;
    @include helpers.gapfix(6px);

    .lb_customerRating_stars {
      width: 80px;
      height: auto;
    }

    .lb_customerRating_score {
      white-space: nowrap;
      font-weight: fontWeights.$fontWeightSemiBold;
      position: relative;
    }

    .lb_customerRating_reviews {
      white-space: nowrap;
    }
  }

  &.lb_customerRating-medium {
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 6px;
    @include helpers.gapfix(6px);

    .lb_customerRating_stars {
      width: 120px;
      height: auto;
    }

    .lb_customerRating_score {
      font-size: 1.1rem;
      white-space: nowrap;
      display: inline;
      font-weight: fontWeights.$fontWeightSemiBold;
    }

    .lb_customerRating_reviews {
      white-space: nowrap;
      font-size: .9rem;
      display: inline;
    }
  }

  &.lb_customerRating-large {
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    align-items: center;
    gap: 6px;
    @include helpers.gapfix(6px);

    .lb_customerRating_stars {
      width: 180px;
      height: auto;
    }

    .lb_customerRating_score {
      font-size: .9rem;
      white-space: nowrap;
      display: inline;
    }

    .lb_customerRating_reviews {
      white-space: nowrap;
      font-size: .9rem;
      display: inline;
    }

  }


  &.lb_customerRating-tile{
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
    align-items: center;
    justify-content: center;

    .lb_customerRating_stars {
      width: 150px;
      height: auto;
      order: 3;
    }

    .lb_customerRating_score {
      order: 1;
      font-size: 2rem;
      font-weight: fontWeights.$fontWeightSemiBold;
    }

    .lb_customerRating_reviews {
      order: 2;
    }
  }
}