@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/layout/abstracts/_lbGrid.scss';
@use '/src/scss/components/popOut/abstracts/_popOut.scss';
@use '/src/scss/icons/abstracts/_arrows.scss';
@use '/src/scss/icons/abstracts/_fontIcons.scss';
@use '/src/scss/icons/abstracts/_sizes.scss';
@use '/src/scss/styles/abstracts/_listItems.scss';
@use '/src/scss/icons/abstracts/_buttons.scss';
@use '/src/scss/global/header/mM/abstracts/_mMStyles.scss';

.lb_mM {
    @include popOut.popOut;
    @include popOut.popOutTransitionRight;
    right: 100%;

    &.lb-active {
        @include popOut.popOutLeftActive;
    }

    >div {
        @include popOut.popOutWrapper;

        >div:nth-child(1) {
            @include popOut.popOutHeader;

            >div {
                @include popOut.backLink;

                float: right;
                padding-top: 4px;

                &:before {
                    @include arrows.arrow;
                    @include arrows.arrowBackward;
                    margin-right: 6px;
                }
            }
        }

        >ul>li> {

            //border: solid 1px BLUE;
            div:nth-child(1) {
                // border: solid 1px green;
                @include listItems.listItem;
                color: colors.$textColour;

                img {
                    @include listItems.listItemImage;
                }

                &.lb_mM-chat,
                &.lb_mM-call,
                &.lb_mM-help,
                &.lb_mM-store,
                &.lb_mM-delivery {
                    background-color: colors.$surfaceColourTint;

                    &:after {
                        display: none;
                    }

                    &:before {
                        @include sizes.iconSmall;
                        @include layout.responsiveGrid(12, 0, 1, "margin-right");
                        color: colors.$textColour;
                    }
                }

                &.lb_mM-brands:before,
                &.lb_mM-sale:before {
                    @include buttons.iconButton;
                    @include buttons.iconButtonLarge;
                    @include listItems.listItemImage;
                    font-size: 30pt;
                    width: 40px;
                    height: 40px;
                }

                &.lb_mM-brands:before {
                    @include fontIcons.brands;
                }

                &.lb_mM-sale:before {
                    @include fontIcons.priceMatch;
                }

                &.lb_mM-call:before {
                    @include fontIcons.phone;
                }

                &.lb_mM-chat:before {
                    @include fontIcons.liveChat;
                }

                &.lb_mM-help:before {
                    @include fontIcons.expertAdvice;
                }

                &.lb_mM-store:before {
                    @include fontIcons.location;
                }

                &.lb_mM-delivery:before {
                    @include fontIcons.delivery;
                }
            }

            div:nth-child(2) {
                @include popOut.popOut;
                @include popOut.popOutTransitionRight;
                right: 100%;

                &.lb-active {
                    @include popOut.popOutLeftActive;
                }

                >div {
                    @include popOut.popOutWrapper;

                    >div:nth-child(1) {
                        @include popOut.popOutHeader;

                        h2 {
                            @include mMStyles.mMHeading;
                            float: left;
                        }

                        >div {
                            @include popOut.backLink;

                            float: right;
                            padding-top: 4px;

                            &:before {
                                @include arrows.arrow;
                                @include arrows.arrowBackward;
                                margin-right: 6px;
                            }
                        }
                    }

                    >div:nth-child(2) {
                        @include popOut.popOutStatics;
                    }
                }
            }
        }
    }
}

@include breakpoints.respond-to("desktop") {
    .lb_mM {
        @include lbGrid.layoutSection-outerPage;
        background-color: colors.$primaryColour;
        position: relative;
        overflow: initial;
        right: initial;

        >div {
            @include lbGrid.layoutSection-innerPage;
            height: 100%;
            background-color: colors.$primaryColour;
            overflow: initial;

            >div:nth-child(1) {
                display: none;
            }

            >ul {
                display: flex;
                height: 100%;

                >li {
                    height: 100%;

                    &.lb_mM-chat,
                    &.lb_mM-call,
                    &.lb_mM-store,
                    &.lb_mM-delivery {
                        display: none;
                    }

                    >div:nth-child(1) {
                        color: #ffffff;
                        font-size: 0.9rem;
                        font-weight: 600;
                        border: 0;
                        padding: 19px;
                        display: flex;
                        align-items: center;
                        height: 45px;
                        white-space: nowrap;

                        &:hover {
                            background: #ffffff;
                            color: colors.$primaryColour;
                            cursor: pointer;
                        }

                        &:after,
                        &:before {
                            display: none !important;
                        }
                    }

                    &.lb_mM-help {
                        margin-left: auto;
                    }

                    >div >img,
                    &.lb_mM-brands:before,
                    &.lb_mM-sale:before {
                        display: none !important;
                    }

                    &:hover>div:nth-child(2) {
                        @include lbGrid.layoutSection-outerPage;
                        transition: none;
                        display: block;
                        position: absolute;
                        right: unset;
                        top: 45px;
                        left: 0;
                        bottom: unset;

                        >div {
                            @include lbGrid.layoutSection-innerPage;

                            >div:nth-child(1) {
                                display: none;
                            }

                            >div:nth-child(2) {
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}