@use '/src/scss/components/productDetails/abstracts/miniSpecification.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';

 .lb_productDetailsMinispec {
  @include miniSpecification.productDetailsMinispec;

  .lb_productDetailsMinispec_item {
    @include miniSpecification.productDetailsMinispecItem;

    .lb_productDetailsMinispec_item_label {
      font-weight: fontWeights.$fontWeightSemiBold;
    }

    &.lb_productDetailsMinispec_item-offer {
     color: signifiers.$offerColour;
     font-weight: fontWeights.$fontWeightSemiBold;
    }  
  }
    
  .lb_productDetailsMinispec_item:last-child {
    margin-bottom: 0;
  }
}