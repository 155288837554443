@use '/src/scss/layout/abstracts/_sideBySideColumns.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';

.lb_layoutSection-mobile25 {
  @include sideBySideColumns.layoutSection25;
}

.lb_layoutSection-mobile33 {
  @include sideBySideColumns.layoutSection33;
}

.lb_layoutSection-mobile50 {
  @include sideBySideColumns.layoutSection50;
}

.lb_layoutSection-mobile66 {
  @include sideBySideColumns.layoutSection66;
}

.lb_layoutSection-mobile75 {
  @include sideBySideColumns.layoutSection75;
}

.lb_layoutSection-mobile100 {
  @include sideBySideColumns.layoutSection100;
  clear: left;
}

.lb_layoutSection-tablet25 {
  @include breakpoints.respond-to("tablet") {
    @include sideBySideColumns.layoutSection25;
  }
}

.lb_layoutSection-tablet33 {
  @include breakpoints.respond-to("tablet") {
    @include sideBySideColumns.layoutSection33;
  }
}

.lb_layoutSection-tablet50 {
  @include breakpoints.respond-to("tablet") {
    @include sideBySideColumns.layoutSection50;
  }
}

.lb_layoutSection-tablet66 {
  @include breakpoints.respond-to("tablet") {
    @include sideBySideColumns.layoutSection66;
  }
}

.lb_layoutSection-tablet75 {
  @include breakpoints.respond-to("tablet") {
    @include sideBySideColumns.layoutSection75;
  }
}

.lb_layoutSection-tablet75 {
  @include breakpoints.respond-to("tablet") {
    @include sideBySideColumns.layoutSection75;
  }
}

.lb_layoutSection-tablet100 {
  @include breakpoints.respond-to("tablet") {
    @include sideBySideColumns.layoutSection100;
  }
}

.lb_layoutSection-desktop10 {
  @include breakpoints.respond-to("desktop") {
    @include sideBySideColumns.layoutSection10;
  }
}

.lb_layoutSection-desktop12 {
  @include breakpoints.respond-to("desktop") {
    @include sideBySideColumns.layoutSection12;
  }
}

.lb_layoutSection-desktop25 {
  @include breakpoints.respond-to("desktop") {
    @include sideBySideColumns.layoutSection25;
  }
}

.lb_layoutSection-desktop33 {
  @include breakpoints.respond-to("desktop") {
    @include sideBySideColumns.layoutSection33;
  }
}

.lb_layoutSection-desktop50 {
  @include breakpoints.respond-to("desktop") {
    @include sideBySideColumns.layoutSection50;
  }
}

.lb_layoutSection-desktop66 {
  @include breakpoints.respond-to("desktop") {
    @include sideBySideColumns.layoutSection66;
  }
}

.lb_layoutSection-desktop75 {
  @include breakpoints.respond-to("desktop") {
    @include sideBySideColumns.layoutSection75;
  }
}

.lb_layoutSection-desktop90 {
  @include breakpoints.respond-to("desktop") {
    @include sideBySideColumns.layoutSection90;
  }
}

.lb_layoutSection-desktop100 {
  @include breakpoints.respond-to("desktop") {
    @include sideBySideColumns.layoutSection100;
  }
}

.lb_layoutSection-newRow {
  clear: both;
}

.lb_layoutSection-mobileCentredColumn {
  margin-left: auto!important;
  margin-right: auto!important;
  float: inherit!important;
}

.lb_layoutSection-tabletCentredColumn {
  margin-left: auto!important;
  margin-right: auto!important;
  float: inherit!important;
}

.lb_layoutSection-desktopCentredColumn {
  margin-left: auto!important;
  margin-right: auto!important;
  float: inherit!important;
}