@use '/src/scss/components/carousel/_carouselControls.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/icons/abstracts/_fontIcons.scss';

$popOutTransition: 250ms;
$popOutShadow: 0 0 8px 0px rgba(0, 0, 0, .5);

@mixin popOutTransitionLeft {
  transition: all $popOutTransition ease-in-out;
}

@mixin popOutTransitionRight {
  transition: all $popOutTransition ease-in-out;
}

@mixin backLink {
  margin-bottom: lineHeights.$lineHeight;
  cursor: pointer;
  @include typography.linkSecondary;
  line-Height: 1;
  font-weight: fontWeights.$fontWeightBold;
}

@mixin popOutShadow {
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, .5);
}

@mixin popOut {
  z-index: 30;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  @include layout.responsiveGrid(12, 12, -3, "width");
  @include breakpoints.respond-to("tablet") {
    @include layout.responsiveGrid(12, 6, -3, "width");
  }
}

@mixin popOutStatics {
  padding-top: lineHeights.$lineHeight;
  padding-bottom: lineHeights.$lineHeight;
  background: #ffffff;
  @include layout.responsiveGrid(12, 0, 2, "padding-left");
  @include layout.responsiveGrid(12, 0, 2, "padding-right");
  @include breakpoints.respond-to("tablet") {
    @include layout.responsiveGrid(12, 0, 2, "padding-left");
    @include layout.responsiveGrid(12, 0, 2, "padding-right");
  }
  @include breakpoints.respond-to("desktop") {
    @include layout.responsiveGrid(12, 0, 2, "padding-left");
    @include layout.responsiveGrid(12, 0, 2, "padding-right");
  }
}

@mixin popOutWrapper {
  overflow-y: auto;
  background: #ffffff;
  height: 100%;
  cursor: auto;
}

@mixin popOutHeader {
  @include popOutStatics;
  min-height: 54px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background: #ffffff;
  @include popOutShadow;
}

@mixin popOutLeftActive {
  @include layout.responsiveGrid(12, 0, 6, "right");
  @include layout.responsiveGrid(12, 12, -6, "width"); 
  &:after {
    @include layout.responsiveGrid(12, 0, 6, "width");
    right: 0;
    text-align: right;
    padding-right: 12px;
    padding-top: 12px;
  }

  @include breakpoints.respond-to("tablet") {
    @include layout.responsiveGrid(12, 6, 3, "right");
    @include layout.responsiveGrid(12, 6, -3, "width"); 
    &:after {
      @include layout.responsiveGrid(12, 6, 3, "width"); 
    }
  }

  @include breakpoints.respond-to("desktop") {
    right: calc(100% - 1200px)!important;
  }
}

@mixin popOutRightActive {
  @include layout.responsiveGrid(12, 0, 3, "left");
  &:after {
    left: 0;
    @include layout.responsiveGrid(12, 0, 3, "width");
    text-align: left;
    padding-left: 4px;
    padding-top: 6px;
  }
  @include breakpoints.respond-to("tablet") {
    @include layout.responsiveGrid(12, 6, 3, "left");
    &:after {
      @include layout.responsiveGrid(12, 6, 3, "width");
      padding-left:12px;
      padding-top: 12px;
    }
  }
  @include breakpoints.respond-to("desktop") {
    left: calc(100% - 1200px);
    &:after {
      width:calc(100% - 1200px);
    }
  }
}