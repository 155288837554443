@use '/src/scss/forms/abstracts/_baseForm.scss';
@use '/src/scss/styles/abstracts/_typography.scss';

.lb_formElement-textBox {

  .lb_formElement_input {
    width: 100%;
    @include typography.formInputText;
    height: baseForm.$formElementControlHeight;
    padding: 0 32px 0 baseForm.$formElementControlPadding;
    border: solid 1px baseForm.$formBorder;
    border-radius: baseForm.$inputBorderRadius;
    font-size: 1rem;
  }
  .lb_formElement_input::placeholder {
    color: baseForm.$placeholder;
    font-style: italic;
  }
  
}