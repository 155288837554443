@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_typography.scss';
@use '/src/scss/styles/abstracts/_headings.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';

.lb_contentHeader {
  position: relative;
  margin-bottom: lineHeights.$lineHeight*3;
  text-align: center;
  background: #ffffff;

  @include breakpoints.respond-to("tablet") {
    background: colors.$primaryColour;
    color: #ffffff;
    @include layout.responsiveGrid(12, 0, 1, "margin-left");
    @include layout.responsiveGrid(12, 0, 1, "margin-right");
    padding-top: 25%;
    align-items: center;
  }
}

.lb_contentHeader_text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: lineHeights.$lineHeight;
  @include layout.responsiveGrid(12, 0, 2, "padding-left");
  @include layout.responsiveGrid(12, 0, 2, "padding-right");
  @include breakpoints.respond-to("tablet") {
    width: 50%;
    position: absolute;
    top: 0;
    height: 100%;
  }
}

.lb_contentHeader_img {
  display: block;
  height: auto;
  @include layout.responsiveGrid(12, 12, -2, "width");
  @include layout.responsiveGrid(12, 0, 1, "margin-left");
  @include layout.responsiveGrid(12, 0, 1, "margin-right");
  @include breakpoints.respond-to("tablet") {
    margin: initial;
    position: absolute;
    width: 50%;
    top: 0;
    right: 0;
    height: 100%;
  }  
}

.lb_contentHeader_text .lb_contentHeader_text_heading {
  @include headings.mainHeading;
  line-height: lineHeights.$lineHeight * 1.8;
  margin-bottom: lineHeights.$lineHeight;
  @include breakpoints.respond-to("tablet") {
    color: #ffffff;
  }
}

.lb_contentHeader_text p {
  @include headings.mainSubHeading;
  padding-bottom: lineHeights.$lineHeight;
  font-weight: fontWeights.$fontWeightRegular;
  color: colors.$primaryColour;
  @include breakpoints.respond-to("tablet") {
    padding-bottom: lineHeights.$lineHeight;
    color: #ffffff;
    width: initial;
    &:last-child {
      padding-bottom: 0;
    }
  } 
}

.lb_contentIntro {
  @include layout.responsiveGrid(12, 0, 1, "margin-left");
  @include layout.responsiveGrid(12, 0, 1, "margin-right");
  background-color: white;
  text-align: center;
  margin-bottom: lineHeights.$lineHeight *3;
  p {
    @include typography.textIntro;
    margin-bottom: lineHeights.$lineHeight;
  }
}