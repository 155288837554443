@use '/src/scss/icons/abstracts/_sizes.scss';

.lb_icon-vSmall:before {
  @include sizes.iconVSmall;
}

.lb_icon-small:before {
  @include sizes.iconSmall;
}

.lb_icon-medium:before {
  @include sizes.iconMedium;
}

.lb_icon-large:before {
  @include sizes.iconLarge;
}

.lb_icon-xLarge:before {
  @include sizes.iconXLarge;
}

.lb_icon-xXLarge:before {
  @include sizes.iconXXLarge;
}