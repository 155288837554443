@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/styles/colors/_colors.scss';
@use '/src/scss/styles/abstracts/_lineHeights.scss';

@mixin heading {
  font-weight: fontWeights.$fontWeightBold;
  color: colors.$primaryColour;
  text-decoration: none;
  clear: left;
}

@mixin heading {
  font-size: 1.2rem;
  font-weight: fontWeights.$fontWeightBold;
  color: colors.$primaryColour;
  text-decoration: none;
  line-height: lineHeights.$lineHeightContent;
}

@mixin mainHeading {
  font-size: 1.6rem;
  line-height: 1rem;
  font-weight: fontWeights.$fontWeightBold;
  color: colors.$primaryColour;
}

@mixin mainSubHeading {
  font-size: 1rem;
  color: colors.$textColour;
  line-height: lineHeights.$lineHeightContent * 1;
}

@mixin pageHeading {
  font-size: 1.6rem;
}

@mixin categoryHeading {
  font-size: 1.4rem;
  font-weight: fontWeights.$fontWeightBold;
  color: colors.$primaryColour;
}

@mixin sectionHeading {
  font-size: 1.2rem;
  line-height: 1.167;
  margin-bottom: layout.$lineHeight;
}

@mixin smallSectionHeading {
  font-size: 1rem;
  margin-bottom: layout.$lineHeight * .75 !important;
}

@mixin subSectionHeading {
  font-size: .8rem;
  margin-bottom: layout.$lineHeight * .5 !important;
}

@mixin textHeading {
  font-size: 1rem;
  color: colors.$textColour;
  line-height: 1.25;
  margin-bottom: layout.$lineHeight * .5;
  font-weight: fontWeights.$fontWeightBold;
}

@mixin centreHeading {
  width: 100%;
  text-align: center;
  padding-left: 0 !important;

  &:before {
    position: relative !important;
    float: initial;
    top: -4px !important;
    display: block;
    margin: 0 auto 0 auto;
  }
}