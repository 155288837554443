$softColour: #B51963;
$softMediumColour: hsl(21, 74%, 40%);
$mediumColour: hsl(171, 50%, 30%);
$mediumFirmColour: #5273A7;
$firmColour: #1E3D8B;
$extraFirmColour: #8B4EB5;

@mixin tensionMark {
    border-radius: 5px;
    display: inline-block;
    padding: 2px 5px;
    font-size: .7rem;
    font-weight: 600;
    letter-spacing: .05rem;
    position: relative;
    top: -3px;
    margin-bottom: 2px !important;
    line-height: 1.4 !important;
}