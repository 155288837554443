@use '/src/scss/styles/colors/_signifiers.scss';
@use '/src/scss/styles/colors/_positiveNegative.scss';
@use '/src/scss/styles/colors/_colors.scss';

.lb_icon-positive {
  color: positiveNegative.$positiveColour;
}

.lb_icon-positive.lb_icon-button:before {
  background-color: positiveNegative.$positiveColour;
}

.lb_icon-negative {
  color: positiveNegative.$negativeColour;
}

.lb_icon-negative.lb_icon-button:before {
  background-color: positiveNegative.$negativeColour;
}

.lb_icon-surface {
  color: colors.$surfaceColourDark;
}

.lb_icon-surfaceLight {
  color: colors.$surfaceColour;
}

.lb_icon-surface.lb_icon-button:before {
  background-color: colors.$surfaceColourDark;
}

.lb_icon-offer {
  color: signifiers.$offerColour;
}

.lb_icon-offer.lb_icon-button:before {
  background-color: signifiers.$offerColour;
}

.lb_icon-sleepTrialColour {
  color: signifiers.$sleepTrial;
}

.lb_icon-sleepTrialColour.lb_icon-button:before {
  background-color: signifiers.$sleepTrial;
}

.lb_icon-whatsAppColour {
  color: signifiers.$whatsApp;
}

.lb_icon-whatsAppColour.lb_icon-button:before {
  background-color: signifiers.$whatsApp;
}