@use '/src/scss/styles/abstracts/_lineHeights.scss';
@use '/src/scss/layout/_breakpoints.scss';
@use '/src/scss/layout/_layout.scss';
@use '/src/scss/styles/abstracts/_fontWeights.scss';
@use '/src/scss/styles/colors/_colors.scss';

.lb_delivery {
    @include layout.responsiveGrid(12, 0, 2, "padding-left");
    @include layout.responsiveGrid(12, 0, 2, "padding-right");
    @include breakpoints.respond-to("desktop") {
        @include layout.responsiveGrid(6, 0, 2, "padding-left");
        @include layout.responsiveGrid(6, 0, 2, "padding-right");
    }

    background-color: colors.$surfaceColourTintVeryDark;
    padding-top: lineHeights.$lineHeight*1.5;
    overflow: hidden;
    text-align: center;
    margin-bottom: lineHeights.$lineHeight;

    .lb_heading {
        font-weight: fontWeights.$fontWeightRegular;
        font-size: .9rem;
        margin-bottom: 22px !important;
        color: colors.$textColour;
        
        &.lb_icon-delivery{
            display: inline-block;
        }
    }

    .lb_text {
        text-align: left;
        margin-bottom: lineHeights.$lineHeight;
    }

    .lb_text-italic {
        margin-top: lineHeights.$lineHeight*.5;
    }

    .lb_icon-info {
        display: inline-flex;
        margin-left: 4px;
    }
}